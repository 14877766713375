import { Button } from 'libraryV2/ui/button';

interface IProps {
  onClick: () => void;
  isNewField: boolean;
}

const SaveCofirmationDialog: React.FC<IProps> = ({
  onClick,
  isNewField = false,
}) => (
  <Button
    className='bg-[#04B25F] ml-3 text-white hover:bg-[#078E4F]'
    onClick={onClick}
  >
    {isNewField ? 'Create' : 'Save Changes'}
  </Button>
);

export default SaveCofirmationDialog;
