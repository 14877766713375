import React from 'react';

import { XCircleIcon, InformationCircleIcon } from '@heroicons/react/20/solid';

interface Props {
  success?: boolean;
  errorMessage?: string;
  webChatSettingData: webChatSettingData;
  handleChange: (key: string, value: string | string[] | boolean) => void;
}

const Step1: React.FC<Props> = ({
  webChatSettingData,
  handleChange,
  success,
  errorMessage,
}) => {
  return (
    <>
      {!success && (
        <div className='flex justify-center'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='ml-2 text-sm text-red-600'>{errorMessage}</p>
        </div>
      )}
      <div className='col-span-6 sm:col-span-6'>
        <label
          htmlFor='title'
          className='block text-sm font-medium text-gray-700'
        >
          Title*
        </label>
        <input
          type='text'
          placeholder='My website channel'
          value={webChatSettingData.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target.name, e.target.value)
          }
          name='title'
          className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
        />
        <p className='mt-2 text-sm text-gray-500'>
          This title will be used in MyAlice to identify this channel. Give it a
          title that you can differentiate with later.
        </p>
      </div>
      <div className='mt-5'>
        <div className='flex'>
          <label
            htmlFor='welcome_message'
            className='block text-sm font-medium text-gray-700'
          >
            Welcome Message*
          </label>
        </div>
        <textarea
          rows={4}
          name='welcome_message'
          value={
            !!webChatSettingData.welcome_message
              ? webChatSettingData.welcome_message
              : ''
          }
          onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
            handleChange(e.target.name, e.target.value)
          }
          className='block w-full px-3 pt-2 mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
        />
      </div>
      <div className='flex p-3 mt-5 rounded bg-blue-50'>
        <InformationCircleIcon className='text-blue-400 w-6 h-6' />
        <p className='ml-3.5 text-blue-800'>
          The chatbot is disabled for now. You can create your automation after
          you’ve finished setting up the channel.
        </p>
      </div>
    </>
  );
};
export default Step1;
