import {
  EmptyStateSleepIcon,
  EmptyStateHappyIcon,
  EmptyStateSadIcon,
} from '../assets/iconComponent/EmptyStateIcon';

import useTranslation from 'components/customHooks/useTranslation';

// import useTranslation from 'components/customHooks/useTranslation';

//const {t} = useTranslation();

export const CreateTicketAudio = new Audio(
  'https://live-alice-v3.s3-ap-southeast-1.amazonaws.com/create_ticket.mp3'
);

export const UpdateTicketAudio = new Audio(
  'https://live-alice-v3.s3-ap-southeast-1.amazonaws.com/update_ticket.mp3'
);

export const filterElement = [
  // {
  //   type: 'ticketStatus',
  //   label: 'Ticket Status',
  // },
  { type: 'channels', label: 'Channels' },
  { type: 'agents', label: 'Agents' },
  { type: 'groups', label: 'Groups' },
  { type: 'tags', label: 'Tags' },
  { type: 'date', label: 'Date' },
];

export const filterElementsForBotQueue = [
  { type: 'channels', label: 'Channels' },
  { type: 'date', label: 'Date' },
];

export const ticketFilterDefaultValue = {
  channels: [],
  startDate: '',
  endDate: '',
  ticketStatus: 0,
  tags: [],
  agents: [],
  groups: [],
};

export const emptyTicketMessage = (type: string) => {
  switch (type) {
    case 'search':
      return {
        title: 'Nothing found related to ',
        subTitle:
          'Change ticket status to resolve in filter if you are looking for closed tickets',
      };

    case 'self':
      return {
        title: 'No ticket has been assigned to you.',
        subTitle:
          'You can assign unattended tickets to yourself from unassigned.',
      };

    case 'unassigned':
      return {
        title: 'No unassigned tickets available',
        subTitle: '',
      };

    default:
      return {
        title: 'No tickets available',
        subTitle: '',
      };
  }
};

export const excludedAttributeList = [
  'id',
  'platform',
  'tags',
  'bot_enabled',
  'primary_id',
  'secondary_id',
  'avatar',
  'full_name',
  'phone',
  'timezone',
  'email',
  'created_at',
  'last_message_text',
  'last_message_time',
  'is_linked_with_ecommerce_account',
  'session_token',
  'whatsapp_cart',
  'ip_address',
  'country',
  'region',
  'city',
  'address',
  'gender',
  'user_agent',
];

export const fixedAttributeList = [
  'full_name',
  'email',
  'phone',
  'city',
  'country',
];

export const ProductRecommendLimit = (channel_type: string) => {
  switch (channel_type) {
    case 'viber_messenger':
      return { limit: 8 };
    case 'instagram_messenger':
    case 'line_messenger':
    case 'livechat_messenger':
    case 'facebook_messenger':
      return { limit: 10 };
    case 'telegram_messenger':
    case 'whatsapp_messenger':
    case 'whatsapp_bsp':
      return { limit: 1 };
    default:
      return { limit: 1 };
  }
};

export const EmptyStateMessage = (
  isFiltered: boolean,
  isUnassigned: boolean,
  isClosed: boolean,
  isPrivateView: boolean = false,
  isBot: boolean
) => {
  const { t } = useTranslation();

  if (isFiltered) {
    return {
      img: <EmptyStateSadIcon />,
      title: '',
      subTitle: t("We didn't find any tickets that match your filters."),
    };
  }

  if (isPrivateView) {
    return {
      img: <EmptyStateSleepIcon />,
      title: t('Time for a breather'),
      subTitle: t("You don't have any new conversation"),
    };
  }

  if (isUnassigned) {
    return {
      img: <EmptyStateSleepIcon />,
      title: t('Time for a breather'),
      subTitle: t("You don't have any new conversation"),
    };
  }

  if (isBot) {
    return {
      img: <EmptyStateHappyIcon />,
      title: t('Your bots are taking a nap!'),
      subTitle: t("You don't have any active bot conversations"),
    };
  }

  if (isClosed) {
    return {
      img: <EmptyStateSadIcon />,
      title: 'Chop chop!',
      subTitle: t("Make sure to close tickets or they'll pile up soon."),
    };
  }
  return {
    img: <EmptyStateHappyIcon />,
    title: t('Make sure to check your unassigned queue'),
    subTitle: t('Use SMART groups for automatic assignment'),
  };
};

export const SecondaryFilterData = [
  {
    label: 'All Chats',
    value: 'all',
  },
  {
    label: 'Replied',
    value: 'replied',
  },
  {
    label: 'Unreplied',
    value: 'unreplied',
  },
  {
    label: 'Hold',
    value: 'hold',
  },
];
