import {
  useActions,
  useSelector,
  MultiListSelector,
} from 'pages/automationWorkflow/export';

const PhoneVariable = () => {
  // Fetch data from the Redux store
  const workflowAutomationData = useSelector(
    (state: any) => state.workflowAutomation
  );
  const { triggerInfo, selectedSaveActionsData } = workflowAutomationData;

  // Extract required action functions from the custom hook
  const {
    updateSelectedTriggerInfoLeftValue,
    updateSelectedTriggerInfoRightValue,
  } = useActions();

  // Destructure required data from the selectedSaveActionsData
  const { whatsapp: { phoneVariable = {} } = {} } =
    selectedSaveActionsData || {};

  return (
    <>
      <div className='px-4 py-2 bg-white rounded mt-6'>
        <h3 className='text-sm font-medium text-gray-600 mb-1'>
          Phone Variable
        </h3>
        <div>
          <MultiListSelector
            activeValue={{
              category: phoneVariable?.selectedTriggerInfo ?? '',
              value: phoneVariable?.selectedTriggerInfoValue ?? '',
            }}
            categoryOptions={Object.keys(triggerInfo)}
            categoryDetails={
              triggerInfo[phoneVariable?.selectedTriggerInfo] || []
            }
            updateSelectedTriggerInfoLeft={(data: string) =>
              updateSelectedTriggerInfoLeftValue(data)
            }
            updateSelectedTriggerInfoRight={(data: string) =>
              updateSelectedTriggerInfoRightValue(data)
            }
          />
        </div>
        <p className='text-sm text-gray-500 mt-1'>
          Phone number variable is where the WhatsApp template will be sent.
        </p>
      </div>
    </>
  );
};

export default PhoneVariable;
