import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Modal from '../../common/Modal';
import Stepper from '../../common/Stepper';

import { navigate } from '@reach/router';
import { getModalCustomFooter } from '../../../utils/functions';

interface Props {
  open: boolean;
  teamName: string;
  handleClose: () => void;
  fetchFacebookPages: (
    id: string,
    token: string,
    type: string
  ) => Promise<facebookPageListProps[]>;
  handleChannelCreate: (
    channelType: string,
    channelData: any
  ) => Promise<channelCreateAPiResponseType>;
}

const formSteps = [
  { id: 1, name: 'Login Facebook' },
  { id: 2, name: 'Choose Connectable Page' },
  { id: 3, name: 'General Information' },
];

const initialPageData = {
  url: '',
  name: '',
  avatar: '',
  primary_id: '',
  is_connected: false,
};

const initialSettingData = {
  url: '',
  name: '',
  title: '',
  primary_id: '',
  is_published: true,
  whitelisted_domains: [],
  secondary_receiver_id: '',
  connected_nlp_integration_id: '',
  persistent_menu: [{ id: 0, title: '', type: 'url', value: '' }],
};

const FacebookModal: React.FC<Props> = ({
  open,
  teamName,
  handleClose,
  fetchFacebookPages,
  handleChannelCreate,
}) => {
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [pageList, setPageList] = React.useState<
    facebookPageListProps[] | null
  >(null);
  const [loading, setLoading] = React.useState(false);

  const [selectedPage, setSelectedPage] = React.useState(initialPageData);
  const [messengerSettingData, setMessengerSettingData] =
    React.useState(initialSettingData);

  const clearState = () => {
    setMessengerSettingData(initialSettingData);
    setSelectedPage(initialPageData);
    setLoading(false);
    setErrorMessage('');
    setSuccess(true);
    setStep(1);
  };

  const processFacebookResponse = async (
    response: facebookResponseDataProps
  ) => {
    setLoading(true);
    if (response.status !== 'unknown') {
      const data = await fetchFacebookPages(
        response.id,
        response.accessToken,
        'facebook_messenger'
      );
      if (!!data) {
        setPageList(data);
        setStep(2);
        setLoading(false);
      } else {
        clearState();
        handleClose();
        setSuccess(false);
        setErrorMessage(
          'Something went wrong while fetching pagelist. Try again later'
        );
      }
    } else {
      setSuccess(false);
      setErrorMessage("Couldn't Connect facebook");
    }
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setStep(2);
        break;
      case 2:
        setStep(3);
        break;
      case 3: {
        setLoading(true);
        let persistentMenu = messengerSettingData.persistent_menu.filter(
          (item) => !!item.title && !!item.value
        );
        let payloadData = {
          title: messengerSettingData.title,
          primary_id: !!selectedPage && selectedPage?.primary_id,
          name: selectedPage.name,
          url: selectedPage.url,
          secondary_receiver_id: messengerSettingData.secondary_receiver_id,
          persistent_menu: persistentMenu,
          whitelisted_domains: messengerSettingData.whitelisted_domains,
          is_published: messengerSettingData.is_published,
          connected_nlp_integration_id:
            messengerSettingData.connected_nlp_integration_id,
        };
        let res = await handleChannelCreate('facebook_messenger', payloadData);
        if (res.status === 200) {
          setLoading(false);
          handleClose();
          navigate(`/dashboard?channel_type=facebook_messenger`);
        } else {
          setSuccess(false);
          setLoading(false);
          setErrorMessage(
            res.status === 500
              ? 'Something went wrong while integrating the channel. Try again later.'
              : res.data.error
          );
        }
        break;
      }
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
      case 3:
        setStep(2);
        break;
      case 4:
        setStep(3);
        break;
    }
  };

  const checkFinishButton = () => {
    if (loading) return true;

    const mandatoryKeys = ['title'];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(messengerSettingData && messengerSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 1:
        return true;
      case 2:
        if (!!pageList && pageList.length) {
          if (selectedPage.primary_id.length) return false;
          else return true;
        }
        return true;
      case 3:
        return checkFinishButton();
      default:
        return false;
    }
  };

  return (
    <Modal
      open={open}
      teamName={teamName}
      title='Facebook Messenger'
      platfromType='facebook_messenger'
      closeOnExternalClick={false}
      handleClose={() => {
        clearState();
        handleClose();
      }}
      handleCancel={() => handleCancel(step)}
      disableConfirm={checkConfirmButtons()}
      handleConfirm={handleConfirm}
      hideCancel={step === 3 || step === 1}
      confirmText={step === 3 ? 'Finish' : 'Next'}
      customFooterContent={getModalCustomFooter(
        'Confused about what to do?',
        'https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-messenger',
        'Read Documentation'
      )}
    >
      <div className='p-6'>
        <Stepper steps={formSteps} currentStep={step} />
        <div className='mt-10'>
          {
            {
              1: (
                <Step1
                  loading={loading}
                  processFacebookResponse={processFacebookResponse}
                  type={'facebook_messenger'}
                />
              ),
              2: (
                <Step2
                  pageList={pageList}
                  selectedPage={selectedPage}
                  setSelectedPage={setSelectedPage}
                />
              ),
              3: (
                <Step3
                  success={success}
                  errorMessage={errorMessage}
                  messengerSettingData={messengerSettingData}
                  handleChange={(
                    key: string,
                    value: string | string[] | boolean
                  ) => {
                    setMessengerSettingData({
                      ...messengerSettingData,
                      [key]: value,
                    });
                  }}
                />
              ),
            }[step]
          }
        </div>
      </div>
    </Modal>
  );
};
export default FacebookModal;
