import {
  Row,
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DotsHorizontalIcon,
  DropdownMenuTrigger,
  useDispatch,
  useSelector,
  useState,
  toast,
} from '../../../export';

import DeleteAlert from '../DeleteAlert';
import DatalabSideSheet from '../DatalabSideSheet';
import useDatalab from 'pages/datalab/hooks/useDatalab';
interface DataWithId {
  id?: string;
}

interface DataTableRowActionsProps<TData extends DataWithId> {
  row: Row<TData>;
  labId: string;
  hasReadAccess: boolean;
  hasWriteAccess: boolean;
  hasDeleteAccess: boolean;
  deleteBulkDatalabList: (ladId: string, ids: string[]) => void;
}

export function DataTableRowActions<TData extends DataWithId>({
  row,
  labId,
  hasReadAccess,
  hasWriteAccess,
  hasDeleteAccess,
  deleteBulkDatalabList,
}: DataTableRowActionsProps<TData>) {
  const dispatch = useDispatch();
  const { isDeleted } = useSelector((state: any) => state.datalabList);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [isDeleting, setDeleting] = useState(false);
  const [siteSheetOpen, setSideSheetOpen] = useState(false);
  const [mode, setMode] = useState<'VIEW' | 'EDIT'>('VIEW');
  const { selectedDatalabInfo } = useDatalab();

  const handleDelete = async () => {
    const id = row.original.id;
    try {
      setDeleting(true);
      const ids = !!id ? [id] : [''];
      await deleteBulkDatalabList(labId, ids);
      if (isDeleted) {
        dispatch.datalabList.updateDeleteStatus(false);
      }
      toast({
        // @ts-expect-error just bad type defination!
        title: <p className='text-green-400'>Entry Deleted Successfully</p>,
      });
    } catch (error) {
      console.error('Error deleting entry:', error);
    } finally {
      setDeleting(false);
      setAlertOpen(false);
    }
  };

  const openDeleteDialog = () => setAlertOpen(true);
  const closeDeleteDialog = () => setAlertOpen(false);

  const handleViewDetails = async () => {
    setMode('VIEW');
    setSideSheetOpen(true);
  };

  const handleEditEntry = async () => {
    setMode('EDIT');
    setSideSheetOpen(true);
  };

  const handleSideSheetClose = () => {
    setSideSheetOpen(false);
    // why this wierd hack? check this: https://github.com/radix-ui/primitives/issues/1241
    // TODO: fix this once radix-ui push a fix
    setTimeout(() => {
      document.body.style.pointerEvents = 'auto';
    }, 1000);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant='ghost'
            className='flex h-8 w-8 p-0 data-[state=open]:bg-muted focus:border-traparent'
          >
            <DotsHorizontalIcon className='w-4 h-4' />
            <span className='sr-only'>Open menu</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align='end' className='w-[160px] bg-white'>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className={'text-left hover:bg-gray-100'}
            onClick={handleViewDetails}
            // disabled={!hasReadAccess}
          >
            View Details
          </DropdownMenuItem>
          <DropdownMenuItem
            className={
              !hasWriteAccess
                ? 'text-gray-200 cursor-not-allowed'
                : 'text-left hover:bg-gray-100'
            }
            onClick={handleEditEntry}
            disabled={!hasWriteAccess}
          >
            Edit Entry
          </DropdownMenuItem>
          <DropdownMenuSeparator className='bg-gray-200' />
          <DropdownMenuItem
            onClick={openDeleteDialog}
            disabled={!hasDeleteAccess}
            className={
              !hasDeleteAccess
                ? 'text-gray-200 cursor-not-allowed'
                : 'text-left hover:bg-gray-100'
            }
          >
            Delete
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <DeleteAlert
        open={isAlertOpen}
        onOpenChange={setAlertOpen}
        title='Delete Entry'
        description='Are you sure you want to delete this entry?'
        onCancel={closeDeleteDialog}
        onConfirm={handleDelete}
        isConfirming={isDeleting}
      />

      {siteSheetOpen && (
        <DatalabSideSheet
          isOpen={siteSheetOpen}
          datalabShape={selectedDatalabInfo}
          datalabEntries={row.original}
          header={mode === 'VIEW' ? `View Details` : `Enter New Data`}
          mode={mode}
          onClose={handleSideSheetClose}
        />
      )}
    </>
  );
}
