import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'libraryV2/ui/alert-dialog';
import { Button } from 'libraryV2/ui/button';

interface IProps {
  onDiscard: () => void;
}

const DiscardCofirmationDialog: React.FC<IProps> = ({ onDiscard }) => (
  <AlertDialog>
    <AlertDialogTrigger asChild>
      <Button className='bg-[#FFFFFF] border-[#DFDFE2] rounded hover:bg-gray-100'>
        Cancel
      </Button>
    </AlertDialogTrigger>
    <AlertDialogContent className='bg-white'>
      <AlertDialogHeader>
        <AlertDialogTitle>Discard Changes?</AlertDialogTitle>
        <AlertDialogDescription className='text-[#71717A]'>
          Are you sure you want to discard your changes? Any unsaved
          modifications will be lost.
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel className='bg-[#F4F4F5] hover:bg-[#E4E4E7]'>
          Cancel
        </AlertDialogCancel>
        <AlertDialogAction
          className='ml-3 text-white bg-[#FF0000] hover:bg-red-700'
          onClick={onDiscard}
        >
          Discard
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
);

export default DiscardCofirmationDialog;
