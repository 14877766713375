import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  checkPermission,
  emailChannels,
  reorderList,
} from '../../../utilities/utils';
import SequenceCard from './components/SequenceCard';
import SequenceMeta from './components/SequenceMeta';
import './assets/styles/style.scss';
import { Button, Alert } from 'evergreen-ui';
import { connect } from 'react-redux';
import BlockDock from './components/BlockDock';
import LoaderLine from '../../components/loaders/LoaderLine';
import NoDataAvailable from './components/NoDataAvailable';
import SmartBlock from '../../components/blocks/SmartBlock';
import DropdownMenu2 from '../../components/elements/DropdownMenu2';
import config from '../../../utilities/config';
import SearchSequence from './components/SearchSequence';
import ModifySequence from './components/ModifySequence';
import PlatformConnectAlert from './components/fragments/PlatformConnectAlert';
import { HasPermission } from '../../../components';
import { navigate } from '@reach/router';
import { PlusIcon } from '@heroicons/react/20/solid';
import Rules from '../rules/Rules';

class Builder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchSequence: '',
      sequenceAcitivityLoader: false,
      sequenceAcitivityModal: false,
      editId: 0,
      editTitle: '',
      readAccess: 'read:bot_building',
      writeAccess: 'write:bot_building',
      loader: true,
    };
  }

  handleDelete = async (id) => {
    await this.props.deleteSequence(this.props.selectedPlatform.id, id);
    if (this.props.selectedSequence === id) {
      await this.props.setSelectedSequence(this.props.sequences[0].id);
    }
  };

  handleSequenceNameSubmit = (id, title) => {
    const data = {
      title: title,
    };
    const result = this.props.editSequence(
      this.props.selectedPlatform.id,
      id,
      data
    );
    return result;
  };

  handleSequenceAdd = async (title) => {
    const data = {
      title: title,
    };
    let res = await this.props.createSequence(
      this.props.selectedPlatform.id,
      data
    );
    return res;
  };

  handleActiveBlock = (id) => {
    this.props.setSelectedSequence(id);
    this.props.fetchBlock(id);
    this.props.fetchBlockRules(id);
  };

  onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorderList(
      this.props.blocks,
      result.source.index,
      result.destination.index
    );

    this.props.reorderBlocks(items);
  };

  handlePlatformOnSelect = async (selectedPlatform) => {
    this.props.setSelectedPlatform(selectedPlatform);
    // fetching sequence for that platform
    await this.props.fetchSequence(parseInt(selectedPlatform.id));
    // after getting value from fetch sequence we update default sequence which updates when fetch sequence called
    await this.props.setSelectedSequence(this.props.defaultSequence);
    // finally fetching all blocks for that selected sequence
    await this.props.fetchBlock(this.props.selectedSequence);
    await this.props.fetchIntent(selectedPlatform.id);
    await this.props.fetchAttributes(selectedPlatform.id);
    await this.props.fetchEntities(parseInt(selectedPlatform.id));
    await this.props.getWhatsAppMesssageTemplates(selectedPlatform.id);
    this.props.updateBlockRulesList([]);
  };

  async componentDidMount() {
    await this.props.fetchProject();
    const project = this.props.projects.filter(
      (p) => parseInt(this.props.projectId) === p.id
    )[0];
    let platformId = 0;
    if (
      !!this.props.selectedProject &&
      this.props.projectId !== this.props.selectedProject.id
    ) {
      await this.props.setSelectedProject(project);
      if (
        this.props.selectedProject.platforms.length > 0 &&
        this.props.selectedProject.platforms.filter(
          (platform) => platform.id === this.props.selectedPlatform?.id
        ).length === 0
      ) {
        this.props.setSelectedPlatform(this.props.selectedProject.platforms[0]);
        platformId = this.props.selectedProject.platforms[0].id;
      } else {
        this.props.setSelectedPlatform(
          this.props.selectedProject.platforms.filter(
            (platform) => platform.id === this.props.selectedPlatform.id
          )[0]
        );
        platformId =
          this.props.selectedPlatform && this.props.selectedPlatform.id;
      }
    }
    //first we will check if current project's permission is in loading state?
    if (this.props.permissionLoader) {
      //if true set timeout to 1s and wait for permission to load
      setTimeout(() => {
        //permission loaded
        const hasPermission = checkPermission(
          this.props.permissionList,
          this.state.readAccess
        );

        if (hasPermission) {
          this.fetchAPIData(platformId);
        }
      }, 1000);
    } else {
      //permission loaded
      const hasPermission = checkPermission(
        this.props.permissionList,
        this.state.readAccess
      );

      if (hasPermission) {
        await this.fetchAPIData(platformId);
      }
    }
  }

  fetchAPIData = async (platformId) => {
    let selectedProjectId = this.props.selectedProject.id;
    if (platformId !== 0) {
      // if there is platformId we fetch sequence and attributes
      await this.props.fetchSequence(platformId);
      await this.props.fetchAttributes(platformId);
      await this.props.fetchEntities(platformId);
      await this.props.getWhatsAppMesssageTemplates(platformId);
      const sequenceIDs = this.props.sequences.map((s) => s.id);

      if (
        this.props.selectedSequence === 0 ||
        !sequenceIDs.includes(this.props.selectedSequence)
      ) {
        this.props.setSelectedSequence(sequenceIDs[0]);
      }
      // later fetching blocks and api
      await this.props.fetchBlock(this.props.selectedSequence);
      await this.props.fetchBlockRules(this.props.selectedSequence);
    }
    await this.props.fetchSubscribe(platformId);
    await this.props.fetchAPI(selectedProjectId);
    await this.props.fetchIntent(platformId);
    await this.props.fetchDataLabs(selectedProjectId);
    await this.props.fetchAgent(selectedProjectId);
    await this.props.fetchTeamGroups(selectedProjectId);
    await this.props.fetchAllTicketTags(selectedProjectId);
    this.setState({ loader: false });
  };

  handleSelectedLabData = async (labId, blockId) => {
    if (labId) {
      await this.props.handleSelectedLabData({
        projectId: this.props.selectedProject?.id,
        labId: labId,
        blockId: blockId,
      });
    }
  };
  render() {
    // used for loader
    const platformSettingsLink = '/integrations/available-integrations';
    const hasEommerceConnected =
      this.props.selectedProject?.has_ecommerce_connected;
    const loadingState =
      this.props.loaders.effects.block.fetchBlock ||
      this.props.loaders.effects.builder.fetchSequence ||
      this.state.loader;

    let selectedSequenceRender = this.props.sequences.filter(
      (s) => s.id === this.props.selectedSequence
    );

    selectedSequenceRender =
      selectedSequenceRender.length > 0 ? selectedSequenceRender[0] : null;

    const isDockAvailable =
      !!selectedSequenceRender &&
      selectedSequenceRender.is_default &&
      (selectedSequenceRender.title.toLowerCase() === 'remove' ||
        selectedSequenceRender.title.toLowerCase() === 'hide');

    const hasWriteAccess = checkPermission(
      this.props.permissionList,
      this.state.writeAccess
    );

    const isWhatsApp =
      this.props.selectedPlatform?.type === 'whatsapp_messenger';
    const isWhatsAppBSP = this.props.selectedPlatform?.type === 'whatsapp_bsp';

    return (
      <HasPermission shortCode={this.state.readAccess}>
        {this.props.selectedPlatform &&
        this.props.selectedPlatform.length !== 0 ? (
          <main className='builder-main'>
            <ModifySequence
              isShown={this.state.sequenceAcitivityModal}
              isLoading={this.state.sequenceAcitivityLoader}
              updateState={(state, value) => this.setState({ [state]: value })}
              status={this.state.editId > 0 ? 'edit' : 'create'}
              handleSequenceAdd={this.handleSequenceAdd}
              editId={this.state.editId}
              handleRename={this.handleSequenceNameSubmit}
              editTitle={this.state.editTitle}
            />
            <div className='builder-main__wrapper bg-gray-50'>
              <div className='builder-main__sequence'>
                <div className='mt-5px' />
                <div className='relative'>
                  {!!this.props.selectedPlatform && (
                    <DropdownMenu2
                      platformData={this.props.platformList}
                      handleSelect={this.handlePlatformOnSelect}
                      selectedPlatform={this.props.selectedPlatform}
                      title={'Select Channel'}
                    />
                  )}
                </div>
                <SequenceMeta
                  title='Default Sequence'
                  subtitle='Your bot consists of sequence. Sequences are like individual pages on a website.'
                />
                {/*we are preventing welcome message block for whatsapp platform so this is for platforms which are not whatsapp */}
                {!isWhatsApp &&
                  !isWhatsAppBSP &&
                  this.props.sequences.map((block, i) => {
                    return (
                      block.is_default && (
                        <SequenceCard
                          key={i}
                          id={block.id}
                          title={block.title}
                          activeSequenceId={this.props.selectedSequence}
                          isEditable={
                            hasWriteAccess ? !block.is_default : false
                          }
                          subtitle={block.subtitle}
                          handleActiveBlock={this.handleActiveBlock}
                        />
                      )
                    );
                  })}
                {/*and this is platform which is whatsapp*/}
                {(isWhatsApp || isWhatsAppBSP) &&
                  this.props.sequences.map((block, i) => {
                    return (
                      block.is_default &&
                      block.title !== 'Welcome Message' && (
                        <SequenceCard
                          key={i}
                          id={block.id}
                          title={block.title}
                          activeSequenceId={this.props.selectedSequence}
                          isEditable={
                            hasWriteAccess ? !block.is_default : false
                          }
                          subtitle={block.subtitle}
                          handleActiveBlock={this.handleActiveBlock}
                        />
                      )
                    );
                  })}

                <SequenceMeta
                  title='Your Sequence'
                  subtitle='Your bot consists of sequence. Sequences are like individual pages on a website.'
                />
                {hasWriteAccess && (
                  <Button
                    className='btn-full btn-light'
                    iconBefore='add'
                    height={40}
                    onClick={() => {
                      this.setState({
                        sequenceAcitivityModal: true,
                        editId: 0,
                      });
                    }}
                  >
                    Add New Sequence
                  </Button>
                )}
                <SearchSequence
                  handleFilter={(evt) =>
                    this.setState({ searchSequence: evt.target.value })
                  }
                  value={this.state.searchSequence}
                />
                {!!this.props.sequences &&
                  this.props.sequences
                    .filter((elem) =>
                      elem.title
                        .toLowerCase()
                        .includes(this.state.searchSequence.toLowerCase())
                    )
                    .map(
                      (block, i) =>
                        !block.is_default && (
                          <SequenceCard
                            key={i}
                            id={block.id}
                            title={block.title}
                            activeSequenceId={this.props.selectedSequence}
                            isEditable={
                              hasWriteAccess ? !block.is_default : false
                            }
                            handleDelete={this.handleDelete}
                            handleSequenceNameSubmit={
                              this.handleSequenceNameSubmit
                            }
                            handleActiveBlock={this.handleActiveBlock}
                            handleEditId={(value) =>
                              this.setState({ editId: value })
                            }
                            handleEditTitle={(value) =>
                              this.setState({ editTitle: value })
                            }
                            handleModal={(value) =>
                              this.setState({ sequenceAcitivityModal: value })
                            }
                          />
                        )
                    )}
              </div>
              <div
                className='builder-main__block-area focus:border-none focus:ring-none focus:outline-none'
                tabIndex={-1}
              >
                {!!this.props.selectedPlatform &&
                  this.props.selectedPlatform?.type?.startsWith('facebook') &&
                  this.props.selectedPlatform.primary_id === '' && (
                    <PlatformConnectAlert
                      platformSettingsLink={platformSettingsLink}
                      facebookTestUrl={config.misc.facebookTestUrl()}
                      userId={this.props.userId}
                      selectedPlatformId={this.props.selectedPlatform.id}
                    />
                  )}

                {!loadingState &&
                  !emailChannels.includes(
                    this.props.selectedPlatform?.platform_type
                  ) && (
                    <Rules
                      selectedPlatform={this.props.selectedPlatform}
                      hasWriteAccess={hasWriteAccess}
                    />
                  )}

                {loadingState && (
                  <LoaderLine style={{ height: `50vh` }} color={'#007B65'} />
                )}
                {!isDockAvailable &&
                  !loadingState &&
                  this.props.blocks.length === 0 && (
                    <NoDataAvailable
                      message={
                        'Click Any of the block from right to add a block!'
                      }
                      height={'350px'}
                    />
                  )}
                {isDockAvailable &&
                  !loadingState &&
                  this.props.blocks.length === 0 && (
                    <Alert
                      intent='none'
                      title='YOU CANNOT MODIFY THIS BLOCK'
                      marginBottom={32}
                    >
                      This block is not modifiable. It exist for specific
                      platforms which allows you to Hide or Delete interactions.
                    </Alert>
                  )}
                <DragDropContext
                  onDragEnd={(result) =>
                    hasWriteAccess && this.onDragEnd(result)
                  }
                >
                  <Droppable droppableId='droppable' direction='vertical'>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                        tabIndex={-1}
                        className='focus:border-none focus:ring-none focus:outline-none'
                      >
                        {!isDockAvailable &&
                          !loadingState &&
                          this.props.blocks.length > 0 &&
                          this.props.blocks.map((block, i) =>
                            block.type === 'product_discovery' &&
                            !hasEommerceConnected ? (
                              ''
                            ) : (
                              <Draggable
                                key={i}
                                draggableId={`block-${i}`}
                                index={i}
                              >
                                {(provided) => (
                                  <div
                                    key={i}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className='builder-blocks__draggable '
                                  >
                                    <SmartBlock
                                      {...block}
                                      sequences={this.props.sequences}
                                      entities={this.props.entities}
                                      subscriptions={this.props.subscriptions}
                                      selectedSequence={
                                        this.props.selectedSequence
                                      }
                                      labsData={this.props.labsData}
                                      selectedProjectId={
                                        this.props.selectedProject?.id
                                      }
                                      platformType={
                                        this.props.selectedPlatform
                                          .platform_type
                                      }
                                      attributeList={this.props.attributes}
                                      apiList={this.props.apis}
                                      deleteBlock={this.props.deleteBlock}
                                      updateTextBlock={
                                        this.props.updateTextBlock
                                      }
                                      updateEmailSendBlock={
                                        this.props.updateEmailSendBlock
                                      }
                                      saveBlock={this.props.saveBlock}
                                      updateRedirectBlock={
                                        this.props.updateRedirectBlock
                                      }
                                      updateInputBlock={
                                        this.props.updateInputBlock
                                      }
                                      saveRedirectBlock={
                                        this.props.saveRedirectBlock
                                      }
                                      createButtonElement={
                                        this.props.createButtonElement
                                      }
                                      createGalleryButtonElement={
                                        this.props.createGalleryButtonElement
                                      }
                                      updateButtonsBlock={
                                        this.props.updateButtonsBlock
                                      }
                                      updateLiveChatButtonBlock={
                                        this.props.updateLiveChatButtonBlock
                                      }
                                      updateButtonElements={
                                        this.props.updateButtonElements
                                      }
                                      createSubscribeElement={
                                        this.props.createSubscribeElement
                                      }
                                      deleteSubscriberSequenceData={
                                        this.props.deleteSubscriberSequenceData
                                      }
                                      updateSubscribeSequenceData={
                                        this.props.updateSubscribeSequenceData
                                      }
                                      updateGalleryBlock={
                                        this.props.updateGalleryBlock
                                      }
                                      updateGalleryElements={
                                        this.props.updateGalleryElements
                                      }
                                      updateGalleryButtonElements={
                                        this.props.updateGalleryButtonElements
                                      }
                                      updateURLBlocks={
                                        this.props.updateURLBlocks
                                      }
                                      updateSubscribeBlockTitle={
                                        this.props.updateSubscribeBlockTitle
                                      }
                                      updateUnsubscribeBlock={
                                        this.props.updateUnsubscribeBlock
                                      }
                                      deleteButtonElement={
                                        this.props.deleteButtonElement
                                      }
                                      createGalleryElement={
                                        this.props.createGalleryElement
                                      }
                                      deleteGalleryItem={
                                        this.props.deleteGalleryItem
                                      }
                                      deleteGalleryButtonElement={
                                        this.props.deleteGalleryButtonElement
                                      }
                                      updateImageBlock={
                                        this.props.updateImageBlock
                                      }
                                      copyBlock={this.props.copyBlock}
                                      moveBlock={this.props.moveBlock}
                                      handleAPIButtonElementCreate={
                                        this.props.createAPIButtonElement
                                      }
                                      saveLoader={this.props.saveLoader}
                                      updateGalleryBlockData={
                                        this.props.updateGalleryBlockData
                                      }
                                      hasWriteAccess={hasWriteAccess}
                                      agentList={this.props.agentList}
                                      allTicketTags={this.props.allTicketTags}
                                      agentGroupList={this.props.teamGroups}
                                      handleSelectedLabData={
                                        this.handleSelectedLabData
                                      }
                                      updateDataLabBlockFieldAttribute={
                                        this.props
                                          .updateDataLabBlockFieldAttribute
                                      }
                                      addProductDiscoveryBlockInputInstruction={
                                        this.props
                                          .addProductDiscoveryBlockInputInstruction
                                      }
                                      addProductDiscoveryBlockOutputInstructions={
                                        this.props
                                          .addProductDiscoveryBlockOutputInstructions
                                      }
                                      deleteProductDiscoveryBlockInputInstruction={
                                        this.props
                                          .deleteProductDiscoveryBlockInputInstruction
                                      }
                                      clearProductDiscoveryBlockInputInstructions={
                                        this.props
                                          .clearProductDiscoveryBlockInputInstructions
                                      }
                                      clearProductDiscoveryBlockOutputInstructions={
                                        this.props
                                          .clearProductDiscoveryBlockOutputInstructions
                                      }
                                      updateProductDiscoveryBlockData={
                                        this.props
                                          .updateProductDiscoveryBlockData
                                      }
                                      handleProductDiscoveryBlockGalleryButton={
                                        this.props
                                          .handleProductDiscoveryBlockGalleryButton
                                      }
                                      handleSetAttributeBlock={
                                        this.props.handleSetAttributeBlock
                                      }
                                      handleAddToCartBlock={
                                        this.props.handleAddToCartBlock
                                      }
                                      updatePlaceOrderData={
                                        this.props.updatePlaceOrderData
                                      }
                                      updateViewCartData={
                                        this.props.updateViewCartData
                                      }
                                      handleViewCartBlockGalleryButton={
                                        this.props
                                          .handleViewCartBlockGalleryButton
                                      }
                                      handleCouponBlock={
                                        this.props.handleCouponBlock
                                      }
                                      selectedProject={
                                        this.props.selectedProject
                                      }
                                      updateBetterdocsBlockData={
                                        this.props.updateBetterdocsBlockData
                                      }
                                      handleBetterdocsBlockGalleryButton={
                                        this.props
                                          .handleBetterdocsBlockGalleryButton
                                      }
                                      whatsappTemplate={this.props.messageTemplates?.filter(
                                        (template) =>
                                          template.status === 'approved'
                                      )}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            )
                          )}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
                {hasWriteAccess && !isDockAvailable && (
                  <BlockDock
                    blocks={this.props.blocks}
                    selectedSequence={this.props.selectedSequence}
                    selectedPlatformType={
                      this.props.selectedPlatform
                        ? this.props.selectedPlatform.platform_type
                        : null
                    }
                    selectedProject={this.props.selectedProject}
                    defaultSequence={this.props.defaultSequence}
                    createBlock={this.props.createBlock}
                  />
                )}
              </div>
            </div>
          </main>
        ) : (
          <div className='flex justify-center h-full text-center align-center flex-column'>
            <svg
              className='w-12 h-12 mx-auto text-gray-400'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
              aria-hidden='true'
            >
              <path
                vectorEffect='non-scaling-stroke'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={2}
                d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
              />
            </svg>
            <h3 className='mt-2 text-sm font-medium text-gray-900'>
              No channels
            </h3>
            <p className='mt-1 text-sm text-gray-500'>
              Create a channel and start serving your customers right away!
            </p>
            <div className='mt-6'>
              <button
                type='button'
                className='inline-flex items-center px-4 py-2 text-sm font-medium text-white border border-transparent rounded-md shadow-sm bg-primary hover:bg-primary-hover focus:outline-none'
                onClick={() => navigate('/integrations/available-integrations')}
              >
                <PlusIcon className='w-5 h-5 mr-2 -ml-1' aria-hidden='true' />
                New Channel
              </button>
            </div>
          </div>
        )}
      </HasPermission>
    );
  }
}

const mapState = (state) => ({
  userId: state.auth.id,
  projects: state.dashboard.projects,
  subscriptions: state.builder.subscriptions,
  labsData: state.dataLab.labsData,
  selectedProject: state.dashboard.selectedProject,
  selectedPlatform: state.dashboard.selectedPlatform,
  platformList: state.dashboard.platformList,
  sequences: state.builder.sequences,
  selectedSequence: state.builder.selectedSequence,
  defaultSequence: state.builder.defaultSequence,
  blocks: state.block.blocks,
  attributes: state.builder.attributes,
  apis: state.api.apis,
  loaders: state.loading,
  saveLoader: state.loading.effects.block.saveBlock,
  blockRules: state.block.blockRules,
  blockRulesEditId: state.block.blockRulesEditId,
  intentList: state.dashboard.intentList,
  updateRulesLoader: state.loading.effects.block.updateBlockRules,
  deleteRulesLoader: state.loading.effects.block.deleteBlockRules,
  permissionList: state.dashboard.permission?.list || [],
  permissionLoader: state.loading.effects.dashboard.fetchRolePermission,
  agentList: state.block.agentList,
  allTicketTags: state.block.allTicketTags,
  messageTemplates: state.crm.messageTemplates,
  teamGroups: state.settings.teamGroups,
  entities: state.builder.entities,
});

const mapDispatch = (dispatch) => ({
  fetchSequence: (platformId) => dispatch.builder.fetchSequence(platformId),
  fetchDataLabs: (projectId) => dispatch.dataLab.fetchDataLabs({ projectId }),
  handleSelectedLabData: ({ projectId, labId, blockId }) =>
    dispatch.block.handleSelectedLabData({ projectId, labId, blockId }),
  fetchAttributes: (platformId) => dispatch.builder.fetchAttributes(platformId),
  fetchEntities: (platformId) => dispatch.builder.fetchEntities(platformId),
  createSequence: (platformId, data) =>
    dispatch.builder.createSequence({ platformId, data }),
  deleteSequence: (platformId, sequenceId) =>
    dispatch.builder.deleteSequence({ platformId, sequenceId }),
  renameSequence: (sequenceId, name) =>
    dispatch.builder.renameSequenceInState({ sequenceId, name }),
  editSequence: (platformId, sequenceId, data) =>
    dispatch.builder.editSequence({ platformId, sequenceId, data }),
  setSelectedProject: (project) =>
    dispatch.dashboard.setSelectedProject(project),
  setSelectedPlatform: (platform) =>
    dispatch.dashboard.setSelectedPlatform(platform),
  setSelectedSequence: (sequence) =>
    dispatch.builder.setSelectedSequence(sequence),
  fetchBlock: (sequenceId) => dispatch.block.fetchBlock(sequenceId),
  createBlock: (sequenceId, type, data) =>
    dispatch.block.createBlock({ sequenceId, type, data }),
  updateDataLabBlockFieldAttribute: (data, id, changeKey) =>
    dispatch.block.updateDataLabBlockFieldAttribute({ data, id, changeKey }),
  updateTextBlock: (data, id, changeKey) =>
    dispatch.block.updateTextBlock({ data, id, changeKey }),
  updateEmailSendBlock: (data, id, changeKey) =>
    dispatch.block.updateEmailSendBlock({ data, id, changeKey }),
  saveBlock: (id) => dispatch.block.saveBlock(id),
  updateRedirectBlock: (data, id, changeKey) =>
    dispatch.block.updateRedirectBlock({ data, id, changeKey }),
  updateInputBlock: (data, id, changeKey) =>
    dispatch.block.updateInputBlock({ data, id, changeKey }),
  saveRedirectBlock: (id, serial, data) =>
    dispatch.block.saveRedirectBlock({ id, serial, data }),
  createButtonElement: (id) => dispatch.block.createButtonElement(id),
  updateButtonsBlock: (id, data, changeKey) =>
    dispatch.block.updateButtonsBlock({ id, data, changeKey }),
  updateLiveChatButtonBlock: (id, data, changeKey) =>
    dispatch.block.updateLiveChatButtonBlock({ id, data, changeKey }),
  updateButtonElements: (blockId, data) =>
    dispatch.block.updateButtonElements({ blockId, data }),
  updateGalleryBlock: (id, galleryIndex, data, changeKey) =>
    dispatch.block.updateGalleryBlock({ id, data, galleryIndex, changeKey }),
  updateURLBlocks: (id, data, changeKey) =>
    dispatch.block.updateURLBlocks({ id, data, changeKey }),
  updateSubscribeBlockTitle: (id, data) =>
    dispatch.block.updateSubscribeBlockTitle({ id, data }),
  createSubscribeElement: (id) => dispatch.block.createSubscribeElement({ id }),
  updateSubscribeSequenceData: (id, subscriptionIndex, data, changeKey) =>
    dispatch.block.updateSubscribeSequenceData({
      id,
      subscriptionIndex,
      data,
      changeKey,
    }),
  updateUnsubscribeBlock: (id, data) =>
    dispatch.block.updateUnsubscribeBlock({ id, data }),
  deleteButtonElement: (blockId, buttonIndex) =>
    dispatch.block.deleteButtonElement({ blockId, buttonIndex }),
  createGalleryElement: (id) => dispatch.block.createGalleryElement(id),
  updateGalleryElements: (blockId, data) =>
    dispatch.block.updateGalleryElements({ blockId, data }),
  updateGalleryButtonElements: (blockId, galleryIndex, data) =>
    dispatch.block.updateGalleryButtonElements({ blockId, galleryIndex, data }),
  createGalleryButtonElement: (blockId, galleryIndex) =>
    dispatch.block.createGalleryButtonElement({ blockId, galleryIndex }),
  deleteGalleryItem: (blockId, galleryIndex) =>
    dispatch.block.deleteGalleryItem({ blockId, galleryIndex }),
  deleteGalleryButtonElement: (blockId, buttonIndex, galleryIndex) =>
    dispatch.block.deleteGalleryButtonElement({
      blockId,
      buttonIndex,
      galleryIndex,
    }),
  updateImageBlock: (id, data) => dispatch.block.updateImageBlock({ id, data }),
  deleteBlock: (blockId) => dispatch.block.deleteBlock({ blockId }),
  copyBlock: (id, sequenceToCopy) =>
    dispatch.block.copyBlock({ id, sequenceToCopy }),
  moveBlock: (id, sequenceToMove) =>
    dispatch.block.moveBlock({ id, sequenceToMove }),
  reorderBlocks: (blocks) => dispatch.block.reorderBlocks(blocks),
  //api
  fetchAPI: (projectId) => dispatch.api.fetchAPI(projectId),
  createAPIButtonElement: (blockId, api, isDeleted) =>
    dispatch.block.createAPIButtonElement({ blockId, api, isDeleted }),
  fetchSubscribe: (platformId) => dispatch.builder.fetchSubscribe(platformId),
  updateGalleryBlockData: (blockId, key, value) =>
    dispatch.block.updateGalleryBlockData({ blockId, key, value }),
  fetchBlockRules: (sequenceId) => dispatch.rules.fetchBlockRules(sequenceId),
  fetchIntent: (channleId) => dispatch.dashboard.fetchIntent(channleId),
  updateBlockRulesChange: (rulesId, key, value) =>
    dispatch.block.updateBlockRulesChange({ rulesId, key, value }),
  updateBlockRulesEditId: (rulesId) =>
    dispatch.block.updateBlockRulesEditId(rulesId),
  createBlockRule: (selectedSequence) =>
    dispatch.block.createBlockRule(selectedSequence),
  deleteBlockRules: (sequenceId, rulesId) =>
    dispatch.block.deleteBlockRules({ sequenceId, rulesId }),
  updateBlockRules: (sequenceId, rulesId) =>
    dispatch.block.updateBlockRules({ sequenceId, rulesId }),
  deleteSubscriberSequenceData: (sequenceId, subscribeId) =>
    dispatch.block.deleteSubscriberSequenceData({ sequenceId, subscribeId }),
  generateRulesQrCode: (rulesId) => dispatch.block.generateRulesQrCode(rulesId),
  fetchAgent: (projectId) => dispatch.block.fetchAgent(projectId),
  fetchProject: () => dispatch.dashboard.fetchProject(),
  fetchTeamGroups: (projectId) => dispatch.settings.fetchTeamGroups(projectId),
  fetchAllTicketTags: (projectId) =>
    dispatch.block.fetchAllTicketTags(projectId),
  addProductDiscoveryBlockInputInstruction: (payloadData, blockId, save) =>
    dispatch.block.addProductDiscoveryBlockInputInstruction({
      payloadData,
      blockId,
      save,
    }),
  addProductDiscoveryBlockOutputInstructions: (payloadData, blockId) =>
    dispatch.block.addProductDiscoveryBlockOutputInstructions({
      payloadData,
      blockId,
    }),
  deleteProductDiscoveryBlockInputInstruction: (payloadData, blockId) =>
    dispatch.block.deleteProductDiscoveryBlockInputInstruction({
      payloadData,
      blockId,
    }),
  clearProductDiscoveryBlockInputInstructions: (blockId) =>
    dispatch.block.clearProductDiscoveryBlockInputInstructions({ blockId }),
  clearProductDiscoveryBlockOutputInstructions: (blockId) =>
    dispatch.block.clearProductDiscoveryBlockOutputInstructions({ blockId }),
  updateProductDiscoveryBlockData: (
    payloadData,
    blockId,
    changeKey,
    indexValue
  ) =>
    dispatch.block.updateProductDiscoveryBlockData({
      payloadData,
      blockId,
      changeKey,
      indexValue,
    }),

  updateBetterdocsBlockData: (payloadData, blockId, changeKey, indexValue) =>
    dispatch.block.updateBetterdocsBlockData({
      payloadData,
      blockId,
      changeKey,
      indexValue,
    }),
  handleProductDiscoveryBlockGalleryButton: (
    payloadData,
    blockId,
    actionType
  ) => {
    dispatch.block.handleProductDiscoveryBlockGalleryButton({
      payloadData,
      blockId,
      actionType,
    });
  },
  handleBetterdocsBlockGalleryButton: (payloadData, blockId, actionType) => {
    dispatch.block.handleBetterdocsBlockGalleryButton({
      payloadData,
      blockId,
      actionType,
    });
  },
  handleSetAttributeBlock: (payloadData, blockId, actionType) =>
    dispatch.block.handleSetAttributeBlock({
      payloadData,
      blockId,
      actionType,
    }),
  handleAddToCartBlock: (payloadData, blockId) =>
    dispatch.block.handleAddToCartBlock({
      payloadData,
      blockId,
    }),
  updatePlaceOrderData: (changeKey, changeValue, blockId) =>
    dispatch.block.updatePlaceOrderData({ changeKey, changeValue, blockId }),
  updateViewCartData: (changeKey, changeValue, blockId) =>
    dispatch.block.updateViewCartData({ changeKey, changeValue, blockId }),
  handleViewCartBlockGalleryButton: (payloadData, blockId, actionType) => {
    dispatch.block.handleViewCartBlockGalleryButton({
      payloadData,
      blockId,
      actionType,
    });
  },
  handleCouponBlock: (
    payloadData,
    blockId,
    actionType,
    instructionType,
    shouldSave
  ) => {
    dispatch.block.handleCouponBlock({
      payloadData,
      blockId,
      actionType,
      instructionType,
      shouldSave,
    });
  },
  updateBlockRulesList: (payload) =>
    dispatch.rules.updateBlockRulesList(payload),
  getWhatsAppMesssageTemplates: (platformId) =>
    dispatch.crm.getWhatsAppMesssageTemplates({ platformId }),
});

const BuilderContainer = connect(mapState, mapDispatch)(Builder);

export default React.memo(BuilderContainer);
