import React, { ChangeEvent } from 'react';
import { Input } from 'libraryV2/ui/input';
import { Label } from 'libraryV2/ui/label';

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'libraryV2/ui/tooltip';

interface Props {
  name?: string;
  label?: string;
  title?: string;
  className?: string;
  style?: any;
  labelStyle?: any;
  value: string | number | readonly string[] | undefined;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: any) => void;
  placeholder?: string;
  required?: boolean;
  focus?: boolean;
  error?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
}

const InputText: React.FC<Props> = ({
  name = '',
  label = '',
  title = '',
  className = 'grid items-center gap-1.5',
  style = { width: '268px' },
  labelStyle = {},
  value,
  onChange,
  placeholder = 'Enter label',
  required = false,
  focus = false,
  error = false,
  disabled = false,
  readOnly = false,
  onBlur,
}) => {
  return (
    <div className={className} style={style}>
      {label && (
        <Label htmlFor='labelForCustomer' style={{ ...labelStyle }}>
          {label}{' '}
          {required && (
            <span style={{ color: '#FF2323', marginLeft: '1px' }}>*</span>
          )}
        </Label>
      )}
      {readOnly ? (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger style={{ width: '100%' }}>
              <Input
                id={name}
                type='text'
                name={name}
                title={title}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                className={`${
                  error ? 'border-red-600' : ''
                } focus:ring-primary focus:border-primary sm:text-sm`}
                autoFocus={focus}
                disabled={disabled}
                readOnly={readOnly}
                style={{ cursor: readOnly ? 'not-allowed' : 'text' }}
              />
            </TooltipTrigger>
            <TooltipContent>
              <p>This field is not editable.</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ) : (
        <Input
          id={name}
          type='text'
          name={name}
          title={title}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          required={required}
          className={`${
            error
              ? 'border-red-600 focus:ring-red-600 focus:border-red-600'
              : 'focus:ring-primary focus:border-primary'
          } sm:text-sm`}
          autoFocus={focus}
          disabled={disabled}
        />
      )}

      {typeof error === 'string' && (
        <span className='text-xs text-red-600'>{error}</span>
      )}
    </div>
  );
};

export default InputText;
