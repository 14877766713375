import useTranslation from 'components/customHooks/useTranslation';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import preParsePostFormat from 'dayjs/plugin/preParsePostFormat';
import { ConversationBlockNoteDataInterface } from 'pages/inbox/inboxInterface';
import React from 'react';
import Time from '../components/conversation/Time';
import DateBar from './DateBar';

dayjs.extend(localizedFormat);
dayjs.extend(preParsePostFormat);
type ActionBarSubTypes =
  | 'assign-chat'
  | 'ticket-tag'
  | 'note'
  | 'reopen-ticket'
  | 'update-customer-attribute'
  | 'create-ticket';
interface Props {
  blockData: ConversationBlockNoteDataInterface;
  time: number;
  enableDateBar: boolean;
  isFeed?: boolean;
}

const ActionBar: React.FC<Props> = ({
  blockData,
  time,
  enableDateBar,
  isFeed = false,
}) => {
  const { t, dashboardLanguage } = useTranslation();
  const isAarabicLocale = dashboardLanguage === 'arabic';
  const timeLocale = isAarabicLocale ? 'ar' : 'en';
  const handleRedirectToAds = () => {
    let redirectLink = blockData?.payload?.includes('https')
      ? blockData.payload
      : `https://facebook.com/${blockData.payload}`;
    window.open(redirectLink, '_blank');
  };

  const getViewAdsLabel = () => {
    if (
      blockData?.sub_type === 'reply_to_ad' ||
      blockData?.sub_type === 'private_reply'
    )
      return (
        <>
          <div className='bg-white px-1 text-[8px] font-medium text-gray-400'>
            |
          </div>
          <div
            onClick={() => handleRedirectToAds()}
            className='bg-white text-xs font-normal text-blue-500 px-1 cursor-pointer'
          >
            {blockData?.sub_type === 'reply_to_ad'
              ? t('View Ad')
              : blockData?.sub_type === 'private_reply'
              ? t('View post')
              : ''}
          </div>
        </>
      );
    else if (
      blockData?.sub_type === 'facebook_referral' &&
      !!blockData?.payload
    ) {
      return (
        <>
          <div className='bg-white px-1 text-[8px] font-medium text-gray-400'>
            |
          </div>
          <span className='bg-white text-xs font-medium text-gray-500'>
            {' '}
            {t('Referral Parameter: ')}
            {blockData?.payload}
          </span>
        </>
      );
    }
    return null;
  };

  const translateBlockText = (text = '') => {
    if (!text || typeof text !== 'string') {
      return text;
    }
    const actionType: ActionBarSubTypes =
      blockData.sub_type as ActionBarSubTypes;
    switch (actionType) {
      case 'assign-chat': {
        const pattern = text.includes('A new ticket was created')
          ? 'A new ticket was created and was assigned to '
          : ' assigned the chat to ';
        const parts = text
          .split(pattern)
          .map((v) => `{{${v}}}`)
          .join(pattern)
          .replace('{{}}', '');
        return t(parts);
      }
      case 'ticket-tag': {
        const pattern = text.includes('tags')
          ? ' added tags: '
          : ' added a tag: ';
        const parts = text
          .split(pattern)
          .map((v) => `{{${v}}}`)
          .join(pattern);
        return t(parts);
      }
      case 'update-customer-attribute': {
        const pattern = ' updated a customer attribute: ';
        const parts = text
          .split(pattern)
          .map((v) => `{{${v}}}`)
          .join(pattern);
        return t(parts);
      }
      case 'note': {
        const pattern = text.includes('was resolved by')
          ? ' was resolved by '
          : ' closed the chat';
        const parts = text
          .split(pattern)
          .map((v) => `{{${v}}}`)
          .join(pattern)
          .replace('{{}}', '');

        return t(parts);
      }
      case 'create-ticket': {
        return t(text);
      }
      case 'reopen-ticket': {
        const pattern = ' was reopened by ';
        const parts = text
          .split(pattern)
          .map((v) => `{{${v}}}`)
          .join(pattern)
          .replace('{{}}', '');
        return t(parts);
      }

      default: {
        return text;
      }
    }
  };

  return (
    <>
      {!isFeed && (
        <>
          <div className='relative pt-3'>
            <div className='absolute inset-0 top-[30%] flex items-center'>
              <div className='w-full border-b border-gray-100' />
            </div>
            <div className='relative flex justify-center'>
              <div className='flex px-2.5 content-center bg-white'>
                <div
                  className='bg-white text-xs font-medium text-gray-500'
                  data-testid='text-action-bar'
                >
                  {blockData?.success
                    ? translateBlockText(blockData?.text)
                    : t('Action not Supported')}
                </div>
                <div className='bg-white px-1 text-[8px] font-medium text-gray-400'>
                  |
                </div>
                <div className='bg-white text-xs font-normal text-gray-400'>
                  {dayjs(time).locale(timeLocale).format('LT')}
                </div>
                {getViewAdsLabel()}
              </div>
            </div>
          </div>
          {!!enableDateBar && <DateBar time={time} />}
        </>
      )}
      {!!isFeed && (
        <div className='relative flex w-full h-auto ltr:pl-16 rtl:pr-16 py-3'>
          <span
            className='absolute border top-4 ltr:left-[34px] rtl:right-[34px] h-full'
            aria-hidden='true'
          />
          <span
            className='absolute top-[-5px] ltr:left-[34px] rtl:right-[34px] ltr:border-l-2 rtl:border-r-2 border-b-2 h-7 w-[30px]'
            aria-hidden='true'
          />
          <div className='flex px-2.5 content-center bg-white'>
            <div
              className='bg-white text-xs font-medium text-gray-400'
              data-testid='text-action-bar'
            >
              {blockData?.success ? blockData?.text : 'Action not Supported'}
            </div>
            <div className='bg-white px-1 text-[8px] font-medium text-gray-400'>
              |
            </div>
            <div className='bg-white text-xs font-normal text-gray-400'>
              <Time time={time} isMerged={false} isFeedPost={true} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ActionBar;
