import React, { useState } from 'react';
import { Fragment } from 'react';
import { Button } from 'library';
import { Transition, Menu, Popover } from '@headlessui/react';
import { classNames } from '../../../../../../utilities/utils';
import noMatchesIcon from '../../../../assets/images/noMatchesIcon.svg';
import OfflineAgentAssignModal from './OfflineAgentAssignModal';
import UserOrGroupSelectionComponent from './UserOrGroupSelectionComponent';
import useTranslation from 'components/customHooks/useTranslation';
interface Props {
  userId: number;
  hasClickedOnAgent: boolean;
  hasSupervisorAccess: boolean;
  agentGroup: GroupInterface[];
  agentList: AgentListInterface[];
  selectedTicket: TicketInterface;
  updateSateData: (key: any, value: any) => void;
  updateSingleTicket: (payload: UpdateSingleTicketReducerInterface) => void;
  reopenClosedTicket: (
    requestBody: assignTicketRequestBody
  ) => Promise<boolean>;
}

const ReopenTicketPopover: React.FC<Props> = ({
  userId,
  agentList,
  agentGroup,
  selectedTicket,
  updateSateData,
  reopenClosedTicket,
  updateSingleTicket,
  hasSupervisorAccess,
}) => {
  const { t } = useTranslation();
  const [notes, setNotes] = React.useState('');
  const [selectedAgentId, setSelectedAgentId] = React.useState<
    string | number | null
  >(null);
  const [selectedGroupId, setSelectedGroupId] = React.useState<
    string | number | null
  >(null);
  const [searchedAgentName, setSearchedAgentName] = React.useState('');
  const [searchedGroupName, setSearchedGroupName] = React.useState('');
  const [isAgentOffline, setIsAgentOffline] = React.useState(false);
  const [isAgentOrGroupMatchFound, setIsAgentOrGroupMatchFound] =
    React.useState(false);

  const [isReopenLoading, setReopenLoading] = useState<boolean>(false);

  const handleReopenTicket = async (close: Function) => {
    let requestBody = {
      ticketId: selectedTicket?.id,
      agentId: selectedAgentId,
      groupId: selectedGroupId,
      note: notes,
    };
    setReopenLoading(true);
    let res = await reopenClosedTicket(requestBody);
    setReopenLoading(false);
    if (res) {
      if (selectedAgentId === userId) {
        updateSateData('selectedQueueType', 'self');
      }
      setIsAgentOffline(false);
      close();
    }
  };

  const handleAgentStatuswiseAssignment = async (close: Function) => {
    let assignedAgent = agentList?.find(
      (agent) => selectedAgentId === agent?.admin.id
    );
    if (assignedAgent?.admin.status === 'online' || selectedGroupId !== null) {
      handleReopenTicket(close);
    } else {
      setIsAgentOffline(true);
    }
  };

  const shouldAssignButtonBeDisabled =
    selectedAgentId === null && selectedGroupId === null;

  const shouldEnableNoteAndAssignButton =
    !isAgentOrGroupMatchFound ||
    selectedAgentId !== null ||
    selectedGroupId !== null;

  const renderPopoverPanelView = (close: Function) => {
    return (
      <Menu as='div' className='relative' id='agent-list'>
        <Transition
          show={true}
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute max-h-[68vh] overflow-y-auto ltr:right-0 rtl:left-0 z-20 origin-top-right bg-white rounded-md shadow-lg w-72 ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <Menu.Item>
              {({ active }) => (
                <div
                  className={classNames(
                    active ? 'bg-white text-gray-900' : 'text-gray-700',
                    'block py-2 text-sm'
                  )}
                >
                  <UserOrGroupSelectionComponent
                    close={close}
                    userId={userId}
                    agentList={agentList}
                    agentGroup={agentGroup}
                    selectedTicket={selectedTicket}
                    shouldRenderSelectedAgent={false}
                    handleSelectedAgentId={setSelectedAgentId}
                    handleSelectedGroupId={setSelectedGroupId}
                    handleNoAgentMatchFound={setSearchedAgentName}
                    handleNoGroupMatchFound={setSearchedGroupName}
                    handleAgentOrGroupMatchFound={setIsAgentOrGroupMatchFound}
                  />
                </div>
              )}
            </Menu.Item>
            {searchedAgentName.length > 0 && searchedGroupName.length > 0 && (
              <div className='flex flex-col justify-center w-full p-6 mb-4'>
                <img
                  className={'ml-8 marker:text-gray-100 w-40 h-32'}
                  src={noMatchesIcon}
                  alt='noMatches'
                />
                <div className='flex justify-center w-full'>
                  <p className='font-normal text-gray-500 '>
                    {`No matches related to {{${searchedAgentName}}}`}
                  </p>
                </div>
              </div>
            )}
            {shouldEnableNoteAndAssignButton && (
              <>
                <Menu.Item>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active ? 'bg-white text-gray-900' : 'text-gray-700',
                        'block px-4 text-sm'
                      )}
                    >
                      <div className='flex justify-between mb-1'>
                        <span className='flex ml-1 text-base font-medium text-gray-700'>
                          {t('Note')}
                          <p className='px-2 mt-0.5 text-gray-500 text-sm'>
                            ({t('Optional')})
                          </p>
                        </span>
                      </div>
                      <textarea
                        rows={2}
                        name='comment'
                        defaultValue={''}
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        onKeyDown={(e: any) => {
                          e.code === 'Space' && e.stopPropagation();
                        }}
                        placeholder={t('Add a note for your teammate...')}
                        className='w-full h-20 p-2 text-sm font-normal border border-gray-300 rounded-md shadow-sm focus:border-primary focus:ring-primary'
                      />
                    </div>
                  )}
                </Menu.Item>
                <div className='w-full border-t divide-y divide-solid'>
                  <div className='flex'>
                    <div className='w-1/2 px-2'></div>
                    <div className='flex justify-end w-1/2 gap-2 p-2'>
                      <Button
                        size='xs'
                        onClick={() => {
                          setSelectedAgentId(null);
                          setSelectedGroupId(null);
                          setIsAgentOrGroupMatchFound(false);
                          close();
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        intent='primary'
                        size='xs'
                        onClick={() => {
                          handleAgentStatuswiseAssignment(close);
                        }}
                        isDisabled={
                          shouldAssignButtonBeDisabled || isReopenLoading
                        }
                      >
                        Reopen
                      </Button>
                    </div>
                  </div>
                </div>
              </>
            )}

            {isAgentOffline && (
              <OfflineAgentAssignModal
                close={close}
                agentList={agentList}
                isLoading={isReopenLoading}
                selectedTicket={selectedTicket}
                selectedAgentId={selectedAgentId}
                setIsAgentOffline={setIsAgentOffline}
                handleTicketAssignment={handleReopenTicket}
              />
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    );
  };

  return (
    <Popover className='relative'>
      {({ open, close }) => (
        <>
          <Popover.Button
            className={`
                ${open ? 'border-1 border-primary' : 'text-opacity-90'}
                 bg-primary text-white px-2 py-1.5 border border-primary shadow-sm rounded-md focus:outline-none`}
          >
            {t('Open Chat')}
          </Popover.Button>
          <Transition as={'div'}>
            <Popover.Panel className='absolute z-20 w-full mt-2'>
              {renderPopoverPanelView(close)}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default ReopenTicketPopover;
