import React from 'react';
import { isValidUrl } from '../../utilities/utils';
import { toaster } from 'evergreen-ui';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  handleChange: (key: string, value: string | string[]) => void;
  tagList: string[];
}

const InputTag: React.FC<Props> = ({ handleChange, tagList }) => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState('');

  const handleAdd = () => {
    if (isValidUrl(value)) {
      let updatedTagList = [...tagList, value];
      handleChange('whitelisted_domains', updatedTagList);
      setValue('');
    } else {
      toaster.warning('Add valid URL');
    }
  };

  const getInputTag = (value: string, id: number) => {
    return (
      <div
        key={id}
        className='my-2 mr-2 inset-y-0 left-2 pr-3 inline-flex items-center py-0.5 pl-2  rounded text-xs font-medium bg-green-100 text-green-800'
      >
        {value?.length > 30 ? value.substring(0, 30) + '...' : value}
        <button
          type='button'
          className='flex-shrink-0 ml-0.5 h-4 w-4 inline-flex items-center justify-center text-indigo-400 hover:bg-transparent hover:text-gary-700 focus:outline-none focus:bg-transparent focus:text-white'
          onClick={() => {
            let updatedTagList = [
              ...tagList.slice(0, id),
              ...tagList.slice(id + 1),
            ];
            handleChange('whitelisted_domains', updatedTagList);
          }}
        >
          <svg
            className='w-2 h-2'
            stroke='#34D399'
            fill='#34D399'
            viewBox='0 0 8 8'
          >
            <path
              strokeLinecap='round'
              strokeWidth='1.5'
              d='M1 1l6 6m0-6L1 7'
            />
          </svg>
        </button>
      </div>
    );
  };

  return (
    <div>
      <div className='flex mt-1 rounded-md shadow-sm'>
        <span className='inline-flex items-center px-3 text-gray-500 border ltr:border-r-0 border-gray-300 ltr:rounded-l-md rtl:rounded-r-md bg-gray-50 sm:text-sm'>
          https://
        </span>
        <input
          type='text'
          name='whitelisted_domains'
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(e.target.value);
          }}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter' && value.length !== 0) handleAdd();
          }}
          className='flex-1 block w-full min-w-0 px-3 py-2 border-gray-300 rounded-none ltr:rounded-r-md rtl:rounded-l-md rtl:rounded-r-0 focus:ring-primary focus:border-primary sm:text-sm'
          placeholder='example.com'
        />
      </div>
      <p className='my-2 text-sm text-gray-500'>
        {t('Write the domain name here and press "Enter"')}
      </p>
      {tagList.length !== 0 && (
        <label
          htmlFor='url_whitelist'
          className='block mt-3 text-sm font-medium text-gray-700'
        >
          {t('Tags')}
        </label>
      )}
      {tagList.length !== 0 &&
        tagList.map((item: string, id) => {
          return getInputTag(item, id);
        })}
    </div>
  );
};
export default InputTag;
