import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import Button from 'library/button';
import { RedirectLinkIcon } from 'library/icon';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';
import React, { Fragment, ReactNode } from 'react';

interface Props {
  open: boolean;
  title: string;
  loading?: boolean;
  linkText?: string;
  children: ReactNode;
  hideFooter?: boolean;
  headerIcon: ReactNode;
  handleClose: () => void;
  suggestionText?: string;
  submitButtonText?: string;
  cancelButtonText?: string;
  hideCancelButton?: boolean;
  hideSubmitButton?: boolean;
  documentationLink?: string;
  disableCancelButton?: boolean;
  disableSubmitButton?: boolean;
  isSubmitButtonLoading?: boolean;
  onSubmitButtonClick?: () => void;
  onCancelButtonClick?: () => void;
}

const BlankModal: React.FC<Props> = ({
  open,
  title,
  children,
  headerIcon,
  handleClose,
  suggestionText,
  loading = false,
  submitButtonText,
  cancelButtonText,
  documentationLink,
  hideFooter = false,
  onSubmitButtonClick,
  onCancelButtonClick,
  hideCancelButton = false,
  hideSubmitButton = false,
  linkText = 'Documentation',
  disableCancelButton = false,
  disableSubmitButton = false,
  isSubmitButtonLoading = false,
}) => {
  const handleCancelButtonClick = () => {
    if (onCancelButtonClick) {
      onCancelButtonClick();
    } else {
      handleClose();
    }
  };

  const handleSubmitButtonClick = () => {
    if (!!onSubmitButtonClick) {
      onSubmitButtonClick();
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto'
        onClose={handleClose}
      >
        <Transition.Child
          as={Fragment}
          leaveTo='opacity-0'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leaveFrom='opacity-100'
          leave='ease-in duration-200'
          enter='ease-out duration-300'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-20 overflow-y-auto'>
          <div className='flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              leave='ease-in duration-200'
              enter='ease-out duration-300'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='inline-block align-bottom bg-white rounded-lg text-left overflow-y-auto shadow-xl transform transition-all sm:align-middle sm:max-w-2xl sm:w-full'>
                <div>
                  <div className='bg-white space-y-3'>
                    <div className='flex gap-2 items-center justify-start w-full px-6 py-3 border-b border-gray-200'>
                      {headerIcon}
                      <span className='text-base font-semibold leading-6 text-gray-700'>
                        {title}
                      </span>

                      <XMarkIcon
                        height={20}
                        color='#6B7280'
                        onClick={() => handleClose()}
                        className='float-right ltr:ml-auto rtl:mr-auto cursor-pointer'
                      />
                    </div>

                    <div className='space-y-6 px-6 py-3'>{children}</div>
                  </div>
                  {!hideFooter && (
                    <div className='border-t border-gray-200 px-4 py-3 sm:px-6 flex items-center justify-start'>
                      {suggestionText && documentationLink && (
                        <p className='text-sm text-gray-500 flex justify-start items-center'>
                          {RedirectLinkIcon()}
                          {suggestionText}{' '}
                          <a
                            target='_blank'
                            href={documentationLink}
                            rel='noopener noreferrer'
                            className='text-blue-500 ltr:ml-2 rtl:mr-2 hover:underline flex justify-center items-center'
                          >
                            {linkText}{' '}
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              fill='none'
                              viewBox='0 0 24 24'
                              strokeWidth='1.5'
                              stroke='currentColor'
                              className='w-5 h-5 ltr:ml-1 rtl:mr-1'
                            >
                              <path
                                strokeLinecap='round'
                                strokeLinejoin='round'
                                d='M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25'
                              />
                            </svg>
                          </a>
                        </p>
                      )}
                      <div className='ltr:space-x-4 flex gap-2 ltr:ml-auto rtl:mr-auto'>
                        {!hideCancelButton && (
                          <Button
                            size='sm'
                            intent='default'
                            isDisabled={disableCancelButton}
                            onClick={() => handleCancelButtonClick()}
                          >
                            {cancelButtonText}
                          </Button>
                        )}
                        {!hideSubmitButton && (
                          <Button
                            size='sm'
                            intent='primary'
                            isDisabled={disableSubmitButton || loading}
                            isLoading={isSubmitButtonLoading}
                            onClick={() => handleSubmitButtonClick()}
                          >
                            {submitButtonText}{' '}
                            {loading && (
                              <CircleSpinner className='animate-spin h-5 w-5 text-gray-500 inline ' />
                            )}
                          </Button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default BlankModal;
