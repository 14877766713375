import {
  DropdownMenu,
  Button,
  cn,
  Column,
  useDatalab,
  // EyeNoneIcon,
} from '../../../export';

interface DataTableColumnHeaderProps<TData, TValue>
  extends React.HTMLAttributes<HTMLDivElement> {
  column: Column<TData, TValue>;
  title: string;
}

export function DataTableColumnHeader<TData, TValue>({
  column,
  title,
  className,
}: DataTableColumnHeaderProps<TData, TValue>) {
  const { lab_fields } = useDatalab();

  const capitalizeKey = (key: string) => {
    const words = key.split('_').map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return words.join(' ');
  };

  const getColumnTitle = (slug: string) => {
    for (const field of lab_fields) {
      if (field.slug === slug) {
        return field.label_agent || field.name;
      }
    }
    return capitalizeKey(slug);
  };

  if (!column.getCanSort()) {
    return <div className={cn(className)}>{getColumnTitle(title)}</div>;
  }

  return (
    <div className={cn('flex items-center space-x-2', className)}>
      <DropdownMenu>
        <Button
          variant='ghost'
          size='sm'
          className='-ml-3 h-8 data-[state=open]:bg-accent'
        >
          <span className='text-[#71717A]'>{getColumnTitle(title)}</span>
        </Button>
      </DropdownMenu>
    </div>
  );
}
