import { useEffect, useState } from 'react';
import backgroundImg from '../../assets/whatsappBg.png';
import { ArrowTopRightWithBox, LeftArrow } from './svgImg';
import { WhatsappMessageTemplateProps } from 'index';
import { transformTextStyle } from 'pages/inbox/utils/functions';
import { DocumentIcon, PlayCircleIcon } from '@heroicons/react/24/solid';
import usePartner from 'components/customHooks/usePartner';
import MyAliceIcon from 'assets/images/aliceIcon.svg';
import { useSelector } from 'react-redux';

interface Props {
  title?: string;
  templateId?: number | string;
  width?: string;
  height?: string;
  templateList: WhatsappMessageTemplateProps[] | null;
}

const defaultData = {
  header: {
    type: 'text',
    value: '',
  },
  body: '',
  buttons: [],
  footer: '',
};

const BroadcastPreview: React.FC<Props> = ({
  title = 'Hi There',
  templateId = '0',
  height,
  width,
  templateList = [],
}) => {
  const teamIcon = useSelector(
    (state: any) => state?.dashboard?.selectedProject?.image
  );

  const { getPartnerIcon } = usePartner();
  const [template, setTemplate] = useState(defaultData);

  useEffect(() => {
    const index =
      templateList && templateList.findIndex((data) => data.id === templateId);

    if (
      index !== -1 &&
      Array.isArray(templateList) &&
      templateList &&
      templateList.length > 0
    )
      // @ts-ignore
      setTemplate(templateList[index !== -1 ? index : 0]);
    else setTemplate(defaultData);
  }, [templateId, templateList]);

  const renderTeamIcon = () => {
    if (teamIcon?.length) {
      return (
        <img src={teamIcon} className='h-5 w-5 rounded-full' alt='team-icon' />
      );
    }
    const partnerIconData = getPartnerIcon();
    if (partnerIconData) {
      return (
        <div className='flex items-center h-7 w-7 bg-white'>
          {partnerIconData}
        </div>
      );
    }

    return (
      <img className='w-6 h-6 rounded-full' src={MyAliceIcon} alt='team-icon' />
    );
  };

  const renderHeader = () => {
    switch (template.header?.type) {
      case 'image': {
        return (
          <div className='w-full h-auto'>
            <img
              src={template.header?.value}
              className='w-full rounded-md'
              alt='header_img'
            />
          </div>
        );
      }
      case 'video': {
        return (
          <div className={`flex w-full`}>
            <div key={1} className='relative cursor-pointer  w-full mx-auto'>
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md h-[120px] w-full'>
                <source src={template.header.value} />
              </video>
            </div>
          </div>
        );
      }
      case 'document': {
        return (
          <div className='w-full flex flex-col items-center rounded-md border border-gray-300 px-4 py-6'>
            <DocumentIcon className='w-9 h-9 text-gray-400' />
            <div className='mt-2 w-full'>
              <p className='text-xs font-medium leading-5 text-gray-600 truncate'>
                {template.header.value.split('/').pop()}
              </p>
            </div>
          </div>
        );
      }
      default: {
        return (
          <div className='w-full h-auto'>
            <span className=' font-bold text-sm text-[#131B20] break-words whitespace-normal'>
              {template.header?.value}
            </span>
          </div>
        );
      }
    }
  };
  const renderButtons = () => {
    return (
      template.buttons &&
      template.buttons.map((data, index) => {
        return (
          <button
            key={index}
            className='flex items-center justify-center flex-row p-1 w-full text-[#2D83E8] text-sm rounded-md bg-white mb-1 break-words whitespace-normal'
          >
            {/* @ts-ignore */}
            {data.type === 'url' && (
              <ArrowTopRightWithBox color='#2D83E8' width={20} height={20} />
            )}
            {/* @ts-ignore */}
            {data.title}
          </button>
        );
      })
    );
  };

  return (
    <>
      <div
        className={`relative ${!!height ? height : 'h-5/6 min-h-[376px]'} ${
          !!width ? width : 'w-full min-w-[376px]'
        }`}
      >
        <img
          src={backgroundImg}
          className='absolute top-0 ltr:left-0 rtl:right-0 w-full h-full'
          alt='preview'
        />
        <div className='w-full absolute h-auto max-h-full overflow-y-auto '>
          <div className='w-full flex items-center rtl:justify-end rtl:pr-3 py-2 bg-[#F6F6F6]'>
            <LeftArrow classNames='ml-2 rtl:order-1 rtl:mr-auto' />
            {!!title && (
              <>
                {renderTeamIcon()}

                <span className='font-medium text-sm ltr:ml-1 rtl:mr-1 rtl:text-right text-[#131B20]'>
                  {title}
                </span>
              </>
            )}
          </div>
          <div className='mt-3 w-2/3 ltr:ml-2 rtl:mr-2'>
            {/* Header & body */}
            <div
              className={`p-1 rounded-md mb-1 ${
                template.body === '' ? '' : 'bg-white'
              }`}
            >
              {renderHeader()}
              <div className='w-full h-auto'>
                <span
                  className='font-normal leading-3 text-xs text-gray-500'
                  dangerouslySetInnerHTML={{
                    __html: transformTextStyle(template.body),
                  }}
                />
              </div>
              {/* Footer */}
              <div className='w-full h-auto'>
                <span className='font-normal leading-3 text-xs text-gray-400'>
                  {template?.footer}
                </span>
              </div>
            </div>
            {renderButtons()}
          </div>
        </div>
      </div>
    </>
  );
};

export default BroadcastPreview;
