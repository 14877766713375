import {
  React,
  ReactTable as Table,
  Cross2Icon,
  Button,
  DataTableViewOptions,
  FilterIcon,
  FilterSideSheet,
  useDatalab,
} from '../../../export';

interface DataTableToolbarProps<TData> {
  table: Table<TData>;
  selectedRowCount: number;
  hasDeleteAccess: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

export function DataTableToolbar<TData>({
  table,
  selectedRowCount = 0,
  hasDeleteAccess = false,
  onDelete = () => {},
  onCancel = () => {},
}: DataTableToolbarProps<TData>) {
  const {
    pageChange,
    updatePageChange,
    storedFilterData,
    updateFilterData,
    updateIsFilterState,
    fetchDatalabEntries,
  } = useDatalab();
  const [isFilterOpened, setIsFilterOpened] = React.useState<boolean>(false);
  let isFilterApplied = storedFilterData.length !== 0;

  const handleResetButtonOnClick = async () => {
    updateFilterData([]);
    updatePageChange(pageChange.limit, 0);
    await updateIsFilterState(false);
    await fetchDatalabEntries();
  };

  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center flex-1 space-x-2'>
        {/* Search option will be enabled when backend API is ready */}
        {/* <div className='relative flex items-center'>
          <Search className='absolute w-4 h-4 text-gray-500 left-2' />
          <Input
            placeholder='Search'
            value={(table.getState().globalFilter as string) ?? ''}
            onChange={(event) => table.setGlobalFilter(event.target.value)}
            className='h-8 w-[150px] lg:w-[250px] pl-8'
          />
        </div> */}
        <Button
          variant='outline'
          size='sm'
          onClick={() => setIsFilterOpened(!isFilterOpened)}
        >
          <FilterIcon className='w-4 h-4 mr-2' /> Filter
        </Button>
        {isFilterApplied && (
          <Button
            variant='ghost'
            onClick={() => handleResetButtonOnClick()}
            className='h-8 px-2 lg:px-3 bg-[#F4F4F5]'
          >
            Reset
            <Cross2Icon className='w-4 h-4 ml-2' />
          </Button>
        )}
        {
          <FilterSideSheet
            isOpen={isFilterOpened}
            onClose={() => setIsFilterOpened(false)}
          />
        }
      </div>
      <DataTableViewOptions
        table={table}
        selectedRowCount={selectedRowCount}
        hasDeleteAccess={hasDeleteAccess}
        onDelete={onDelete}
        onCancel={onCancel}
      />
    </div>
  );
}
