import React from 'react';
import { XCircleIcon } from '@heroicons/react/20/solid';

interface Props {
  success?: boolean;
  errorMessage?: string;
  viberSettingData: viberSettingDataProps;
  handleChange: (key: string, value: string | string[] | boolean) => void;
}

const Step1: React.FC<Props> = ({
  success = true,
  errorMessage,
  handleChange,
  viberSettingData,
}) => {
  return (
    <>
      {!success && (
        <div className='flex justify-center'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='ml-2 text-sm text-red-600'>{errorMessage}</p>
        </div>
      )}
      <div>
        <div className='col-span-6 sm:col-span-6'>
          <label
            htmlFor='title'
            className='block text-sm font-medium text-gray-700'
          >
            Title*
          </label>
          <input
            type='text'
            placeholder='My viber channel'
            value={viberSettingData.title}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='title'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm text-gray-500'>
            This title will be used in MyAlice to identify this channel. Give it
            a title that you can differentiate with later.
          </p>
        </div>
        <div className='col-span-6 mt-5 sm:col-span-6'>
          <label
            htmlFor='primary_token'
            className='block text-sm font-medium text-gray-700'
          >
            Primary Token*
          </label>
          <input
            type='text'
            placeholder='Primary token found in your viber account'
            value={viberSettingData.primary_token}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              handleChange(e.target.name, e.target.value)
            }
            name='primary_token'
            className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
          />
          <p className='mt-2 text-sm font-medium text-gray-500'>
            To connect your Viber account with MyAlice, you should create an
            account through 
            <a
              href='https://partners.viber.com/'
              target='_blank'
              className='text-blue-500 hover:text-blue-800'
              rel='noreferrer'
            >
              {`Viber Admin Panel, `}
            </a>
            if you don’t have one already. Then provide the Primary Token which
            you will find in the admin panel. For details,
            <a
              href='https://docs.myalice.ai/connect-social-channels/connect-social-media/connect-viber'
              target='_blank'
              className='text-blue-500 hover:text-blue-800'
              rel='noreferrer'
            >
              {` Read this. `}
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
export default Step1;
