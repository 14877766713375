/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  sequenceProps,
  channelListProps,
  whatsappMessageTemplateProps,
} from 'pages/integration/interface';
import { toaster } from 'evergreen-ui';
import WhatsappSideSheet from '../whatsapp/components/WhatsappSideSheet';
import WhatsappTemplateBody from '../whatsapp/components/WhatsappTemplateBody';
import WhatsappTemplateMenu from '../whatsapp/components/WhatsappTemplateMenu';
import WhatsappTemplateHeader from '../whatsapp/components/WhatsappTemplateHeader';
import WhatsappTemplateFooter from '../whatsapp/components/WhatsappTemplateFooter';
import AuthenticationTemplateOptions from '../whatsapp/components/AuthenticationTemplateOptions';
import WhatsappTemplatePreview from '../whatsapp/components/WhatsappTemplatePreview';
import useWhatsappTemplateData from 'pages/integration/hooks/useWhatsappTemplateData';
import WhatsappTemplateButtonBlock from '../whatsapp/components/WhatsappTemplateButtonBlock';
import useTranslation from 'components/customHooks/useTranslation';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { WhatsappCarouselTemplate } from '../whatsapp/components/WhatsappCarouselTemplate';

export type WhatsappTemplateActionType = 'CREATE' | 'VIEW' | 'EDIT';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  channelData: channelListProps;
  templateData?: whatsappMessageTemplateProps;
  actionType: WhatsappTemplateActionType;
  sequence: sequenceProps[];
}

const templateDetailsText = {
  CREATE: {
    title: 'Create Message Template',
    confirmButtonText: 'Save & Request Approval',
  },
  VIEW: {
    title: 'View Message Template',
    confirmButtonText: 'Back',
  },
  EDIT: {
    title: 'Edit Message Template',
    confirmButtonText: 'Submit for Review',
  },
};

const CreateWhatsAppTemplateV2: React.FC<Props> = ({
  isOpen,
  handleClose,
  channelData,
  templateData,
  actionType,
  sequence,
}) => {
  const { t } = useTranslation();
  const {
    isValidTemplateData,
    updateWhatsappTemplateData,
    resetWhatsappTemplateState,
    editWhatsappMessageTemplate,
    createWhatsappMessageTemplate,
    createWhatsapptemplateLoading,
    whatsappTemplateState,
    selectedProject,
  } = useWhatsappTemplateData();

  const [isTemplateRequestLoading, setIsTemplateRequestLoading] =
    useState<boolean>(createWhatsapptemplateLoading);
  const [hasInvalidBodyVariable, setHasInvalidBodyVariable] =
    useState<boolean>(true);

  const isCaroselTemplateType =
    whatsappTemplateState.templateType === 'CAROUSEL';

  const isAuthenticationTemplate =
    whatsappTemplateState.category === 'AUTHENTICATION';

  const divHeightOffset = actionType === 'CREATE' ? '130px' : '160px';

  const shouldDisableTemplateSubmit = !(
    isValidTemplateData && hasInvalidBodyVariable
  );

  const whatsappTemplateApiCallBack = (response: any) => {
    setIsTemplateRequestLoading(false);
    if (response?.status === 500) {
      toaster.danger(
        t(
          `Could not process data to ${actionType.toLowerCase()} template. Please try again!`
        )
      );
    } else if (response?.data?.dataSource?.success) {
      if (actionType === 'CREATE' || actionType === 'EDIT') {
        toaster.success(
          t(
            `Message template ${
              actionType === 'CREATE' ? 'created' : 'edited'
            } successfully!`
          )
        );
      } else {
        toaster.success(response?.data?.dataSource?.message);
      }
      handleClose();
    } else if (response?.data?.error) {
      toaster.danger(response.data.error);
    } else {
      response?.data?.dataSource?.error_message
        ? toaster.danger(response.data.dataSource.error_message)
        : toaster.danger(
            t(
              `Please provide valid data to ${actionType.toLowerCase()} message template.`
            )
          );
    }
  };

  const getSideSheetDescription = (
    templateActionType: WhatsappTemplateActionType
  ) => {
    if (templateActionType === 'CREATE') return null;
    return (
      <div className='flex mt-2'>
        <div className='inline text-gray-600'>
          <div className='inline-block font-normal text-sm w-20'>
            {t('Template ID')}
          </div>
          : {templateData?.id}
        </div>

        {!!channelData?.id && (
          <div className='flex text-gray-600'>
            <span className='mx-1 h-4 mt-0.5 border-r border-l border-gray-500'></span>
            <span className='inline-block font-normal text-sm w-30'>
              {t('Channel ID')}
            </span>
            <span>{`: ${channelData?.id}`}</span>
          </div>
        )}
      </div>
    );
  };

  const handleSideSheetConfirm = async () => {
    if (actionType === 'CREATE') {
      setIsTemplateRequestLoading(true);
      createWhatsappMessageTemplate({
        channelData: channelData,
        callback: whatsappTemplateApiCallBack,
      });
    } else if (actionType === 'EDIT') {
      setIsTemplateRequestLoading(true);
      editWhatsappMessageTemplate({
        channelData: channelData,
        callback: whatsappTemplateApiCallBack,
        templateId: templateData?.id!,
      });
    } else {
      handleClose();
    }
  };

  const renderRejectionReason = () => {
    if (actionType !== 'VIEW' || !templateData?.failed_reason) {
      return null;
    }
    return (
      <div className='w-full flex gap-2 mb-2 p-4 items-start rounded-md bg-red-100'>
        <div className=' mt-0.5'>
          <ExclamationTriangleIcon
            height={'20px'}
            className='text-red-600 outline-none'
          />
        </div>
        <p className='text-red-800 text-sm leading-5'>
          {templateData?.failed_reason}
        </p>
      </div>
    );
  };

  useEffect(() => {
    if (actionType !== 'CREATE') {
      updateWhatsappTemplateData({
        ...templateData,
        headerType:
          templateData?.header?.type ||
          templateData?.cards?.at(0)?.header?.type,
        templateType: templateData?.template_type || 'GENERIC',
        headerValue: templateData?.header?.value,
        hasSecurityRecommendation: !!templateData?.has_security_recommendation,
        hasAuthCodeExpirationTime: !!templateData?.code_expiration_minutes,
        authCodeExpirationTime: templateData?.code_expiration_minutes ?? '',
        activeCarouselCardIndex: 1,
      });
    }

    return () => {
      resetWhatsappTemplateState();
    };
  }, [actionType]);

  const renderLoaderAnimation = () => {
    return (
      <div className='flex w-full h-[500px] items-center justify-center'>
        <div className='border-4 border-b-green-500 border-l-green-500 border-r-green-500 border-t-transparent rounded-full animate-spin h-16 w-16 mr-3 p-3'>
          {''}
        </div>
      </div>
    );
  };

  const renderWhatsappTemplates = () => {
    if (isAuthenticationTemplate) {
      return (
        <>
          <WhatsappTemplateMenu actionType={actionType} />
          <WhatsappTemplateBody
            setHasInvalidBodyVariable={setHasInvalidBodyVariable}
            actionType={actionType}
          />
          <AuthenticationTemplateOptions actionType={actionType} />
          <WhatsappTemplateFooter actionType={actionType} />
        </>
      );
    } else if (isCaroselTemplateType) {
      return (
        <>
          <WhatsappTemplateMenu actionType={actionType} />
          <WhatsappTemplateHeader actionType={actionType} />
          <WhatsappTemplateBody
            setHasInvalidBodyVariable={setHasInvalidBodyVariable}
            actionType={actionType}
          />
          <WhatsappCarouselTemplate
            actionType={actionType}
            sequence={sequence}
          />
        </>
      );
    } else {
      return (
        <>
          <WhatsappTemplateMenu actionType={actionType} />
          <WhatsappTemplateHeader actionType={actionType} />
          <WhatsappTemplateBody
            setHasInvalidBodyVariable={setHasInvalidBodyVariable}
            actionType={actionType}
          />
          <WhatsappTemplateFooter actionType={actionType} />
          <WhatsappTemplateButtonBlock
            sequence={sequence}
            channelData={channelData}
            actionType={actionType}
          />
        </>
      );
    }
  };

  return (
    <>
      <WhatsappSideSheet
        open={isOpen}
        hasMinimizeButton={false}
        handleClose={() => {
          handleClose();
        }}
        closeOnExternalClick={false}
        title={t(templateDetailsText[actionType].title)}
        description={getSideSheetDescription(actionType)}
        confirmText={t(templateDetailsText[actionType].confirmButtonText)}
        handleConfirm={handleSideSheetConfirm}
        hideCancel={true}
        disableConfirm={isTemplateRequestLoading || shouldDisableTemplateSubmit}
        width='w-[75%]'
      >
        <div className='flex gap-2 h-screen'>
          <div
            style={{ height: `calc(100vh - ${divHeightOffset})` }}
            className={`flex-1 overflow-auto p-4 ${
              isTemplateRequestLoading ? 'hidden' : 'visible'
            }`}
          >
            {renderRejectionReason()}
            {renderWhatsappTemplates()}
          </div>
          <div
            className={`relative w-1/3 h-full bg-white p-4 ${
              isTemplateRequestLoading ? 'hidden' : 'visible'
            }`}
          >
            <WhatsappTemplatePreview
              channelIcon={selectedProject?.image}
              channelName={channelData.name!}
              templateData={whatsappTemplateState}
            />
          </div>
          {isTemplateRequestLoading && renderLoaderAnimation()}
        </div>
      </WhatsappSideSheet>
    </>
  );
};
export default CreateWhatsAppTemplateV2;
