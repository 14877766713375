import React from 'react';
import PropTypes from 'prop-types';
import Alert from '../../../old/components/elements/redesign/Alert';
import PasswordField from '../../../components/utilityComponent/PasswordField';

import { Button, Link } from '../../../library';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

const LoginFormSection = ({
  email,
  content,
  loading,
  password,
  loginFailed,
  handleChange,
  handleSubmit,
  errorCode,
  errorMessage,
}) => {
  const { isPartnerRestricted } = usePartner();
  const { authT } = useTranslation();
  const shouldHideForgetPasswordLink = isPartnerRestricted(
    'LoginFormSection/node/forgot_password_url'
  );
  return (
    <div className='space-y-6'>
      <div>
        <label
          htmlFor='email'
          className='block text-sm font-medium text-gray-700'
        >
          {authT('Your Email address')}
        </label>
        <div className='mt-1'>
          <input
            id='email'
            name='email'
            type='email'
            autoComplete='email'
            placeholder='example@company.com'
            value={email}
            onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
            onChange={(e) => handleChange(e.target.name, e.target.value)}
            required
            className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
          />
          {loginFailed && errorCode === 'no_user_found' && !!errorMessage ? (
            <div className='mt-3'>
              <Alert intent='danger' text={errorMessage} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div>
        <label
          htmlFor='password'
          className='block text-sm font-medium text-gray-700'
        >
          {authT('Your Password')}
        </label>
        <div className='mt-1'>
          <PasswordField
            password={password}
            handleChange={(name, value) => handleChange(name, value)}
            handleSubmit={handleSubmit}
          />
          {loginFailed && errorCode !== 'no_user_found' && !!errorMessage ? (
            <div className='mt-3'>
              <Alert intent='danger' text={errorMessage} />
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div
        className={`flex items-center justify-end ${
          shouldHideForgetPasswordLink ? 'hidden' : 'visible'
        }`}
      >
        <Link textSize={'sm'} url={content.forgetInfo.link}>
          {authT(content.forgetInfo.linkTitle)}
        </Link>
      </div>
      <div>
        <Button
          intent='primary'
          isFullWidth={true}
          isLoading={loading}
          size='full'
          onClick={() => handleSubmit()}
        >
          {loading ? authT('Signing In') : authT('Sign In')}
          {loading && (
            <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-gray-500 inline' />
          )}
        </Button>
      </div>
    </div>
  );
};
LoginFormSection.propTypes = {
  content: PropTypes.any,
  email: PropTypes.string,
  password: PropTypes.string,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  loginLoading: PropTypes.bool,
  errorMessage: PropTypes.string,
};
export default LoginFormSection;
