import React from 'react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from 'libraryV2/ui/alert-dialog';

interface DeleteAlertDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  title: string;
  description: string;
  cancelTitle?: string;
  confirmTitle?: string;
  onCancel: () => void;
  onConfirm: () => void;
  isConfirming?: boolean;
}

const DeleteAlert: React.FC<DeleteAlertDialogProps> = ({
  open,
  onOpenChange,
  title,
  description,
  onCancel,
  onConfirm,
  cancelTitle = 'No',
  confirmTitle = 'Yes! Delete',
  isConfirming = false,
}) => {
  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={onCancel} disabled={isConfirming}>
            {cancelTitle}
          </AlertDialogCancel>
          <AlertDialogAction
            className='ml-1 text-white bg-[#FF0000] hover:bg-red-600'
            onClick={onConfirm}
            disabled={isConfirming}
          >
            {confirmTitle}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteAlert;
