import React from 'react';
import Button from '../../../../library/button';
import LineModal from '../onboardingChannels/lineMessenger/LineModal';
import ViberModal from '../onboardingChannels/viberMessenger/ViberModal';
import LivechatModal from '../onboardingChannels/livechat/LivechatModal';
import WhatsappModal from '../onboardingChannels/whatsappBSP/WhatsappModal';
import TelegramModal from '../onboardingChannels/telegramMessenger/TelegramModal';
import FacebookModal from '../onboardingChannels/facebookMessenger/FacebookModal';
import InstagramModal from '../onboardingChannels/instagramMessenger/InstagramModal';

import { connect } from 'react-redux';
import { ArrowLeftIcon, ArrowRightIcon } from '@heroicons/react/20/solid';
import { OnboardingChannelList } from '../../utils/content';
import FacebookFeedModal from '../onboardingChannels/facebookFeed/FacebookFeedModal';
import InstagramFeedModal from '../onboardingChannels/instagramFeed/InstagramFeedModal';
import { navigate } from '@reach/router';
import { mixpanelTrack } from 'utilities/mixpanel';
import useTranslation from 'components/customHooks/useTranslation';
interface IData {
  data?: CreatorInterface;
  success: boolean;
}
interface Props {
  auth: CreatorInterface;
  projects: SelectedTeamInterface[];
  updateAccountInfo: (auth: CreatorInterface) => IData;
  handleSelectStore: (step: string) => void;
  userAccountInfo: () => void;
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) => Promise<channelCreateAPiResponseType>;
  fetchFacebookPages: (
    id: string,
    token: string,
    type: string
  ) => Promise<facebookPageListProps[]>;
  fetchInstagramPages: (
    id: string,
    token: string,
    type: string
  ) => Promise<instagramPagesListProps[]>;
  instagramMessengerChannelConnectionValidation: (
    channelType: string,
    teamId: number,
    data: instagramConnectionRequestProps
  ) => Promise<instagramMessengerConnectionAPiResponseType>;
}

const ChannelPicker: React.FC<Props> = ({
  auth,
  projects,
  updateAccountInfo,
  userAccountInfo,
  handleSelectStore,
  fetchFacebookPages,
  fetchInstagramPages,
  createChannelIntegrations,
  instagramMessengerChannelConnectionValidation,
}) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  const [selectedChannel, setSelectedChannel] = React.useState('');

  const handleChannelCreate = async (
    channelName: string,
    channelPayloadData: any
  ) => {
    let res = await createChannelIntegrations(
      channelName,
      projects[0].id,
      channelPayloadData
    );
    if (res.status === 200) {
      await userAccountInfo();
    }
    return res;
  };

  const validateInstagramMessengerChannelConnection = async (
    channelType: string,
    data: instagramConnectionRequestProps
  ) => {
    let res = await instagramMessengerChannelConnectionValidation(
      channelType,
      projects[0].id,
      data
    );
    return res;
  };

  const handleSkip = async () => {
    mixpanelTrack('Clicked onboarding - skip for now');
    auth['has_completed_onboarding'] = true;
    auth.username = auth.email;
    auth.avatar = '';
    const res = await updateAccountInfo(auth);
    if (res.success) {
      navigate('/dashboard');
    }
  };

  return (
    <>
      <div
        key={`key-0`}
        className={`grid ${
          showMore ? 'grid-cols-2 gap-x-4' : 'grid-rows-2'
        } mx-2 mt-8 sm:mx-auto sm:w-full sm:max-w-lg`}
        style={
          showMore
            ? { paddingLeft: '1px', paddingRight: '1px' }
            : { paddingLeft: '4rem', paddingRight: '4rem' }
        }
      >
        {!showMore &&
          OnboardingChannelList.map((channel: any, index: number) =>
            index <= 3 ? (
              <Button
                key={`k-${index}`}
                isFullWidth={false}
                size='lg'
                onClick={() => setSelectedChannel(channel.platform_type)}
                icon={
                  <img
                    src={channel.icon}
                    alt={channel.platform_type}
                    loading='lazy'
                  />
                }
                className='mb-3 text-[14px] text-gray-700 font-medium'
              >
                {t(channel.name)}
              </Button>
            ) : null
          )}
        {showMore &&
          OnboardingChannelList.map((channel: any, index: number) => (
            <Button
              key={`k-0${index}`}
              isFullWidth={false}
              size='lg'
              onClick={() => setSelectedChannel(channel.platform_type)}
              icon={
                <img
                  src={channel.icon}
                  alt={channel.platform_type}
                  loading='lazy'
                />
              }
              className='mb-3 text-[16px] text-gray-700 font-medium'
            >
              {channel.name}
            </Button>
          ))}

        <Button
          isFullWidth={false}
          size='lg'
          onClick={() => setShowMore(!showMore)}
          className='mb-3 text-[16px] text-gray-700 font-medium'
        >
          {showMore ? t('Show Less') : t('Show More')}
        </Button>

        <LivechatModal
          teamName={auth.full_name}
          open={selectedChannel === 'livechat_messenger'}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
        <WhatsappModal
          teamName={auth.full_name}
          open={selectedChannel === 'whatsapp_bsp'}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
        <FacebookModal
          teamName={auth.full_name}
          open={selectedChannel === 'facebook_messenger'}
          fetchFacebookPages={fetchFacebookPages}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
        <FacebookFeedModal
          teamName={auth.full_name}
          open={selectedChannel === 'facebook_feed'}
          fetchFacebookPages={fetchFacebookPages}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
        <InstagramFeedModal
          teamName={auth.full_name}
          open={selectedChannel === 'instagram_feed'}
          fetchInstagramPages={fetchInstagramPages}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
        <InstagramModal
          teamName={auth.full_name}
          open={selectedChannel === 'instagram_messenger'}
          fetchInstagramPages={fetchInstagramPages}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
          handleInstaConnectionValidation={
            validateInstagramMessengerChannelConnection
          }
        />
        <ViberModal
          teamName={auth.full_name}
          open={selectedChannel === 'viber_messenger'}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
        <LineModal
          teamName={auth.full_name}
          open={selectedChannel === 'line_messenger'}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
        <TelegramModal
          teamName={auth.full_name}
          open={selectedChannel === 'telegram_messenger'}
          handleChannelCreate={handleChannelCreate}
          handleClose={() => setSelectedChannel('')}
        />
      </div>

      <div
        key={`key-1`}
        className={`grid-cols-2 gap-x-4 ${
          !showMore ? 'ltr:ml-4 rtl:mr-4' : ''
        }`}
      >
        <Button
          key={`key-10`}
          isFullWidth={true}
          isCapsuleButton={true}
          size='lg'
          onClick={() => handleSelectStore('step1')}
          icon={<ArrowLeftIcon className='rtl:-rotate-180' />}
          className='mb-3 mt-8 text-[13px] text-[#0078CF] font-medium ltr:float-left rtl:float-right'
        >
          {t('Select Store')}
        </Button>

        <Button
          key={100}
          isFullWidth={true}
          isCapsuleButton={true}
          size='lg'
          iconInRight={true}
          onClick={() => handleSkip()}
          icon={<ArrowRightIcon className='rtl:-rotate-180' />}
          className='mb-3 mt-8 text-[13px] text-[#0078CF] rtl:gap-2 font-medium ltr:float-right rtl:float-left'
        >
          {t('Skip For Now')}
        </Button>
      </div>
    </>
  );
};
const mapState = (state: any) => ({
  auth: state.auth,
  projects: state.dashboard.projects,
});

const mapDispatch = (dispatch: any) => ({
  updateAccountInfo: (state: CreatorInterface) =>
    dispatch.auth.updateAccountInfo(state),
  userAccountInfo: () => dispatch.auth.userAccountInfo(),
  fetchFacebookPages: (id: string, token: string, type: string) =>
    dispatch.onboarding.fetchFacebookPages({ id, token, type }),
  fetchInstagramPages: (id: string, token: string, type: string) =>
    dispatch.onboarding.fetchInstagramPages({ id, token, type }),
  instagramMessengerChannelConnectionValidation: (
    channelType: string,
    teamId: number,
    data: instagramConnectionRequestProps
  ) =>
    dispatch.onboarding.instagramMessengerChannelConnectionValidation({
      channelType,
      teamId,
      data,
    }),
  createChannelIntegrations: (
    channelType: string,
    teamId: number,
    data: object
  ) =>
    dispatch.onboarding.createChannelIntegrations({
      channelType,
      teamId,
      data,
    }),
});

export default connect(mapState, mapDispatch)(ChannelPicker);
