import axios from '../../../utilities/httpClient';
import config from '../../../utilities/config';
import { toaster } from 'evergreen-ui';
import { mixpanelTrack } from 'utilities/mixpanel';

const formatError = (errorObject) => {
  if (typeof errorObject === 'object' && errorObject !== null) {
    const errorMessages = Object.values(errorObject).map((message) => {
      return typeof message === 'string' ? message : '';
    });
    return errorMessages.filter(Boolean).join(' | ');
  } else if (typeof errorObject === 'string') {
    return errorObject;
  } else {
    return 'Please check all the data and try again';
  }
};

const initialState = {
  cart: {},
  products: [],
  openModal: false,
  customerHasCart: false,
  countryList: null,
  showDiscardAlert: false,
  openShopifyModal: false,
  calculateDraftOrder: null,
  openWooCommerceModal: false,
  showProductView: false,
  paymentMethodList: [],
  shippingMethodList: [],
  woocommerceDiscountCoupons: [],
  sallaDiscountCoupons: [],
  zidDiscountCoupons: [],
  orderFormInformation: {},
  ecommerceCustomerList: [],
  customerOrderList: [],
  openSallaModal: false,
  openZidModal: false,
  ecommerceCustomerDetails: null,
};

export const ecommerce = {
  state: { ...initialState },
  reducers: {
    updateEcommerceStateData(state, updatedData) {
      return { ...state, [updatedData.key]: updatedData.value };
    },
    updateCountryList(state, updatedData) {
      return { ...state, countryList: updatedData };
    },
    updateEcommerceCustomerDetails(state, updatedData) {
      return { ...state, ecommerceCustomerDetails: updatedData };
    },
    updateOpenShopifyModal(state, updatedData) {
      return { ...state, openShopifyModal: updatedData };
    },
    updateOrderFormInformation(state, updatedData) {
      return {
        ...state,
        orderFormInformation: {
          ...state.orderFormInformation,
          [updatedData.key]: updatedData.data,
        },
      };
    },
    updateCustomerOrderList(state, updatedData) {
      return { ...state, customerOrderList: updatedData };
    },
    clearState(state) {
      return { ...initialState };
    },
  },
  effects: (dispatch) => ({
    async fetchProducts({ teamId, params }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.products('edge', teamId),
          { params: params }
        );
        if (res?.data?.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'products',
            value: [...res.data.dataSource],
          });

          return { success: true, data: res.data.dataSource };
        } else {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'products',
            value: [],
          });
          toaster.notify('No Products Found');
          return { success: false, data: [] };
        }
      } catch (e) {
        console.error(e);
        return { success: false, data: [] };
      }
    },

    async fetchEcommerceCustomerDetails({ customerId }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.ecommerceCustomerDetails('edge', customerId)
        );

        if (res?.data?.success) {
          dispatch.ecommerce.updateEcommerceCustomerDetails(
            res?.data?.dataSource
          );
          return true;
        }
      } catch (e) {
        console.error(e);
        return false;
      }
    },

    async recommendProducts(payload) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.recommendProducts('edge', payload.customerId),
          {
            products: payload.selectedProductData,
          }
        );
        return res.status === 200 && res.data.success;
      } catch (err) {
        console.log(err);
        return false;
      }
    },

    async fetchCustomerInfo(customerId) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.cart('edge', customerId)
        );
        if (res.status === 200 && res?.data?.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'cart',
            value: res.data.dataSource,
          });
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'customerHasCart',
            value:
              res.data.dataSource.alice_cart &&
              res.data.dataSource.alice_cart.length > 0,
          });
        }
      } catch (err) {
        if (err?.response?.data?.error) {
          const error = err?.response?.data?.error;
          if (typeof error === 'string')
            toaster.danger(err?.response?.data?.error);
          else if (!!error?.message) {
            toaster.danger(error?.message);
          } else {
            toaster.danger("Can't get customer data at this moment");
          }
        }
        console.error(err);
      }
    },

    async fetchVariation({ teamId, productId }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.variant('edge', teamId, productId)
        );
        if (res.status === 200 && res.data.success) {
          return { success: true, data: [...res.data.dataSource] };
        } else {
          return { success: false, data: [] };
        }
      } catch (err) {
        console.error(err);
        return { success: false, data: [] };
      }
    },

    async updateCart(payload) {
      // this loads on initial create order call, update cart and discard cart :)
      try {
        const res = await axios.post(
          config.inboxEcommerce.updateCart('edge', payload.customerId),
          {
            cart_products: payload.cartProducts,
          }
        );
        if (res.status === 200 && res.data.success) {
          //after successfully updated
        }
      } catch (err) {
        console.log(err);
      }
    },
    async cancelOrder({ projectId, orderId }) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.cancelOrder('edge', projectId, orderId),
          {}
        );
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'cart',
            value: res.data.dataSource,
          });

          toaster.success('Order Cancellation', {
            description: 'Order Cancelled Successfully',
          });
        } else {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'cart',
            value: {},
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
    async fetchCartLink(customerId) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.cartLink('edge', customerId)
        );

        if (res.status === 200 && res.data.success) {
          return res.data.dataSource;
        } else {
          return '';
        }
      } catch (err) {
        console.log(err);
        return '';
      }
    },
    async fetchCountryList(payload) {
      try {
        const res = await axios.get(config.misc.countryList());
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateCountryList(res.data.dataSource);
        } else {
          dispatch.ecommerce.updateCountryList([]);
        }
      } catch (err) {
        console.log(err);
        dispatch.settings.updateCountryList([]);
      }
    },
    async fetchEcommerceCountryList(projectId) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.ecommerceCountryList(projectId)
        );
        if (res.status === 200 && res.data.success) {
          return res.data.dataSource;
        } else {
          return [];
        }
      } catch (err) {
        console.log(err);
        return [];
      }
    },
    async fetchEcommerceCityList(payload) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.ecommerceCityList(
            payload?.projectId,
            payload?.countryId
          )
        );
        if (res.status === 200 && res.data.success) {
          return res.data.dataSource;
        } else {
          return [];
        }
      } catch (err) {
        console.log(err);
        return [];
      }
    },

    async calculateDraftOrder({ projectId, data }) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.calculateDraftOrder('edge', projectId),
          data
        );
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'calculateDrftOrder',
            value: res.data.dataSource,
          });
          return {
            success: true,
            data: res.data.dataSource,
          };
        }
      } catch (err) {
        return {
          success: false,
          data: err?.response?.data,
        };
      }
    },
    async createOrder({ teamId, data }) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.createOrder('edge', teamId),
          data
        );
        if (res.status === 200 && res.data.success) {
          return res.data;
        }
      } catch (err) {
        return { success: false, dataSource: null };
      }
    },

    async fetchPaymentMethods({ projectId }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.paymentMethod('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'paymentMethodList',
            value: [...res.data.dataSource],
          });
          return res.data.dataSource;
        } else {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'paymentMethodList',
            value: [],
          });
        }
      } catch (err) {
        console.log(err);
        dispatch.ecommerce.updateEcommerceStateData({
          key: 'paymentMethodList',
          value: [],
        });
      }
      return [];
    },

    async fetchShippingMethods({ projectId }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.shippingMethod('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'shippingMethodList',
            value: [...res.data.dataSource],
          });
          return res.data.dataSource;
        } else {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'shippingMethodList',
            value: [],
          });
        }
      } catch (err) {
        console.log(err);
        dispatch.ecommerce.updateEcommerceStateData({
          key: 'shippingMethodList',
          value: [],
        });
      }
      return [];
    },

    async fetchWoocommerceCoupons({ projectId }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.getWocommerceCoupons('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'woocommerceDiscountCoupons',
            value: [...res.data.dataSource],
          });
        } else {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'woocommerceDiscountCoupons',
            value: [],
          });
        }
      } catch (err) {
        console.log(err);
        dispatch.ecommerce.updateEcommerceStateData({
          key: 'woocommerceDiscountCoupons',
          value: [],
        });
      }
    },
    async fetchSallaCoupons({ projectId }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.getWocommerceCoupons('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'sallaDiscountCoupons',
            value: [...res.data.dataSource],
          });
        } else {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'sallaDiscountCoupons',
            value: [],
          });
        }
      } catch (err) {
        console.log(err);
        dispatch.ecommerce.updateEcommerceStateData({
          key: 'sallaDiscountCoupons',
          value: [],
        });
      }
    },

    async fetchZidCoupons({ projectId }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.getWocommerceCoupons('edge', projectId)
        );
        if (res.status === 200 && res.data.success) {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'zidDiscountCoupons',
            value: [...res.data.dataSource],
          });
        } else {
          dispatch.ecommerce.updateEcommerceStateData({
            key: 'zidDiscountCoupons',
            value: [],
          });
        }
      } catch (err) {
        console.log(err);
        dispatch.ecommerce.updateEcommerceStateData({
          key: 'zidDiscountCoupons',
          value: [],
        });
      }
    },

    async createWooCommerceOrder({
      teamId,
      customerId,
      ticketId,
      billing_address,
      shipping_address,
      paymentMethod,
      shippingMethod,
      couponCode,
    }) {
      try {
        const payload = {
          customer_id: customerId,
          ticket_id: ticketId,
          billing_address: billing_address || '',
          shipping_address: shipping_address || '',
          payment_line: paymentMethod,
          shipping_line: shippingMethod,
          coupon_code: couponCode,
        };
        const res = await axios.post(
          config.inboxEcommerce.createOrder('edge', teamId),
          payload
        );
        if (res.status === 200 && res.data.success) {
          mixpanelTrack('Created Order', {
            customerId: customerId,
          });
          return { success: true, dataSource: res.data.dataSource };
        } else {
          return { success: false, dataSource: null };
        }
      } catch (err) {
        return {
          success: false,
          error: err?.response?.data?.error,
          dataSource: null,
        };
      }
    },

    async createSallaOrder({
      teamId,
      customerId,
      ticketId,
      billing_address,
      shipping_address,
      payment,
      courier_id,
      shippingMethod,
      couponCode,
    }) {
      const address = `${shipping_address.address_one} ${
        shipping_address.address_two
      } ${shipping_address.city.name ?? ''}, ${
        shipping_address.country.name ?? ''
      }`;
      shipping_address['address'] = address;
      shipping_address['address_line'] = address;
      try {
        const payload = {
          customer_id: customerId,
          ticket_id: ticketId,
          billing_address: billing_address || '',
          shipping_address: shipping_address || '',
          payment,
          courier_id,
          shipping_line: shippingMethod,
          coupon_code: couponCode,
        };

        const res = await axios.post(
          config.inboxEcommerce.createOrder('edge', teamId),
          payload
        );
        if (res.status === 200 && res.data.success) {
          mixpanelTrack('Created Order', {
            customerId: customerId,
          });
          return { success: true, dataSource: res.data.dataSource };
        } else {
          return { success: false, dataSource: null };
        }
      } catch (err) {
        return {
          success: false,
          error: formatError(err?.response?.data?.error),
          dataSource: null,
        };
      }
    },

    async createZidOrder({ customerId, teamId, payload }) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.createOrder('edge', teamId),
          { ...payload }
        );
        if (res.status === 200 && res.data.success) {
          mixpanelTrack('Created Order', {
            customerId: customerId,
          });
          return { success: true, dataSource: res.data.dataSource };
        } else {
          return { success: false, dataSource: null };
        }
      } catch (err) {
        return {
          success: false,
          error: formatError(err?.response?.data?.error),
          dataSource: null,
        };
      }
    },

    async fetchEcommerceCustomerList({ projectId, searchQuery }) {
      try {
        const res = await axios.get(
          config.inboxEcommerce.ecommerceCustomerList(
            'edge',
            projectId,
            searchQuery
          )
        );

        return res?.data;
      } catch (err) {
        console.log(err);
        dispatch.ecommerce.updateEcommerceStateData({
          key: 'ecommerceCustomerList',
          value: [],
        });
        return { success: false };
      }
    },
    async connectEcommerceCustomer(payload) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.connectEcommerceCustomer(
            'edge',
            payload.teamId
          ),
          {
            alice_customer_id: payload.customerId,
            ecommerce_account_id: payload.ecommerceId,
          }
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Successful', {
            description: 'The customer has been connected',
            duration: 2,
          });
          return { success: res.data.success, data: res.data.dataSource };
        }
      } catch (err) {
        console.log(err);
        if (!!err && err?.response?.status !== 200) {
          toaster.danger('Failed', {
            description:
              err.response?.data?.error ||
              'Something went wrong. Please try again.',
          });
        }
        return { success: false, data: null };
      }
    },
    async disconnectEcommerceCustomer(payload) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.disconnectEcommerceCustomer(
            'edge',
            payload.teamId
          ),
          {
            alice_customer_id: payload.customerId,
          }
        );
        toaster.success('Successful', {
          description: 'The customer has been disconnected',
          duration: 2,
        });
        return res.data.success;
      } catch (err) {
        console.log(err);
        if (!!err && err?.response?.status !== 200) {
          toaster.danger('Failed', {
            description:
              err.response?.data?.error ||
              'Something went wrong. Please try again.',
          });
        }
        return false;
      }
    },
    async fetchCustomerOrder({ customerId, orderId }) {
      try {
        const paramsLocal = !!orderId ? { order_id: orderId } : {};
        const res = await axios.get(
          config.inboxEcommerce.orderList('edge', customerId),
          {
            params: paramsLocal,
          }
        );
        if (res.data.success) {
          dispatch.ecommerce.updateCustomerOrderList(res.data.dataSource);
        } else {
          dispatch.ecommerce.updateCustomerOrderList([]);
        }
      } catch (err) {
        console.log(err); // TODO: to be refactored
      }
    },
    async refundOrder({ projectId, orderId }) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.refundOrder('edge', projectId, orderId)
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Order Refund', {
            description: 'Order Refunded Successfully',
          });
          return true;
        } else {
          toaster.danger('Order Refund', {
            description: 'Unable to refund order',
          });
          return false;
        }
      } catch (err) {
        toaster.danger('Order Refund', {
          description: err.response.data.error,
        });
        return false;
      }
    },
    async cancelOrderFromList({ projectId, orderId }) {
      try {
        const res = await axios.post(
          config.inboxEcommerce.cancelOrder('edge', projectId, orderId),
          {}
        );
        if (res.status === 200 && res.data.success) {
          toaster.success('Order Cancellation', {
            description: 'Order Cancelled Successfully',
          });
        } else {
          toaster.danger('Order Cancellation', {
            description: 'Unable to cancel order',
          });
          return false;
        }
      } catch (err) {
        toaster.danger('Order Cancellation', {
          description: err.response.data.error,
        });
        return false;
      }
    },
  }),
};
