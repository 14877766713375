'use client';
import { ReactElement, useRef, useState } from 'react';
import {
  Button,
  DownloadIcon,
  UploadIcon,
  PlusIcon,
  ReactTable as Table,
  useSelector,
  useDatalab,
  Trash,
  Separator,
  DeleteAlert,
} from '../../../export';

import DatalabSideSheet from '../DatalabSideSheet';
import ConfirmationModal from './../../../components/common/ConfirmationModal';

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>;
  selectedRowCount: number;
  hasDeleteAccess: boolean;
  onDelete: () => void;
  onCancel: () => void;
}

interface ExportStateProps {
  show: boolean;
  success: boolean;
  message: {
    title: string;
    description: string | ReactElement;
  };
}

export function DataTableViewOptions<TData>({
  table,
  selectedRowCount = 0,
  hasDeleteAccess = false,
  onDelete = () => {},
  onCancel = () => {},
}: DataTableViewOptionsProps<TData>) {
  const {
    fetchExportDatalabData,
    importDatalabFile,
    fetchDataLabInfo,
    hasWriteAccess,
  } = useDatalab();

  const { selectedDatalabInfo, dateRange, labId } = useDatalab();
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [tableDatalabExportResponse, setTableDatalabExportResponse] =
    useState<ExportStateProps>({
      show: false,
      success: false,
      message: {
        title: '',
        description: '',
      },
    });

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { email } = useSelector((state: any) => state?.auth);

  const handleExportClick = async () => {
    const res = await fetchExportDatalabData(labId, dateRange);
    if (res) {
      setTableDatalabExportResponse({
        show: true,
        success: true,
        message: {
          title: 'Download In Process',
          description: (
            <span className='text-sm font-normal text-gray-500'>
              Your export is currently processing. When it is ready, you will
              receive an email at{' '}
              <span className='text-sm font-bold text-gray-700'>{email}</span>
            </span>
          ),
        },
      });
    } else {
      setTableDatalabExportResponse({
        show: true,
        success: false,
        message: {
          title: 'Failed',
          description: 'Failed to Export Data',
        },
      });
    }
  };

  const handleImportClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const success = await importDatalabFile(labId, file);
      if (success) {
        // fetchDatalabData(projectId, labId, dateRange);
        fetchDataLabInfo();
      }
    }
  };

  const handleNewDataClick = () => {
    setIsSheetOpen(true);
  };

  const handleSheetClose = () => {
    setIsSheetOpen(false);
  };

  const toggleAlert = () => {
    setIsAlertOpen(!isAlertOpen);
  };

  return (
    <div className='flex gap-2'>
      <div>
        <input
          type='file'
          accept='.csv'
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
        />
        <Button
          size='sm'
          variant='outline'
          disabled={!hasWriteAccess}
          onClick={handleImportClick}
          className={
            hasWriteAccess ? 'flex gap-2' : 'cursor-not-allowed flex gap-2'
          }
        >
          <UploadIcon className='w-4 h-4' />
          <span>Import</span>
        </Button>
      </div>
      <Button
        size='sm'
        variant='outline'
        className='flex gap-2'
        onClick={handleExportClick}
      >
        <DownloadIcon className='w-4 h-4' />
        <span>Export</span>
      </Button>

      {selectedRowCount > 0 ? (
        <>
          <Separator
            className='border border-solid border-[#E4E4E7] mt-1.5'
            orientation='vertical'
            style={{ height: '22.51px' }}
          />

          <Button
            size='sm'
            variant='outline'
            className='flex gap-2 text-white bg-[#FF0000] hover:bg-red-600'
            disabled={!hasDeleteAccess}
            onClick={toggleAlert}
          >
            <Trash className='w-4 h-4' />
            <span>Delete</span>
          </Button>

          <Button
            size='sm'
            variant='outline'
            className='flex gap-2'
            onClick={onCancel}
          >
            <span>Cancel</span>
          </Button>
        </>
      ) : (
        <div>
          <Button
            size='sm'
            onClick={handleNewDataClick}
            disabled={!hasWriteAccess}
            className={
              hasWriteAccess ? 'flex gap-2' : 'cursor-not-allowed flex gap-2'
            }
          >
            <PlusIcon className='w-4 h-4 text-white' stroke='2' />
            <span className='text-white'>Enter new Data</span>
          </Button>
          {isSheetOpen && (
            <DatalabSideSheet
              isOpen={isSheetOpen}
              datalabEntries={{}}
              datalabShape={selectedDatalabInfo}
              header='Enter new Data'
              mode='CREATE'
              onClose={handleSheetClose}
            />
            // <SiteSheet header='Enter new Data' onClose={handleSheetClose} />
          )}
        </div>
      )}

      <ConfirmationModal
        shouldShowCancelButton={false}
        isShown={tableDatalabExportResponse?.show}
        title={tableDatalabExportResponse?.message?.title}
        description={tableDatalabExportResponse?.message?.description}
        intent={tableDatalabExportResponse?.success ? 'primary' : 'danger'}
        shouldRenderDescriptionAsNode={tableDatalabExportResponse?.success}
        confirmButtonTitle='Got it!'
        onConfirm={() => {
          setTableDatalabExportResponse({
            ...tableDatalabExportResponse,
            show: false,
          });
        }}
      />

      <DeleteAlert
        open={isAlertOpen}
        onOpenChange={toggleAlert}
        title={`Delete ${selectedRowCount} Entries`}
        description={`This will delete ${selectedRowCount} entries that you have selected. Are you sure you want to delete?`}
        onCancel={toggleAlert}
        onConfirm={onDelete}
      />
    </div>
  );
}
