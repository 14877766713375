import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from 'libraryV2/ui/alert-dialog';
import { Button } from 'libraryV2/ui/button';
import { TrashIcon } from 'lucide-react';

interface IProps {
  type: string;
  name: string;
  onDelete: () => void;
}

const DeleteCofirmationDialog: React.FC<IProps> = ({
  type,
  onDelete,
  name,
}) => {
  const handleOnDelete = () => {
    onDelete();
  };
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>
        <Button
          variant='outline'
          size='icon'
          className='mr-3'
          style={{ background: '#FFF0F0', color: '#FF0000' }}
        >
          <TrashIcon className='h-4 w-4' />
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent className='bg-white'>
        <AlertDialogHeader>
          <AlertDialogTitle>Delete {name}?</AlertDialogTitle>
          <AlertDialogDescription>
            This will permanently delete <strong>{name}</strong> from your form.
            Are you sure you want to delete?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel className='bg-[#F4F4F5] hover:bg-[#E4E4E7]'>
            No
          </AlertDialogCancel>
          <AlertDialogAction
            className='ml-3 text-white bg-[#FF0000] hover:bg-red-700'
            onClick={handleOnDelete}
          >
            Yes! Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default DeleteCofirmationDialog;
