import React from 'react';
import BlankModal from 'library/modal/BlankModal';
import {
  WhatsappBspIcon,
  WhatsappGrayIcon,
  WhatsappGreenTickIcon,
} from 'pages/integration/utils/content';
import { IWhatsappAccountInfo } from 'pages/integration/interface';
import CopyText from 'library/copyText';

type Props = {
  setOpenAccountInfoModal: (value: boolean) => void;
  whatsappAccountInfoData: IWhatsappAccountInfo | null;
  isOpenWhatsappAccountInfoModal: boolean;
};

const WhatsappAccountInfo: React.FC<Props> = ({
  setOpenAccountInfoModal,
  whatsappAccountInfoData,
  isOpenWhatsappAccountInfoModal,
}) => {
  const renderAccountStatusView = (type: string) => {
    switch (type) {
      case 'APPROVED':
        return (
          <div className='bg-green-100 text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            Approved
          </div>
        );
      case 'REJECTED':
        return (
          <div className='bg-red-100 text-red-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            Rejected
          </div>
        );
      case 'PENDING':
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            Pending
          </div>
        );

      default:
        return null;
    }
  };

  const renderFbBusinessVerificationView = (type: string) => {
    switch (type) {
      case 'verified':
        return (
          <div className='bg-green-100 text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            Verified
          </div>
        );
      case 'not_verified':
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            Not Verified
          </div>
        );

      default:
        return null;
    }
  };

  const renderQualityRatingView = (type: string) => {
    switch (type) {
      case 'GREEN':
        return (
          <div className='bg-green-100 text-green-800 py-0.5 px-1.5 rounded text-sm font-medium inline-block'>
            High
          </div>
        );
      case 'YELLOW':
        return (
          <div className='bg-gray-100 text-gray-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            Medium
          </div>
        );
      case 'RED':
        return (
          <div className='bg-red-100 text-red-800 py-0.5 px-1.5 rounded text-sm font-medium  inline-block'>
            Low
          </div>
        );

      default:
        return null;
    }
  };
  return (
    <>
      <BlankModal
        open={isOpenWhatsappAccountInfoModal}
        hideFooter={true}
        handleClose={() => {
          setOpenAccountInfoModal(false);
        }}
        title='Account Info'
        headerIcon={<img src={WhatsappBspIcon} alt='' />}
      >
        <div className='grid grid-cols-2 gap-6 pb-4'>
          <div className='border border-gray-200 bg-gray-50 rounded-md p-4'>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-700 text-base font-semibold mr-1.5'>
                  {whatsappAccountInfoData?.business_name}
                </h3>
                {whatsappAccountInfoData?.green_tick && (
                  <div>
                    <WhatsappGreenTickIcon />
                  </div>
                )}
              </div>
              <div className='flex items-center mt-1'>
                <div className='mr-1'>
                  <WhatsappGrayIcon />
                </div>
                <div className='text-gray-500 text-xs'>
                  Connected with{' '}
                  <span className='text-gray-800'>
                    {whatsappAccountInfoData?.display_phone_number}
                  </span>
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                Business name
              </h3>
              <div className='mt-1'>
                <div className='text-gray-800 text-sm'>
                  {whatsappAccountInfoData?.business_name}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-400 text-xs font-semibold uppercase mr-1'>
                  WABA ID
                </h3>
                <div className='w-6 h-6 mt-1'>
                  <CopyText
                    height={20}
                    textToCopy={whatsappAccountInfoData?.waba_id ?? ''}
                  />
                </div>
              </div>
              <div className='mt-1'>
                <div className='text-gray-800 text-sm'>
                  {whatsappAccountInfoData?.waba_id}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                account status
              </h3>
              <div className='mt-1'>
                {renderAccountStatusView(
                  whatsappAccountInfoData?.account_status ?? ''
                )}
              </div>
            </div>
            <div className=''>
              <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                Message limit
              </h3>
              <div className='mt-1'>
                <div className='text-gray-800 text-sm'>
                  {whatsappAccountInfoData?.message_limit}
                </div>
              </div>
            </div>
          </div>
          <div className='border border-gray-200 bg-gray-50 rounded-md p-4'>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-700 text-base font-semibold mr-1.5'>
                  Facebook Business
                </h3>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                Facebook Business name
              </h3>
              <div className='mt-1'>
                <div className='text-gray-800 text-sm'>
                  {whatsappAccountInfoData?.fb_business_name}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <div className='flex items-center'>
                <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                  FB business verification
                </h3>
              </div>
              <div className='mt-1'>
                <div>
                  {renderFbBusinessVerificationView(
                    whatsappAccountInfoData?.business_verification_status ?? ''
                  )}
                </div>
              </div>
            </div>
            <div className='mb-4'>
              <h3 className='text-gray-400 text-xs font-semibold uppercase'>
                Quality ratings
              </h3>
              <div className='mt-1'>
                {renderQualityRatingView(
                  whatsappAccountInfoData?.quality_rating ?? ''
                )}
              </div>
            </div>
          </div>
        </div>
      </BlankModal>
    </>
  );
};

export default React.memo(WhatsappAccountInfo);
