import { useDrag } from 'react-dnd';
import {
  DragTypes,
  platinum_color,
  useTriggerActions,
  capitalizeFirstLetter,
  useActions,
  React,
} from 'pages/datalab/export';
import { IFormField } from 'pages/datalab/interface';

interface IProps {
  field: IFormField;
}

const SingleItem: React.FC<IProps> = (props) => {
  const { field } = props;
  const { getController } = useActions();
  const { createDataLabField, addTempFieldToLabFields } = useTriggerActions();
  const { labId } = getController();

  const handleFieldClick = () => {
    // field click functionality here
    // add field to the canvas
    if (field.type === 'group') {
      createDataLabField(labId, field);
    } else {
      addTempFieldToLabFields(field);
    }
  };

  const [{ isDragging }, drag] = useDrag({
    type: DragTypes.FIELD,
    item: { field, type: DragTypes.FIELD },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      className={`h-[100px] w-[110px] bg-white grid justify-center items-center border rounded-md border-[${platinum_color}] hover:bg-gray-50`}
      ref={drag}
      style={{ cursor: isDragging ? 'grabbing' : 'pointer' }}
      onClick={handleFieldClick}
    >
      <div
        className='grid items-center justify-center'
        style={{ visibility: isDragging ? 'hidden' : 'visible' }}
      >
        <span className='m-auto'>{field.icon}</span>
        <span className='mt-3'>{capitalizeFirstLetter(field.type)}</span>
      </div>
    </div>
  );
};

export default SingleItem;
