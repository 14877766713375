import {
  Button,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
  ReactTable as Table,
} from '../../../export';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  limit: number;
  offset: number;
  totalRowCount: number;
  onLimitChange: (newLimit: number) => void;
  onPageChange: (newOffset: number) => void;
}

export function DataTablePagination<TData>({
  table,
  limit,
  offset,
  totalRowCount,
  onLimitChange,
  onPageChange,
}: DataTablePaginationProps<TData>) {
  const handleLimitChange = (newLimit: string) => {
    onLimitChange(Number(newLimit));
  };

  const handlePageChange = (newOffset: number) => {
    onPageChange(newOffset);
  };

  const currentPage = Math.ceil(offset / limit) + Number(!!totalRowCount);
  const totalPage = Math.ceil(totalRowCount / limit);

  const renderPageLimitSelectionMenu = () => {
    return (
      <div className='flex items-center space-x-2'>
        <p className='text-sm font-medium'>Rows per page</p>
        <Select value={`${limit}`} onValueChange={handleLimitChange}>
          <SelectTrigger className='h-8 w-[70px]'>
            <SelectValue placeholder={`${limit}`} />
          </SelectTrigger>
          <SelectContent side='top'>
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <SelectItem
                key={pageSize}
                value={`${pageSize}`}
                className='bg-white'
              >
                {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    );
  };

  return (
    <div className='flex items-center justify-between px-2'>
      <div className='flex-1 text-sm text-muted-foreground'>
        {table.getFilteredSelectedRowModel().rows.length} of {totalRowCount}
        row(s) selected.
      </div>
      <div className='flex items-center space-x-6 lg:space-x-8'>
        {renderPageLimitSelectionMenu()}
        <div className='flex items-center justify-center text-sm font-medium'>
          {`Page ${currentPage} of ${totalPage}`}
        </div>
        <div className='flex items-center space-x-2'>
          <Button
            variant='outline'
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() => handlePageChange(0)}
            disabled={offset === 0}
          >
            <span className='sr-only'>Go to first page</span>
            <DoubleArrowLeftIcon className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => handlePageChange(offset - limit)}
            disabled={offset === 0}
          >
            <span className='sr-only'>Go to previous page</span>
            <ChevronLeftIcon className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='h-8 w-8 p-0'
            onClick={() => handlePageChange(offset + limit)}
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>Go to next page</span>
            <ChevronRightIcon className='h-4 w-4' />
          </Button>
          <Button
            variant='outline'
            className='hidden h-8 w-8 p-0 lg:flex'
            onClick={() =>
              handlePageChange((Math.ceil(totalRowCount / limit) - 1) * limit)
            }
            disabled={offset + limit >= totalRowCount}
          >
            <span className='sr-only'>Go to last page</span>
            <DoubleArrowRightIcon className='h-4 w-4' />
          </Button>
        </div>
      </div>
    </div>
  );
}
