import React from 'react';
import facebookLogo from '../../../assets/icons/facebookLogo.svg';

interface Props {
  pageList: facebookPageListProps[] | null;
  selectedPage: facebookPageListProps;
  setSelectedPage: (value: any) => void;
}

const Step2: React.FC<Props> = ({
  pageList,
  selectedPage,
  setSelectedPage,
}) => {
  return (
    <>
      <div className='pb-32 overflow-y-auto'>
        <div className='border rounded'>
          {!!pageList &&
            pageList.map((page: facebookPageListProps, index: number) => (
              <div
                key={index}
                className={`flex justify-between py-3 border-t ${
                  selectedPage.name === page.name
                    ? 'bg-green-200'
                    : page.is_connected
                    ? 'bg-gray-100'
                    : ''
                }`}
              >
                <span className='flex'>
                  <input
                    name='notification-method'
                    type='radio'
                    onChange={() => setSelectedPage(page)}
                    disabled={page.is_connected}
                    checked={
                      selectedPage?.name.length &&
                      selectedPage.name === page.name
                        ? true
                        : false
                    }
                    className='w-4 h-4 mx-4 mt-2 border-gray-300 text-primary focus:ring-primary'
                  />
                  <img
                    className='inline-block w-8 h-8 rounded-full'
                    src={page.avatar.length ? page.avatar : facebookLogo}
                    alt=''
                    onClick={
                      !page.is_connected
                        ? () => setSelectedPage(page)
                        : undefined
                    }
                  />
                  <label
                    htmlFor={page.name}
                    className='block mt-1.5 ml-3 text-sm font-medium text-gray-700'
                    onClick={
                      !page.is_connected
                        ? () => setSelectedPage(page)
                        : undefined
                    }
                  >
                    {page.name}
                  </label>
                </span>
                {page.is_connected && (
                  <span className='inline-flex items-center px-3 mr-3 text-center text-green-800 bg-green-100 rounded cursor-pointer'>
                    Connected
                  </span>
                )}
              </div>
            ))}
        </div>
      </div>
    </>
  );
};
export default Step2;
