import React from 'react';
import InputTag from '../../../../../components/utilityComponent/InputTag';
import { XCircleIcon } from '@heroicons/react/20/solid';

interface Props {
  success?: boolean;
  errorMessage?: string;
  messengerSettingData: facebookMessengerSettingDataProps;
  handleChange: (key: string, value: string | string[] | boolean) => void;
}

const Step3: React.FC<Props> = ({
  success,
  errorMessage,
  messengerSettingData,
  handleChange,
}) => {
  return (
    <>
      {!success && (
        <div className='flex justify-center'>
          <div className='justify-center'>
            <XCircleIcon className='w-5 h-5 text-red-500' aria-hidden='true' />
          </div>
          <p className='ml-2 text-sm text-red-600'>{errorMessage}</p>
        </div>
      )}
      <div className='col-span-6 sm:col-span-6'>
        <label
          htmlFor='title'
          className='block text-sm font-medium text-gray-700'
        >
          Title*
        </label>
        <input
          type='text'
          placeholder='My website channel'
          value={messengerSettingData.title}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChange(e.target.name, e.target.value)
          }
          name='title'
          className='block w-full mt-1 border-gray-300 rounded-md shadow-sm focus:ring-primary focus:border-primary sm:text-sm'
        />
        <p className='mt-2 text-sm text-gray-500'>
          This title will be used in MyAlice to identify this channel. Give it a
          title that you can differentiate with later.
        </p>
      </div>
      <div className='mt-5'>
        <div className='flex justify-between'>
          <label
            htmlFor='url_whitelist'
            className='block text-sm font-medium text-gray-700'
          >
            Whitelist Domain
          </label>
          <span className='text-gray-500'>Optional</span>
        </div>
        <InputTag
          handleChange={handleChange}
          tagList={messengerSettingData.whitelisted_domains}
        />
      </div>
    </>
  );
};
export default Step3;
