import Navbar from '../navbar';
import LeftSideBar from '../leftSideBar';
import Editor from '../editor';
// import { useActions } from 'pages/datalab/export';

const Layout = () => {
  // const { clearState } = useActions();
  // clearState();
  return (
    <>
      <Navbar />
      <div className='grid grid-rows-3 grid-flow-col gap-4 ml-px bg-white h-[93vh]'>
        <LeftSideBar />
        <Editor />
      </div>
    </>
  );
};

export default Layout;
