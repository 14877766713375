import { useState, Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import CustomerListView from './ListView';
import { ISearchCustomer } from 'pages/inbox/inboxInterface';
import useLeftBarHook from 'pages/inbox/hooks/useLeftBarHook';
import ChannelListDropdown from './ChannelList';
import Button from 'library/button';
import { XMarkIcon } from '@heroicons/react/24/solid';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  isOpen: boolean;
  setModalOpen: (value: boolean) => void;
}

const TicketCreationModal: React.FC<Props> = ({ isOpen, setModalOpen }) => {
  const { t } = useTranslation();
  const {
    selectedProject,
    integratedChannelList,
    createTicketByPrimaryId,
    searchCustomerByPrimaryId,
  } = useLeftBarHook();
  const [searchLoading, setSearchLoading] = useState<boolean>(true);
  const [customers, setCustomers] = useState<ISearchCustomer[]>([]);
  const [selectedChannelId, setSelectedChannelId] = useState(null);
  const [ticketCreationLoading, setTicketCreationLoading] =
    useState<boolean>(false);
  const [selectedCustomer, setSelectedCustomer] =
    useState<ISearchCustomer | null>(null);

  const handleSearch = async (query: string) => {
    if (!!query) {
      setSearchLoading(true);
      const res = await searchCustomerByPrimaryId(query);
      if (!!res) setCustomers([...res]);
      setSearchLoading(false);
    } else setCustomers([]);
  };

  useEffect(() => {
    if (!isOpen) {
      setSelectedChannelId(null);
      setSelectedCustomer(null);
      setCustomers([]);
    }
  }, [isOpen]);

  const handleTicketCreation = async () => {
    if (!!selectedCustomer && !!selectedChannelId) {
      setTicketCreationLoading(true);
      const res = await createTicketByPrimaryId(
        selectedCustomer?.id,
        selectedCustomer?.customer_primary_id,
        selectedChannelId
      );
      setTicketCreationLoading(false);
      if (res) {
        setModalOpen(false);
      }
    }
  };

  const updateCustomerList = (list: ISearchCustomer[]) => {
    setCustomers(list);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10 '
        onClose={() => setModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          leaveTo='opacity-0'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leaveFrom='opacity-100'
          leave='ease-in duration-200'
          enter='ease-out duration-300'
        >
          <div className='fixed inset-0 bg-gray-300 bg-opacity-75 transition-opacity' />
        </Transition.Child>
        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              enter='transition duration-100 ease-out'
              enterFrom='transform scale-95 opacity-0'
              enterTo='transform scale-100 opacity-100'
              leave='transition duration-75 ease-out'
              leaveFrom='transform scale-100 opacity-100'
              leaveTo='transform scale-95 opacity-0'
              as={Fragment}
            >
              <Dialog.Panel
                className={'bg-white p-5 flex flex-col rounded-md w-[464px]'}
              >
                <div className='flex items-center w-full'>
                  <p className='text-gray-800 text-base ltr:text-left rtl:text-right font-semibold mb-2'>
                    {t('Send Message')}
                  </p>
                  <XMarkIcon
                    className='w-5 h-5 text-gray-700 font-bold ltr:ml-auto rtl:mr-auto stroke-2 cursor-pointer'
                    onClick={() => setModalOpen(false)}
                  />
                </div>
                <CustomerListView
                  customers={customers}
                  searchLoading={searchLoading}
                  searchCustomer={handleSearch}
                  selectedProject={selectedProject}
                  selectedCustomer={selectedCustomer}
                  updateCustomerList={updateCustomerList}
                  handleCustomerSelect={(customer: ISearchCustomer) =>
                    setSelectedCustomer(customer)
                  }
                />
                <div className='p-1 w-full' />
                {!!selectedCustomer && (
                  <>
                    <ChannelListDropdown
                      integratedChannelList={integratedChannelList}
                      selectedCustomerPrimaryId={
                        selectedCustomer?.customer_primary_id
                      }
                      handleSelectedchannelId={(channelId: any) => {
                        setSelectedChannelId(channelId);
                      }}
                    />
                    <div className='p-1 w-full' />
                  </>
                )}
                <div className='w-full flex items-center justify-end gap-2 mt-2'>
                  <Button
                    intent='default'
                    size='sm'
                    onClick={() => {
                      setModalOpen(false);
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button
                    size='sm'
                    className='ml-2'
                    intent='primary'
                    isLoading={ticketCreationLoading}
                    onClick={() => handleTicketCreation()}
                    isDisabled={!selectedChannelId || !selectedCustomer}
                  >
                    {ticketCreationLoading && (
                      <div className='flex justify-center items-center w-full '>
                        <span className='text-md text-gray-700 mr-1'>
                          {t('Creating')}...
                        </span>
                        <div
                          className='inline-block w-5 h-5 text-white border-2 rounded-full spinner-border animate-spin'
                          role='status'
                        />
                      </div>
                    )}
                    {!ticketCreationLoading && t('Create')}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default TicketCreationModal;
