import React, { useState } from 'react';
import { GripVerticalIcon } from 'lucide-react';
import { isEqual } from 'lodash';
import { RadioGroup, RadioGroupItem } from 'libraryV2/ui/radio-group';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'libraryV2/ui/accordion';
import { Label } from 'libraryV2/ui/label';
import { Checkbox } from 'libraryV2/ui/checkbox';

import {
  InputText,
  SaveCofirmationDialog,
  DiscardCofirmationDialog,
  DeleteCofirmationDialog,
  useActions,
  useTriggerActions,
  FormFields,
  platinum_color,
  capitalizeFirstLetter,
  IDataLabField,
} from 'pages/datalab/export';

interface IProps {
  field: IDataLabField;
  isDragging: boolean;
  isSmall?: boolean;
  nodeRef?: React.RefObject<HTMLDivElement>;
}

const DateTime: React.FC<IProps> = (props) => {
  const { field, isDragging, isSmall, nodeRef } = props;

  const { setSelectedFieldId, getSelectedFieldId, getController } =
    useActions();

  const {
    createDataLabField,
    updateDataLabField,
    deleteDataLabField,
    updateUnsaveTracker,
  } = useTriggerActions();

  const [fieldProperties, setFieldProperties] = useState(field);
  const selectedFieldId = getSelectedFieldId();
  const isNewField =
    typeof field.id === 'string' &&
    field.id.startsWith('random') &&
    !field.slug;

  const {
    id: fieldId,
    name = '',
    label_agent = '',
    placeholder = '',
    help_text = '',
    only_date = true,
    only_time = false,
    date_time = false,
    is_required = false,
    is_hidden = false,
    type = 'datetime',
  } = fieldProperties;
  const [errors, setErrors] = useState({});

  const { icon } = FormFields[type];

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!value) {
      setErrors({
        ...errors,
        [name]: 'This field is required',
      });
    } else {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handleOnUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setFieldProperties({ ...fieldProperties, [name]: value });
  };

  const handleOnCheckboxChange = (name: string) => {
    setFieldProperties({
      ...fieldProperties,
      [name]: !fieldProperties[name],
    });
  };

  const handleOnCreateField = () => {
    const { labId } = getController();
    createDataLabField(labId, fieldProperties);
  };

  const handleOnSaveOrCreate = () => {
    if (isNewField) {
      handleOnCreateField();
    } else {
      if (name) {
        setErrors({});

        const { labId } = getController();
        updateDataLabField(labId, fieldId, fieldProperties);
      } else {
        setErrors({
          ...errors,
          name: 'This field is required',
        });
      }
    }
  };

  const handleOnRemove = () => {
    const { labId } = getController();
    deleteDataLabField(labId, fieldId);
  };

  const handleOnBlockFieldClick = () => {
    // if parent_id is not null, then it is a child field.
    if (field.parent_id) return;

    if (selectedFieldId === fieldId) {
      setSelectedFieldId(null);
    } else {
      setSelectedFieldId(fieldId);
    }
  };

  const accordionTriggerStyle = { color: platinum_color };
  if (
    field.parent_id ||
    selectedFieldId === null ||
    selectedFieldId === fieldId
  ) {
    accordionTriggerStyle.color = '#18181B';
  }

  const handleOnDiscard = () => {
    setFieldProperties(field);
    setSelectedFieldId(null);
  };

  const handleOnDateTypeChange = (value: string) => {
    setFieldProperties({
      ...fieldProperties,
      only_date: value === 'only_date',
      date_time: value === 'date_time',
      only_time: value === 'only_time',
    });
  };

  const checkFieldIsDirty = () => {
    const isDirty = !isEqual(fieldProperties, field);

    const updatedField = isDirty ? fieldProperties : {}; // if field is not dirty, then return empty object

    updateUnsaveTracker({ isDirty, field: updatedField });
  };

  const placeHolderText = only_date
    ? 'dd/mm/yyyy'
    : date_time
    ? 'dd/mm/yyyy, --:-- AM'
    : '--:-- AM';

  const draggingStyles = isDragging
    ? { opacity: 0, backGraound: '#fafafa', cursor: 'grabbing' }
    : { opacity: 1 };

  const fieldName = capitalizeFirstLetter(name);
  const fieldType = capitalizeFirstLetter(type);

  return (
    <AccordionItem
      key={fieldId}
      value={fieldId}
      className='border bg-white rounded'
      ref={nodeRef}
      onBlur={checkFieldIsDirty}
    >
      <AccordionTrigger
        className='border-b px-4 py-4 hover:underline-offset-0 hover:bg-[#fafafa]'
        style={{ height: '52px', ...draggingStyles }}
        defaultChecked={selectedFieldId === fieldId}
        onClick={handleOnBlockFieldClick}
      >
        <div className='flex justify-between items-center'>
          <GripVerticalIcon color={platinum_color} />
          <span className='mx-2' style={{ color: platinum_color }}>
            {icon}
          </span>

          <span className='text-sm' style={accordionTriggerStyle}>
            {fieldName || fieldType}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent className='px-4 py-4'>
        <RadioGroup
          defaultValue={
            only_date ? 'only_date' : only_time ? 'date_time' : 'only_time'
          }
          className='flex space-x-2'
          onValueChange={handleOnDateTypeChange}
        >
          <div className='flex items-center space-x-2'>
            <RadioGroupItem value='only_date' id='only_date' />
            <Label htmlFor='only_date'>Date</Label>
          </div>
          <div className='flex items-center space-x-2'>
            <RadioGroupItem value='date_time' id='date_time' />
            <Label htmlFor='date_time'>Date and time</Label>
          </div>
          <div className='flex items-center space-x-2'>
            <RadioGroupItem value='only_time' id='only_time' />
            <Label htmlFor='only_time'>Time</Label>
          </div>
        </RadioGroup>
      </AccordionContent>
      <AccordionContent className='px-4 py-2 border-t'>
        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='name'
            label='Label for customers'
            value={name}
            onChange={handleOnUpdate}
            required
            error={errors['name']}
            onBlur={handleOnBlur}
          />

          <InputText
            name='label_agent'
            label='Label for agents'
            value={label_agent}
            onChange={handleOnUpdate}
          />
        </div>

        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='placeholder'
            label='Placeholder'
            value={placeholder}
            onChange={handleOnUpdate}
            placeholder={placeHolderText}
            readOnly
          />

          <InputText
            name='help_text'
            label='Help Text'
            value={help_text}
            onChange={handleOnUpdate}
            placeholder='Enter help text'
          />
        </div>
      </AccordionContent>
      <AccordionContent className='px-2 py-2 border-t h-[60px]'>
        <div className='flex justify-between'>
          <div className='flex'>
            <div className='flex items-center space-x-2'>
              <Checkbox
                id='is_required'
                className='rd-input-style-checkbox'
                name='is_required'
                checked={is_required}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_required');
                }}
              />
              <label
                htmlFor='is_required'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              >
                Set as required
              </label>
            </div>

            <div className='flex items-center space-x-2 ml-4'>
              <Checkbox
                id='is_hidden'
                className='rd-input-style-checkbox'
                name='is_hidden'
                checked={is_hidden}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_hidden');
                }}
              />
              <label
                htmlFor='is_hidden'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              >
                Set as hidden
              </label>
            </div>
          </div>

          <div className='flex'>
            <DeleteCofirmationDialog
              type={name}
              onDelete={handleOnRemove}
              name={fieldName}
            />

            <DiscardCofirmationDialog onDiscard={handleOnDiscard} />

            <SaveCofirmationDialog
              onClick={handleOnSaveOrCreate}
              isNewField={isNewField}
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default DateTime;
