'use client';

import { CartesianGrid, Line, LineChart, XAxis } from 'recharts';

import { Card, CardContent } from '../../../../../libraryV2/ui/card';
import {
  ChartConfig,
  ChartContainer,
  ChartLegend,
  ChartLegendContent,
  ChartTooltip,
  ChartTooltipContent,
} from '../../../../../libraryV2/ui/chart';
import dayJs from 'dayjs';

const chartConfig = {
  FREE_ENTRY_POINT: {
    label: 'FREE_ENTRY_POINT',
    color: '#2563eb',
  },
  FREE_TIER: {
    label: 'FREE_TIER',
    color: '#60a5fa',
  },
  MARKETING: {
    label: 'MARKETING',
    color: '#3b82f6',
  },
  SERVICE: {
    label: 'SERVICE',
    color: '#f97316',
  },
  AUTHENTICATION: {
    label: 'AUTHENTICATION',
    color: '#34d399',
  },
} satisfies ChartConfig;

export default function CustomReportingLineChart({ data, loader }: any) {
  const chartData = data.map((item: any) => ({
    month: dayJs(item?.timestamp * 1000).format('MMM D'), // If 'name' is missing, use 'Unknown'
    FREE_ENTRY_POINT: item.FREE_ENTRY_POINT,
    FREE_TIER: item.FREE_TIER,
    MARKETING: item.MARKETING,
    SERVICE: item.SERVICE,
    AUTHENTICATION: item.AUTHENTICATION, // Add AUTHENTICATION to the chart data
  }));

  return (
    <Card>
      <CardContent>
        <ChartContainer
          config={chartConfig}
          className='h-[253px] max-h-[450px] w-full'
        >
          <LineChart
            accessibilityLayer
            data={chartData}
            margin={{
              left: 12,
              right: 12,
            }}
          >
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey='month'
              tickLine={false}
              axisLine={false}
              tickMargin={8}
              tickFormatter={(value) => value}
            />
            <ChartTooltip content={<ChartTooltipContent />} />
            <ChartLegend content={<ChartLegendContent />} />
            <Line
              dataKey='FREE_ENTRY_POINT'
              type='monotone'
              stroke='var(--color-FREE_ENTRY_POINT)'
              strokeWidth={2}
            />
            <Line
              dataKey='FREE_TIER'
              type='monotone'
              stroke='var(--color-FREE_TIER)'
              strokeWidth={2}
            />
            <Line
              dataKey='MARKETING'
              type='monotone'
              stroke='var(--color-MARKETING)'
              strokeWidth={2}
            />
            <Line
              dataKey='SERVICE'
              type='monotone'
              stroke='var(--color-SERVICE)'
              strokeWidth={2}
            />
          </LineChart>
        </ChartContainer>
      </CardContent>
    </Card>
  );
}
