import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'libraryV2/ui/sheet';

import { Filter } from '../../../export';

interface SiteSheetProps {
  onClose: () => void;
  isOpen: boolean;
}

const FilterSideSheet = ({ onClose, isOpen = false }: SiteSheetProps) => {
  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        side='right'
        className='bg-white sm:max-w-[470px] sm:w-[470px] overflow-auto scrollbar-hide'
      >
        <SheetHeader>
          <SheetTitle>Add filter</SheetTitle>
        </SheetHeader>
        <div className='h-[82vh] mt-4'>
          <Filter onSideSheetClose={onClose} />
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default FilterSideSheet;
