import Link from '../../../library/link';

export const getModalCustomFooter = (
  description: string,
  link: string,
  linkTitle: string
) => {
  return (
    <div className='flex justify-end mt-2'>
      <label htmlFor='remember_me' className='block ml-2 text-sm text-gray-900'>
        {description}{' '}
        <Link url={link} openInNewTab={true}>
          {linkTitle}
        </Link>
      </label>
    </div>
  );
};
