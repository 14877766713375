import groupBy from 'lodash/groupBy';

import SingleItem from './singleItem';

import { FormFields } from 'pages/datalab/export';
import { IFormField } from 'pages/datalab/interface';

const LeftSideBar = () => {
  const formFieldsArr = Object.values(FormFields);
  const groupedFormFields = groupBy(formFieldsArr, 'category');

  return (
    <div
      className={`row-span-3 bg-white p-4 border-r border-gray-200 dark:border-gray-600 dark:bg-slate-800 h-auto w-[380px] overflow-y-auto scrollbar-hide`}
    >
      {Object.keys(groupedFormFields).map((category: string, index: number) => {
        if (!category || category === 'undefined') return null;

        return (
          <div key={index}>
            <span className='text-xs text-[#71717A]'>
              {category.toUpperCase()}
            </span>
            <div className='grid grid-cols-3 gap-2 mt-3 mb-2'>
              {groupedFormFields[category].map(
                (field: IFormField, i: number) => (
                  <SingleItem field={field} key={i} />
                )
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default LeftSideBar;
