import { CSSProperties } from 'react';
import WhatsappBg from '../../../../../broadcast/assets/whatsappBg.png';
import DeviceTopBar from '../assets/DeviceTopbar.svg';
import {
  ArrowLeftIcon,
  ArrowTopRightOnSquareIcon,
  CheckBadgeIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  EllipsisVerticalIcon,
  ListBulletIcon,
  PhoneIcon,
  PlayCircleIcon,
} from '@heroicons/react/24/solid';
import AliceLogo from '../../../../../../assets/images/aliceLogo.svg';
import {
  IWhatsappCarouselCard,
  IWhatsappStore,
  TCarouselCardHeaderType,
} from 'pages/integration/hooks/useWhatsappTemplateData';
import { getAuthTranslationData } from '../utils/authTemplateTranslation';
import { IWhatsappTemplateButton } from 'pages/integration/interface';
import WhatsappSequenceButtonIcon from '../assets/WhatsappSequenceButtonIcon.svg';
import {
  formatWhatsappText,
  replaceAndHighlightVariableInText,
} from '../utils/whatsappUtility';
import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  channelName?: String;
  channelIcon?: string;
  templateData: IWhatsappStore;
}
const randomAuthCode = Math.floor(Math.random() * 1000000) + ' ';

let Options: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: true,
};

const renderSingleWhatsappTemplateButton = (
  button: IWhatsappTemplateButton
) => {
  switch (button?.type) {
    case 'url':
      return (
        <div
          key={button?.id}
          className='flex justify-center items-center gap-2 py-2 border-1 border-t'
        >
          <ArrowTopRightOnSquareIcon className='w-4 h-4 font-bold text-[#0EA5E9]' />
          <span className='text-[#0EA5E9] text-xs font-semibold'>
            {button?.title}
          </span>
        </div>
      );

    case 'phone_number':
      return (
        <div
          key={button?.id}
          className='flex justify-center items-center gap-2 border-1 border-t py-2'
        >
          <PhoneIcon className='w-4 h-4 text-[#0EA5E9]' />
          <span className='text-[#0EA5E9] text-xs font-semibold'>
            {button?.title}
          </span>
        </div>
      );

    case 'sequence':
      return (
        <div
          key={button?.id}
          className='flex justify-center items-center gap-2 border-1 border-t py-2'
        >
          <img
            src={WhatsappSequenceButtonIcon}
            alt='whatsapp'
            className='w-4 h-4'
          />
          <span className='text-[#0EA5E9] text-xs font-semibold'>
            {button?.title}
          </span>
        </div>
      );
    default:
      return null;
  }
};

const renderSeeAllOptions = () => {
  return (
    <div className='flex justify-center items-center gap-2 py-2 border-1 border-t'>
      <ListBulletIcon className='w-4 h-4 font-bold text-[#0EA5E9]' />
      <span className='text-[#0EA5E9] text-xs font-semibold'>
        See all options
      </span>
    </div>
  );
};

const WhatsappTemplatePreview: React.FC<Props> = ({
  channelName = 'MyAlice',
  channelIcon,
  templateData,
}) => {
  const { t, dashboardLanguage } = useTranslation();
  const localLanguage = dashboardLanguage === 'arabic' ? 'ar-SA' : 'en-US';
  const { currentPartnerInfo, isPartnerRestricted } = usePartner();
  const shouldDisableAliceLogo = isPartnerRestricted(
    'WhatsappTemplatePreview/function/renderDefaultLogo'
  );
  const currentTime = new Date().toLocaleTimeString(localLanguage, Options);
  const isAuthenticationTemplate = templateData.category === 'AUTHENTICATION';
  const bodyStyle: CSSProperties = {
    backgroundImage: `url(${WhatsappBg})`,
    backgroundSize: 'cover',
    height: '70%',
  };

  const renderChannelLogo = () => {
    if (channelIcon) {
      return (
        <img
          id='teamImage'
          className={`w-full scale-150 h-full rounded-full inline-block`}
          src={channelIcon}
          alt='team_avatar'
        />
      );
    }
    if (shouldDisableAliceLogo && currentPartnerInfo?.icon) {
      return <div className='w-full h-full'>{currentPartnerInfo?.icon}</div>;
    } else {
      return (
        <img
          id='teamImage'
          className={`w-full h-full inline-block`}
          src={AliceLogo}
          alt='team_avatar'
        />
      );
    }
  };

  const renderAuthTemplatePreview = () => {
    return (
      <div
        id='wa-preview-template-body'
        className='bg-white w-[80%] rounded-lg ltr:ml-2 rtl:mr-2 mt-2 text-xs font-normal flex flex-col gap-2'
      >
        <div className='px-2 pt-2'>
          <span className='font-bold'> {randomAuthCode} </span>
          <span>
            {templateData.authTemplateBody?.replace(
              '{{verification_code}}',
              ''
            )}
          </span>
        </div>
        <div
          className={`text-[#788082] px-2 ${
            templateData.hasAuthCodeExpirationTime ? 'visible' : 'hidden'
          }`}
        >
          {getAuthTranslationData(
            'expiration_text',
            templateData.language,
            true,
            templateData.authCodeExpirationTime ?? '0'
          )}
        </div>
        <div className='flex justify-end text-[#788082] px-2'>
          <span> {currentTime} </span>
        </div>
        <hr className='border-0 border-t' />
        <button className='text-blue-500 font-semibold gap-2 justify-center align-center flex text-xs pb-2'>
          <DocumentDuplicateIcon className='w-4 h-4' />
          {getAuthTranslationData('copy_text', templateData.language)}
        </button>
      </div>
    );
  };

  const renderTemplateHeaderPreview = (
    templateHeaderType: string,
    headerValue = ''
  ) => {
    if (typeof headerValue !== 'string' || headerValue.length === 0) {
      return null;
    }
    switch (templateHeaderType) {
      case 'text': {
        const indexOfVariable = headerValue.lastIndexOf('{{1}}') ?? -1;
        if (indexOfVariable === -1) {
          return <h1 className='font-semibold text-xs px-2'>{headerValue}</h1>;
        }
        const firstPart = headerValue.slice(0, indexOfVariable);
        let variablePart = templateData?.headerVariable?.length
          ? templateData.headerVariable
          : headerValue?.slice(indexOfVariable, indexOfVariable + 5);
        const lastPart = headerValue?.slice(indexOfVariable + 5) ?? '';
        return (
          <h1 key={headerValue} className='font-semibold text-xs px-2'>
            <span> {firstPart} </span>
            <span className='bg-indigo-50 text-indigo-400 rounded-sm'>
              {variablePart}
            </span>
            <span> {lastPart} </span>
          </h1>
        );
      }
      case 'image': {
        return (
          <div key={headerValue} className='w-full h-auto px-2 mt-2'>
            <img
              src={headerValue}
              className='w-full rounded-md'
              alt='header_img'
            />
          </div>
        );
      }
      case 'video': {
        return (
          <div key={headerValue} className={`flex w-full px-2 mt-2`}>
            <div className='relative cursor-pointer  w-full mx-auto'>
              <div className='absolute bg-gray-500 bg-opacity-40 rounded-md h-[120px] w-full flex justify-center items-center'>
                <PlayCircleIcon className='text-white w-9 h-9' />
              </div>
              <video className='object-fill rounded-md h-[120px] w-full'>
                <source src={headerValue} />
              </video>
            </div>
          </div>
        );
      }
      case 'document': {
        return (
          <div
            key={headerValue}
            className='w-full flex flex-col items-center rounded-md px-4 py-6'
          >
            <DocumentIcon className='w-9 h-9 text-gray-400' />
            <div className='mt-2 w-full'>
              <p className='text-xs text-center font-medium leading-5 text-gray-600 truncate'>
                {headerValue?.split('/').pop()}
              </p>
            </div>
          </div>
        );
      }
      default: {
        return (
          <div key={headerValue} className='w-full h-auto'>
            <span className=' font-bold text-xs text-[#131B20] break-words whitespace-normal'>
              {headerValue}
            </span>
          </div>
        );
      }
    }
  };

  const renderGenericTemplatePreview = () => {
    const templateHeaderType = templateData?.headerType ?? 'None';
    return (
      <div className='bg-white pt-1 text-xs text-md flex flex-col gap-2 w-[80%] rounded-md ltr:ml-2 rtl:mr-2 my-2'>
        {renderTemplateHeaderPreview(
          templateHeaderType,
          templateData.headerValue
        )}
        <div
          className='whitespace-pre-line px-2 text-xs'
          id='wa-preview-template-body'
          dangerouslySetInnerHTML={{
            __html: replaceAndHighlightVariableInText(
              formatWhatsappText(templateData.body ?? ''),
              templateData.bodyVariables
            ),
          }}
        ></div>
        <div
          id='wa-preview-template-footer'
          className='text-[#788082] text-xs px-2'
        >
          {templateData?.footer}
        </div>
        <div className='flex justify-end text-[#788082] text-xs px-2'>
          {currentTime}
        </div>
        <div className='flex flex-col'>
          {templateData?.buttons?.slice(0, 2).map((button, index) => {
            return renderSingleWhatsappTemplateButton(button);
          })}
          {templateData?.buttons?.length > 2 ? renderSeeAllOptions() : null}
        </div>
      </div>
    );
  };

  const renderSingleCarouselCardPreview = (
    card: IWhatsappCarouselCard,
    headerType: TCarouselCardHeaderType
  ) => {
    const headerValue = card?.header?.value || '';
    const cardBody = card?.body || '';
    const cardButtons = card?.buttons || [];

    const isEmptyCard = [headerValue, cardBody, cardButtons].every(
      (v) => !v.length
    );
    if (isEmptyCard) {
      return null;
    }
    return (
      <div className='w-full flex flex-col gap-2'>
        <div className='px-2'>
          {renderTemplateHeaderPreview(headerType, headerValue)}
        </div>
        <div className='text-xs whitespace-pre-line px-2 text-[#1F2C34] font-normal leading-5'>
          {cardBody}
        </div>
        <div className='flex flex-col'>
          {cardButtons?.slice(0, 2).map((button, index) => {
            return renderSingleWhatsappTemplateButton(button);
          })}
        </div>
      </div>
    );
  };

  const renderCarouselTemplatePreview = () => {
    const {
      cards,
      headerType: carouselHeaderType,
      activeCarouselCardIndex,
    } = templateData;
    const activeCard = cards.find((c) => c.id === activeCarouselCardIndex);

    return (
      <div className='w-full relative flex flex-col gap-2 pt-2'>
        <div className='absolute left-2 top-2 w-0 h-0 border-l-[30px] border-l-transparent border-t-[50px] border-white rounded-md'></div>
        <div className='relative bg-white pt-1 text-xs text-md flex flex-col gap-2 w-[80%] rounded-md ltr:ml-4 rtl:mr-4'>
          <div
            className='whitespace-pre-line px-2 text-xs'
            id='wa-preview-template-body'
            dangerouslySetInnerHTML={{
              __html: replaceAndHighlightVariableInText(
                formatWhatsappText(templateData.body ?? ''),
                templateData.bodyVariables
              ),
            }}
          ></div>
          <div className='flex justify-end text-[#788082] text-xs px-2'>
            {currentTime}
          </div>
        </div>
        <div className='relative bg-white text-xs text-md flex flex-col gap-2 w-[80%] rounded-md ltr:ml-4 rtl:mr-4'>
          {renderSingleCarouselCardPreview(
            activeCard!,
            carouselHeaderType as TCarouselCardHeaderType
          )}
        </div>
      </div>
    );
  };

  const renderTemplatePreview = () => {
    if (isAuthenticationTemplate) {
      return renderAuthTemplatePreview();
    } else if (templateData.templateType === 'CAROUSEL') {
      return renderCarouselTemplatePreview();
    } else {
      return renderGenericTemplatePreview();
    }
  };

  const isDisabledPreview = isAuthenticationTemplate
    ? false
    : !templateData.body?.length &&
      !templateData.footer &&
      !templateData.headerValue;

  return (
    <div className='relative w-[85%] h-full mx-auto'>
      <h1 className='text-lg font-medium leading-7 capitalize  w-full text-gray-900 mb-2'>
        {t('Preview')}
      </h1>

      <div
        style={bodyStyle}
        className='relative max-w-[450px] rounded-lg overflow-y-auto'
      >
        <img
          src={DeviceTopBar}
          alt='whatsapp'
          className='w-full border-2 rounded-t-lg border-none'
        />
        <div className='bg-[#008268] p-2 flex gap-2 items-center justify-between'>
          <div className='flex gap-2 items-center'>
            <ArrowLeftIcon className='text-white rtl:-rotate-180 h-4 w-4 font-bold ' />
            <div className='h-6 w-6 p-1 bg-white flex justify-center rounded-full'>
              {renderChannelLogo()}
            </div>
            <h1 className='text-white flex text-xs font-medium text-truncate'>
              {channelName}
            </h1>
            {shouldDisableAliceLogo ? null : (
              <CheckBadgeIcon className='h-4 w-4 text-green-400' />
            )}
          </div>
          <EllipsisVerticalIcon className='h-4 w-4 text-white font-bold' />
        </div>

        {isDisabledPreview ? null : renderTemplatePreview()}
      </div>
    </div>
  );
};
export default WhatsappTemplatePreview;
