import Button from 'library/button';
import { useEffect, useState } from 'react';
import useOTP from './hooks/useOtp';
import AuthHeader from './components/AuthHeader';
import { navigate } from '@reach/router';
import { CircleSpinner } from 'pages/inbox/assets/iconComponent/Spinner';
import useTranslation from 'components/customHooks/useTranslation';

const VerifyOTP = () => {
  const {
    token,
    verifyOTP,
    resendOTP,
    updateTimer,
    verifyLoading,
    resendLoading,
    updateToken,
  } = useOTP();
  const [otp, setOTP] = useState<string>('');
  const [email, setEmail] = useState('');

  const [timeLeft, setTimeLeft] = useState(60);

  const { authT } = useTranslation();

  useEffect(() => {
    // Get the query string from the URL
    const queryString = window.location.search;

    // Create a URLSearchParams object from the query string
    const params = new URLSearchParams(queryString);

    // Get the value of token from param
    const tokenStr = params.get('token');

    if (!!tokenStr) {
      updateToken(tokenStr);
      setEmail(params.get('email') || '');
    } else navigate('/');

    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      updateTimer(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, updateTimer]);

  const handleOtpChange = (value: string) => {
    if (!isNaN(Number(value))) {
      setOTP(value);
    }
  };

  const handleSubmit = () => {
    if (!!token && !!otp) verifyOTP(token, otp);
  };

  const handleResendOTP = async () => {
    if (!!token) {
      const success = await resendOTP(token, email);
      if (success) setTimeLeft(60);
      else navigate('/');
    }
  };

  const renderOTPInputField = () => {
    return (
      <div className='w-full mr-2'>
        <label
          htmlFor='otpVerification'
          className='block text-sm font-medium text-gray-700'
        >
          {authT('Verification Code')}
        </label>
        <div className='mt-1'>
          <input
            id='otpVerification'
            name='verifyOtp'
            type='text'
            value={otp}
            placeholder={authT('Provide Verification Code')}
            onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
            onChange={(e) => handleOtpChange(e.target.value)}
            required
            className='w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
          />
        </div>
      </div>
    );
  };

  const renderResendButton = () => {
    return (
      <Button
        isDisabled={timeLeft > 0}
        isLoading={resendLoading}
        intent='primary'
        onClick={() => {
          handleResendOTP();
        }}
      >
        {timeLeft > 0
          ? `00:${timeLeft}`
          : resendLoading
          ? authT('Resending ')
          : authT('Resend')}
        {resendLoading && (
          <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-gray-500 inline ' />
        )}
      </Button>
    );
  };

  const renderOTPField = () => {
    return (
      <div className='flex items-end justify-between'>
        {renderOTPInputField()}
        {renderResendButton()}
      </div>
    );
  };

  return (
    <div className='flex flex-col justify-center min-h-screen py-12 bg-white sm:px-6 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-md  '>
        <div className='px-4 pb-8 sm:px-10 space-y-6 '>
          <AuthHeader
            title={'Verify your login'}
            subtitle={`We’ve sent you a verification code to ${
              !!email ? email : authT('your mail')
            }`}
          />
          {renderOTPField()}
          <Button
            onClick={() => handleSubmit()}
            isFullWidth={true}
            size='sm'
            intent='primary'
            isDisabled={!otp || otp.length !== 6}
            isLoading={verifyLoading}
          >
            {verifyLoading ? authT('Verifying ') : authT('Verify')}
            {verifyLoading && (
              <CircleSpinner className='animate-spin ml-1 h-5 w-5 text-gray-500 inline ' />
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default VerifyOTP;
