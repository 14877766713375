import usePartner from 'components/customHooks/usePartner';
import useTranslation from 'components/customHooks/useTranslation';
import React, { useEffect } from 'react';
import PasswordStrengthBar from 'react-password-strength-bar';
import { connect } from 'react-redux';
import IomnihubPowerdBy from '../../../assets/icons/partnerIconSet/Infocomm-Logo-on-Powered.svg';
import { Button } from '../../../library';

interface Props {
  verifyCode: string;
  content: {
    forget: { title: string; subtitle: string; savePassword: string };
  };
  password: string;
  confirmPassword: string;
  loading: boolean;
  handleChange: (key: string, value: string) => void;
  handleResendMail: () => void;
  handleSubmit: () => void;
  updateTimer: (time: number) => void;
  spentTime: number;
}
const ForgetPasswordVerifyComponent: React.FC<Props> = ({
  verifyCode,
  content,
  password,
  confirmPassword,
  handleChange,
  loading,
  handleResendMail,
  handleSubmit,
  updateTimer,
  spentTime,
}) => {
  const [timeLeft, setTimeLeft] = React.useState(spentTime);
  const { isPartnerRestricted, currentPartnerInfo } = usePartner();
  const shouldRenderPartnerBranding = isPartnerRestricted(
    'ForgetPasswordVerifyComponent/node/partner-branding'
  );
  const { authT } = useTranslation();

  useEffect(() => {
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
      updateTimer(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft, updateTimer]);

  const renderParnterBranding = () => {
    if (!shouldRenderPartnerBranding) {
      return null;
    }
    switch (currentPartnerInfo?.domain) {
      case 'app.iomnihub.ai': {
        return (
          <div id='partner-branding' className='w-full flex justify-center'>
            <img
              id='iomni-powerd-by'
              className='mt-8'
              src={IomnihubPowerdBy}
              width={128}
              height={128}
              alt='hello'
            />
          </div>
        );
      }

      default:
        return null;
    }
  };

  return (
    <div className='sm:mx-auto sm:w-full sm:max-w-md'>
      <div className='px-4 py-8 sm:px-10'>
        <div className='space-y-6'>
          <div className='flex items-end justify-between'>
            <div className='w-full rtl:flex-1 ltr:mr-2 rtl:ml-2'>
              <label
                htmlFor='verificationCode'
                className='block text-sm font-medium text-gray-700'
              >
                {authT('Verification Code')}
              </label>
              <div className='mt-1'>
                <input
                  id='verificationCode'
                  name='verifyCode'
                  type='text'
                  value={verifyCode}
                  placeholder={authT('Provide Verification Code')}
                  onKeyPress={(e) =>
                    e.key === 'Enter' ? handleSubmit() : null
                  }
                  onChange={(e) => handleChange(e.target.name, e.target.value)}
                  required
                  className='w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
                />
              </div>
            </div>
            <Button
              isDisabled={timeLeft > 0}
              intent='primary'
              onClick={() => {
                handleResendMail();
                setTimeLeft(60);
              }}
            >
              {timeLeft > 0 ? `00:${timeLeft}` : authT('Resend')}
            </Button>
          </div>

          <div>
            <label
              htmlFor='newPassword'
              className='block text-sm font-medium text-gray-700'
            >
              {authT('New Password')}
            </label>
            <div className='mt-1'>
              <input
                id='newPassword'
                name='password'
                type='password'
                required
                value={password}
                placeholder={authT('Provide Password')}
                onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
          </div>
          <div>
            <label
              htmlFor='confirmPassword'
              className='block text-sm font-medium text-gray-700'
            >
              {authT('Confirm Password')}
            </label>
            <div className='mt-1'>
              <input
                id='confirmPassword'
                name='confirmPassword'
                type='password'
                placeholder={authT('Confirm Password')}
                required
                value={confirmPassword}
                onKeyPress={(e) => (e.key === 'Enter' ? handleSubmit() : null)}
                onChange={(e) => handleChange(e.target.name, e.target.value)}
                className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary focus:border-primary sm:text-sm'
              />
            </div>
          </div>
          <PasswordStrengthBar password={password} minLength={2} />
          <div>
            <Button
              isFullWidth={true}
              isDisabled={confirmPassword !== password}
              isLoading={loading}
              intent='primary'
              onClick={() => handleSubmit()}
            >
              {authT(content.forget.savePassword)}
            </Button>
          </div>
          {renderParnterBranding()}
        </div>
      </div>
    </div>
  );
};

const mapState = (state: any) => ({
  spentTime: state.auth.spentTime,
});

const mapDispatch = (dispatch: any) => ({
  updateTimer: (time: number) => dispatch.auth.updateTimer(time),
});

const ForgetPasswordVerify = connect(
  mapState,
  mapDispatch
)(ForgetPasswordVerifyComponent);

export default ForgetPasswordVerify;
