import React from 'react';
import { Button } from '../../index';
import { connect } from 'react-redux';
import { classNames } from 'utilities/utils';

interface Prop {
  cancelText?: string;
  confirmText?: string;
  handleConfirm: () => void;
  handleCancel: () => void;
  disableConfirm?: boolean;
  isConfirmButtonLoading?: boolean;
  hideCancel: boolean;
  hasDropdown?: boolean;
  handleChange?: (value: any) => void;
  orderStatus: string;
  hasLeftActionElement?: boolean;
  hideLeftActionElement?: boolean;
  leftActionElement?: any;
  hasConfirmButton?: boolean;
  hasTopBorder?: boolean;
  customConfirmButton?: React.ReactNode;
  customFooterContent?: React.ReactNode;
}

const Footer: React.FC<Prop> = ({
  cancelText = 'Cancel',
  confirmText = 'Continue',
  handleConfirm,
  handleCancel,
  disableConfirm,
  isConfirmButtonLoading,
  hideCancel,
  hasDropdown = false,
  hasTopBorder = true,
  handleChange,
  orderStatus,
  hasLeftActionElement = false,
  hideLeftActionElement = true,
  leftActionElement,
  hasConfirmButton = true,
  customConfirmButton,
  customFooterContent,
}) => {
  return (
    <div className={`w-full p-4 bg-white ${hasTopBorder ? 'border-t' : ''}`}>
      <div
        className={classNames(
          `flex w-full items-center`,
          !hideLeftActionElement || hasDropdown ? 'justify-between' : 'flex-end'
        )}
      >
        {hasLeftActionElement && !hideLeftActionElement && leftActionElement}

        {hasDropdown && (
          <select
            name='order'
            className='block py-2 pl-3 pr-10 mr-2 text-base border-gray-300 rounded-md cursor-pointer focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm'
            value={orderStatus}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              if (handleChange) {
                handleChange(e.target.value);
              }
            }}
          >
            {/* note for future! please never keep scoped contents to global scope rather pass it as componenet
            content-scoped: its a order creation hasDropdown
            mainScope: modal is generic for while project
            solution: pass footer contents as custom component/reactNode[] instead of scoping a global copmonent */}
            <option value={'pending'}>Pending</option>
            <option value={'completed'}>Completed</option>
            <option value={'draft'}>Draft</option>
          </select>
        )}
        <div className='flex gap-2'>
          {!hideCancel && (
            <Button intent='default' size={'sm'} onClick={() => handleCancel()}>
              {cancelText}
            </Button>
          )}
          {!!customConfirmButton
            ? customConfirmButton
            : hasConfirmButton && (
                <Button
                  intent={'primary'}
                  size={'sm'}
                  isLoading={isConfirmButtonLoading}
                  isDisabled={disableConfirm}
                  onClick={() => handleConfirm()}
                >
                  {confirmText}
                </Button>
              )}
        </div>
      </div>
      {!!customFooterContent && customFooterContent}
    </div>
  );
};

const mapState = (state: any) => ({
  orderStatus: state.crmEcommerce.orderStatus,
});

const mapDispatch = (dispatch: any) => ({});

const FooterContainer = connect(mapState, mapDispatch)(Footer);
export default FooterContainer;
