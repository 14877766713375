import React from 'react';
import Linkify from 'react-linkify';

import useTranslation from 'components/customHooks/useTranslation';
import { cn } from 'libraryV2/utils';
import { Dot } from 'lucide-react';
import useMessageBlocksAction from 'pages/inbox/hooks/useMessageBlocksAction';
import { getLangDirection } from 'pages/inbox/utils/functions';
import { emailChannels, feedChannels } from 'utilities/utils';
import { ConversationBlockNoteDataInterface } from '../../../inboxInterface';
import Avatar from '../components/conversation/Avatar';
import Time from '../components/conversation/Time';
import DateBar from './DateBar';

interface Props {
  blockData: ConversationBlockNoteDataInterface;
  avatar: string;
  name: string;
  time: number;
  source: string;
  enableDateBar: boolean;
  isReplyComment?: true;
  platformType?: string;
  isMergeable?: boolean;
}

const NoteMessage: React.FC<Props> = ({
  blockData,
  avatar,
  name,
  time,
  source,
  enableDateBar,
  isReplyComment = false,
  platformType,
}) => {
  const { t, isRtlLanguage } = useTranslation();
  const { rowDirection, isZigzagView } = useMessageBlocksAction({ source });
  const isEmailChannel = emailChannels.includes(platformType ?? '');
  const isFeedChannels = feedChannels.includes(platformType ?? '');

  const borderRadius =
    isZigzagView && !isEmailChannel && !isFeedChannels
      ? 'rounded-xl ltr:rounded-tr-[4px] rtl:rounded-tl-[4px]'
      : isEmailChannel && getLangDirection(blockData?.text) === 'rtl'
      ? 'rounded-xl rounded-tr-[4px]'
      : !isZigzagView &&
        isRtlLanguage &&
        getLangDirection(blockData?.text) === 'rtl'
      ? 'rounded-xl rounded-tr-[4px]'
      : isFeedChannels && isRtlLanguage
      ? 'rounded-xl rounded-tr-[4px]'
      : 'rounded-xl rounded-tl-[4px]';

  const getSourceNameColor = () => {
    switch (source) {
      case 'customer':
        return 'text-green-500';
      case 'bot':
        return 'text-[#3B82F6]';
      case 'admin':
      case 'echo':
        return 'text-gray-900';
      default:
        return 'text-green-500';
    }
  };

  const renderNoteTextView = () => {
    return (
      <p
        data-testid='text-note-block'
        className='w-auto h-auto rtl:order-1 text-gray-900 break-words whitespace-pre-wrap sm:text-sm'
      >
        <Linkify
          componentDecorator={(decoratedHref, decoratedText, key) => (
            <a
              target='blank'
              className='text-link'
              href={decoratedHref}
              key={key}
            >
              {decoratedText}
            </a>
          )}
        >
          {blockData.success ? blockData?.text : blockData.error}
        </Linkify>
      </p>
    );
  };

  return (
    <>
      <div
        className={cn(
          'relative group bg-[#FFFBEB] flex  w-full h-auto pt-0.5 pb-1.5',
          isFeedChannels && isZigzagView && 'flex-start',
          isEmailChannel && getLangDirection(blockData?.text) === 'ltr'
            ? 'ltr:flex-start rtl:flex-row-reverse'
            : isEmailChannel && getLangDirection(blockData?.text) === 'rtl'
            ? 'ltr:flex-row-reverse rtl:flex-start'
            : isZigzagView && !isFeedChannels
            ? 'flex-row-reverse'
            : 'flex-start'
        )}
      >
        <Avatar avatar={avatar} isReplyComment={isReplyComment} />
        <div className='max-w-[80%]'>
          <div className={`flex gap-1 flex-col ${rowDirection}`}>
            <div
              className={cn(
                'flex items-center mt-1.5',
                isZigzagView && !isEmailChannel && 'justify-end',
                getLangDirection(blockData?.text) === 'rtl' &&
                  isEmailChannel &&
                  'ltr:justify-end rtl:justify-start',
                isFeedChannels && 'justify-start'
              )}
            >
              <div
                className={cn(
                  'w-auto h-auto text-[#F59E0B] sm:text-xs',
                  !isZigzagView && 'order-2'
                )}
              >
                {t('Private Note')}
              </div>
              <Dot
                className={cn(
                  'self-end w-6 h-6 mt-1 flex items-center justify-center text-[#DFDFE2]',
                  !isZigzagView && 'order-1'
                )}
              />
              <div
                className={`w-auto h-auto font-bold sm:text-sm ${getSourceNameColor()}`}
              >
                {t(name)}
              </div>
            </div>

            <div
              className={`flex flex-col ${
                isZigzagView && !isEmailChannel && 'items-end'
              } ${
                getLangDirection(blockData?.text) === 'rtl' &&
                isZigzagView &&
                isEmailChannel &&
                'ltr:items-end rtl:items-start'
              } ${
                getLangDirection(blockData?.text) === 'ltr' &&
                isEmailChannel &&
                isZigzagView &&
                'ltr:items-start rtl:items-end'
              }  ${
                getLangDirection(blockData?.text) === 'ltr' &&
                isEmailChannel &&
                !isZigzagView &&
                'ltr:items-start rtl:items-end'
              } ${
                getLangDirection(blockData?.text) === 'rtl' &&
                isEmailChannel &&
                !isZigzagView &&
                'ltr:items-end rtl:items-start'
              }`}
            >
              <div
                className={`relative p-2 flex flex-col border border-[#FCD34D] w-max ${borderRadius} bg-[#FEF3C7]`}
              >
                <div className='flex items-center ltr:justify-end rtl:justify-start gap-3'>
                  {renderNoteTextView()}

                  <div className='flex flex-end self-end gap-1.5 mt-4'>
                    <Time time={time} isMerged={false} source={undefined} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!!enableDateBar && <DateBar time={time} />}
    </>
  );
};

export default NoteMessage;
