import React, { useState, Fragment } from 'react';
import { LayoutListIcon, GripVerticalIcon } from 'lucide-react';

import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'libraryV2/ui/accordion';
import { Checkbox } from 'libraryV2/ui/checkbox';

import { Button } from 'libraryV2/ui/button';
import { PlusIcon } from 'lucide-react';
import { isEqual } from 'lodash';

import {
  InputText,
  SaveCofirmationDialog,
  DiscardCofirmationDialog,
  DeleteCofirmationDialog,
  useActions,
  useTriggerActions,
  LabelSeparator,
  DropZone,
  DragTypes,
  arrayMove,
  platinum_color,
  capitalizeFirstLetter,
  IDataLabField,
} from 'pages/datalab/export';
import Choice from './choice';

interface IProps {
  field: IDataLabField;
  isDragging: boolean;
  isSmall?: boolean;
  nodeRef?: React.RefObject<HTMLDivElement>;
}

const SingleSelect: React.FC<IProps> = (props) => {
  const { field, isDragging, isSmall, nodeRef } = props;
  const { setSelectedFieldId, getSelectedFieldId, getController } =
    useActions();

  const {
    createDataLabField,
    updateDataLabField,
    deleteDataLabField,
    updateUnsaveTracker,
  } = useTriggerActions();

  const [fieldProperties, setFieldProperties] = useState(field);
  const [currChoice, setCurrChoice] = useState({ value: '', error: '' });

  const {
    id: fieldId,
    name,
    label_agent,
    placeholder,
    help_text,
    is_required,
    is_hidden,
    choices = [],
    type = 'singleselect',
  } = fieldProperties;

  const isNewField =
    typeof field.id === 'string' &&
    field.id.startsWith('random') &&
    !field.slug;

  const selectedFieldId = getSelectedFieldId();

  const [errors, setErrors] = useState({});

  const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (!value) {
      setErrors({
        ...errors,
        [name]: 'This field is required',
      });
    } else {
      setErrors({
        ...errors,
        [name]: false,
      });
    }
  };

  const handleOnUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { name, value } = e.target;
    setFieldProperties({ ...fieldProperties, [name]: value });
  };

  const handleOnChoiceUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const { value } = e.target;
    setCurrChoice({ value, error: '' });
  };

  const handleOnAddChoice = () => {
    if (currChoice.value) {
      const updatedChoices = choices;

      updatedChoices.push({ name: currChoice.value });

      setFieldProperties({
        ...fieldProperties,
        choices: updatedChoices,
      });

      setCurrChoice({ value: '', error: '' });
    } else {
      setCurrChoice({ value: '', error: 'Please enter minimum one option.' });
    }
  };

  const handleOnBlurChoice = () => {
    if (currChoice.error) {
      setCurrChoice({ ...currChoice, error: '' });
    }
  };

  const handleOnDeleteChoice = (index: number) => {
    const choices = fieldProperties.choices || [];
    choices.splice(index, 1);
    setFieldProperties({
      ...fieldProperties,
      choices,
    });
  };

  const handleOnCheckboxChange = (name: string) => {
    setFieldProperties({
      ...fieldProperties,
      [name]: !fieldProperties[name],
    });
  };

  const getFieldPropertiesWithCurrChoice = () => {
    const updatedFieldProperties = { ...fieldProperties };

    if (currChoice.value) {
      const updatedChoices = choices;

      updatedChoices.push({ name: currChoice.value });

      updatedFieldProperties.choices = updatedChoices;

      setCurrChoice({ value: '', error: '' });
    }

    return updatedFieldProperties;
  };

  const handleOnCreateField = () => {
    const { labId } = getController();
    const updatedFieldProperties = getFieldPropertiesWithCurrChoice();
    createDataLabField(labId, updatedFieldProperties);
  };

  const handleOnSaveOrCreate = () => {
    if (isNewField) {
      handleOnCreateField();
    } else {
      if (name) {
        setErrors({});

        const { labId } = getController();
        const updatedFieldProperties = getFieldPropertiesWithCurrChoice();
        updateDataLabField(labId, fieldId, updatedFieldProperties);
      } else {
        setErrors({
          ...errors,
          name: 'This field is required',
        });
      }
    }
  };

  const handleOnRemove = () => {
    const { labId } = getController();
    deleteDataLabField(labId, fieldId);
  };

  const handleOnBlockFieldClick = () => {
    // if parent_id is not null, then it is a child field.
    if (field.parent_id) return;

    if (selectedFieldId === fieldId) {
      setSelectedFieldId(null);
    } else {
      setSelectedFieldId(fieldId);
    }
  };

  const accordionTriggerStyle = { color: platinum_color };
  if (
    field.parent_id ||
    selectedFieldId === null ||
    selectedFieldId === fieldId
  ) {
    accordionTriggerStyle.color = '#18181B';
  }

  const handleOnDiscard = () => {
    setFieldProperties(field);
    setSelectedFieldId(null);
  };

  const handleOnReOrdering = (dropZone: any, item: any) => {
    if (item.type === DragTypes.MULTI_CHOICE) {
      const drag_index = Number(item.index);
      const hover_index = Number(dropZone.index);

      if (drag_index !== hover_index && hover_index > -1) {
        // handle sorting of fields
        const updatedChoices = arrayMove(choices, drag_index, hover_index);

        setFieldProperties({
          ...fieldProperties,
          choices: updatedChoices,
        });
      }
    }
  };

  const checkFieldIsDirty = () => {
    const isDirty = !isEqual(fieldProperties, field);

    const updatedField = isDirty ? fieldProperties : {}; // if field is not dirty, then return empty object

    updateUnsaveTracker({ isDirty, field: updatedField });
  };

  const fieldName = capitalizeFirstLetter(name);
  const fieldType = capitalizeFirstLetter(type);

  const draggingStyles = isDragging
    ? { opacity: 0, background: '#fafafa', cursor: 'grabbing' }
    : { opacity: 1 };

  return (
    <AccordionItem
      value={fieldId}
      className='border bg-white rounded'
      ref={nodeRef}
      onBlur={checkFieldIsDirty}
    >
      <AccordionTrigger
        className='border-b px-4 py-4 hover:underline-offset-0 hover:bg-[#fafafa]'
        style={{ height: '52px', ...draggingStyles }}
        defaultChecked={selectedFieldId === fieldId}
        onClick={handleOnBlockFieldClick}
      >
        <div className='flex justify-between items-center'>
          <GripVerticalIcon color={platinum_color} />
          <LayoutListIcon
            className='border rounded mx-2'
            color={platinum_color}
          />
          <span className='text-sm' style={accordionTriggerStyle}>
            {fieldName || fieldType}
          </span>
        </div>
      </AccordionTrigger>
      <AccordionContent className='px-4 py-2 border-t'>
        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='name'
            label='Label for customers'
            value={name}
            onChange={handleOnUpdate}
            required
            error={errors['name']}
            onBlur={handleOnBlur}
          />

          <InputText
            name='label_agent'
            label='Label for agents'
            value={label_agent}
            onChange={handleOnUpdate}
          />
        </div>

        <div className={`flex justify-between mt-4 ${isSmall ? 'gap-2' : ''}`}>
          <InputText
            name='placeholder'
            label='Placeholder'
            value={placeholder}
            onChange={handleOnUpdate}
            placeholder='Enter placeholder'
          />

          <InputText
            name='help_text'
            label='Help Text'
            value={help_text}
            onChange={handleOnUpdate}
            placeholder='Enter help text'
          />
        </div>
      </AccordionContent>
      <AccordionContent className='px-2 py-2'>
        <LabelSeparator label='Option' />
        {choices.map((choice: any, index: number) => (
          <Fragment key={index}>
            <DropZone
              data={{
                index: `${index}`,
                childrenCount: choices.length,
              }}
              onDrop={handleOnReOrdering}
              style={{ display: 'flex', width: '542px', marginLeft: 'auto' }}
            />
            <Choice
              choice={choice}
              index={index}
              onDeleteChoice={handleOnDeleteChoice}
            />
          </Fragment>
        ))}

        <DropZone
          data={{
            index: `${choices?.length}`,
            childrenCount: choices?.length,
          }}
          onDrop={handleOnReOrdering}
          style={{ display: 'flex', width: '542px', marginLeft: 'auto' }}
        />

        <div
          className={`flex justify-between mt-1 mb-2 ${
            currChoice.error ? 'border-red-600' : ''
          }`}
          style={{ marginLeft: '2.5rem' }}
        >
          <InputText
            name='choices'
            value={currChoice.value}
            onChange={handleOnChoiceUpdate}
            onBlur={handleOnBlurChoice}
            placeholder='Add options'
            // required
            style={{ width: '100%' }}
            error={!!currChoice.error}
          />

          <Button
            variant='outline'
            size='icon'
            className='ml-3'
            style={{
              background: 'transparent',
              cursor: currChoice.error ? 'not-allowed' : 'pointer',
            }}
            onClick={handleOnAddChoice}
            title={
              currChoice.error ? 'Enter value to add an option' : 'Add Option'
            }
          >
            <PlusIcon className='h-4 w-4' />
          </Button>
        </div>

        {currChoice.error ? (
          <span
            className='mt-3 mb-2 text-red-600'
            style={{ marginLeft: '2.2rem' }}
          >
            {currChoice.error}
          </span>
        ) : null}
      </AccordionContent>

      <AccordionContent className='px-2 py-2 border-t h-[60px]'>
        <div className='flex justify-between'>
          <div className='flex'>
            <div className='flex items-center space-x-2'>
              <Checkbox
                id='is_required'
                className='rd-input-style-checkbox'
                name='is_required'
                checked={is_required}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_required');
                }}
              />
              <label
                htmlFor='is_required'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              >
                Set as required
              </label>
            </div>

            <div className='flex items-center space-x-2 ml-4'>
              <Checkbox
                id='is_hidden'
                className='rd-input-style-checkbox'
                name='is_hidden'
                checked={is_hidden}
                onCheckedChange={() => {
                  handleOnCheckboxChange('is_hidden');
                }}
              />
              <label
                htmlFor='is_hidden'
                className='text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70'
              >
                Set as hidden
              </label>
            </div>
          </div>

          <div className='flex'>
            <DeleteCofirmationDialog
              type={name}
              onDelete={handleOnRemove}
              name={fieldName}
            />

            <DiscardCofirmationDialog onDiscard={handleOnDiscard} />

            <SaveCofirmationDialog
              onClick={handleOnSaveOrCreate}
              isNewField={isNewField}
            />
          </div>
        </div>
      </AccordionContent>
    </AccordionItem>
  );
};

export default SingleSelect;
