import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from 'libraryV2/ui/sheet';
import DatalabForm from './DatalabForm';
import { TDatalabFormModes } from 'pages/datalab/export';

interface SiteSheetProps {
  onClose: () => void;
  header: string;
  isOpen: boolean;
  datalabEntries: any;
  datalabShape: any;
  mode: TDatalabFormModes;
}

const DatalabSideSheet = ({
  onClose,
  header,
  isOpen = false,
  datalabEntries,
  datalabShape,
  mode,
}: SiteSheetProps) => {
  return (
    <Sheet open={isOpen} onOpenChange={onClose}>
      <SheetContent
        side='right'
        className='bg-white sm:max-w-[470px] sm:w-[470px] overflow-auto'
      >
        <SheetHeader>
          <SheetTitle className='text-zinc-900'>{header}</SheetTitle>
        </SheetHeader>
        <div className='mt-4'>
          {mode === 'CREATE' ? (
            <DatalabForm
              onClose={onClose}
              formAction='CREATE'
              datalabShape={datalabShape}
            />
          ) : (
            <DatalabForm
              onClose={onClose}
              formAction={mode}
              datalabEntries={datalabEntries}
              datalabShape={datalabShape}
            />
          )}
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default DatalabSideSheet;
