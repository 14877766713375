import { uniqWith } from 'lodash';

export interface TemplateVariable {
  position: number;
  value: string;
}

export const hasSingleValidVariablePattern = (text: string): boolean => {
  //To-Do: handle edge cases like invalid variable foramts,valid format but not 1
  const regexPattern = /(?<!{){{[1]}}(?!})/g; //search for pattern: {{1}}
  const found = text.search(regexPattern);
  return found === -1 ? false : true;
};

export const getValidVariablePatternList = (text: string) => {
  const str = text;
  const pattern = /(?<!{){{\d+}}(?!})/g;
  const matches = [...str.matchAll(pattern)];
  return matches.map((v: RegExpMatchArray) => {
    const index = v?.index ?? 0;
    return {
      position: index + 2,
      value: str.slice(index + 2, str.indexOf('}', index + 2)),
    };
  });
};

export const checkNewTemplateBodyInput = (text: string, position: number) => {
  //TO-DO: this function currently is not used.it contains logic to prevent wrong input in a variable block
  const openingIdx = text.lastIndexOf('{{', position);
  const closingIdx = text.indexOf('}}', position);
  const variable = text.slice(openingIdx + 2, closingIdx);

  if (openingIdx !== -1 && closingIdx !== -1) {
    let isPolluted = false;
    if (Number(variable) === 0) {
      return true;
    }
    for (let i = 0; i < variable.length; i++) {
      if (variable.charCodeAt(i) < 49 || variable.charCodeAt(i) > 57) {
        isPolluted = true;
        break;
      }
    }
    return isPolluted;
  }

  return true;
};

export const analyzeTemplateBody = ({ bodyText = '', position = 0 }) => {
  const validPatternList = getValidVariablePatternList(bodyText);

  validPatternList.sort((a, b) => Number(a.value) - Number(b.value));

  const validVariableList: TemplateVariable[] = [];
  let invalidVariablelist: TemplateVariable[] = [];
  let diff = 0;
  let maxVariable = 0;

  if (validPatternList.length) {
    let firstVariableValue = Number(validPatternList[0].value);
    if (firstVariableValue === 1) {
      maxVariable = Number(validPatternList[0].value);
      validVariableList.push(validPatternList[0]);
    } else {
      invalidVariablelist.push(validPatternList[0]);
      invalidVariablelist = [...validPatternList];
      validPatternList.splice(0);
    }
  }

  let lastValidIndex = 0;

  for (let i = 1; i < validPatternList.length; i++) {
    diff =
      Number(validPatternList[i].value) - Number(validPatternList[i - 1].value);
    if (diff === 0 || diff === 1) {
      validVariableList.push(validPatternList[i]);
      lastValidIndex += 1;
      maxVariable = Math.max(Number(validVariableList[i]?.value), maxVariable);
    } else {
      break;
    }
  }

  const uniqueVariableList = uniqWith(
    validVariableList,
    (a, b) => a.value === b.value
  ).reduce((acc, current) => {
    if (acc[current.value]) {
      return acc;
    }
    acc[current.value] = '';
    return { ...acc };
  }, {});

  invalidVariablelist.splice(
    invalidVariablelist.length,
    0,
    ...validPatternList.slice(lastValidIndex + 1)
  );

  return {
    validPatternList,
    uniqueVariableList,
    validVariableList,
    invalidVariablelist,
    maxVariable,
  };
};

export const handleTemplateBodyVariableAutoComplete = ({
  currentText = '',
  previousText = '',
  position = 0,
  nextCount = 1,
}) => {
  const inputActionType =
    currentText.length < previousText.length ? 'delete' : 'add';

  const firstPart = currentText.slice(0, position);
  const lastPart = currentText.slice(position);
  let middlePart = '';
  if (inputActionType === 'add') {
    if (
      firstPart.endsWith('{{') &&
      !lastPart.startsWith('{') &&
      !firstPart.endsWith('{{{')
    ) {
      middlePart += nextCount + '}}';
    }
  }
  const finalText = firstPart + middlePart + lastPart;
  return finalText;
};

export const getColoredVariableBlock = (
  text: string,
  variable: string,
  isValid: boolean = false
) => {
  const goodVariableStyle = `<span class="bg-indigo-50 text-indigo-400 rounded-sm">`;
  const badVariableStyle = `<span class="bg-red-50 text-red-400 rounded-sm">`;
  const variableFormat = '{{' + variable + '}}';
  if (isValid) {
    return `${goodVariableStyle}${text ? text : variableFormat}</span>`;
  }
  return `${badVariableStyle}${text ? text : variableFormat}</span>`;
};

export const replaceAndHighlightVariableInText = (
  text: string,
  variableObj: any
) => {
  const testResult = analyzeTemplateBody({
    bodyText: text,
  });
  const maxVariable = testResult.maxVariable;
  const coloredPattern = [];
  if (testResult.validVariableList.length) {
    testResult.validPatternList.sort((a, b) => a.position - b.position);
    if (Number(testResult.validPatternList[0].value) <= maxVariable) {
      coloredPattern.push({
        pattern: text.slice(
          0,
          testResult.validPatternList[0].position +
            testResult.validPatternList[0].value.length +
            2
        ),
        value: testResult.validPatternList[0].value,
        isValid: true,
      });
    }
    for (let i = 1; i < testResult.validPatternList.length; i++) {
      let currentPattern = testResult.validPatternList[i];
      let previousPattern = testResult.validPatternList[i - 1];
      let start = previousPattern.position + 2 + previousPattern.value.length;
      let end = currentPattern.position + 2 + currentPattern.value.length;
      coloredPattern.push({
        pattern: text.slice(start, end),
        isValid: Number(currentPattern.value) <= maxVariable,
        value: currentPattern.value,
      });
    }
    let lastPart = '';
    const lastVariable =
      testResult.validPatternList[testResult.validPatternList.length - 1];
    if (lastVariable.position !== text.length) {
      lastPart = text.slice(
        lastVariable.position + lastVariable.value.length + 2
      );
    }
    coloredPattern.push(lastPart);
  } else {
    coloredPattern.push(text);
  }

  let result = coloredPattern.map((current) => {
    if (typeof current === 'string') {
      return current;
    }
    const patternStartIndex = current.pattern.lastIndexOf('{{');
    const sampleValue = variableObj[current.value];
    let variable = current.value;
    let nonPatternText = current.pattern.slice(0, patternStartIndex);
    return (
      nonPatternText +
      getColoredVariableBlock(sampleValue, variable, current.isValid)
    );
  });

  return result.join('');
};

export function formatWhatsappText(text: string) {
  let bold = /(?:[\s|\n]\*+)(?:(?!\s))((?:(?!\n|\*).)+)(?:\*+)/g;
  let italic = /(?:[\s|\n]_+)(?:(?!\s))((?:(?!\n|_).)+)(?:_+)/g;
  let strikeThrough = /(?:[\s|\n]~+)(?:(?!\s))((?:(?!\n|~).)+)(?:~+)/g;

  return text
    .replace(bold, `<b> $1</b>`)
    .replace(italic, '<i> $1</i>')
    .replace(strikeThrough, '<s> $1</s>');
}
