import { useCallback, useEffect, useState } from 'react';
import WhatsappAnalyticsLogsHeader from './WhatsappAnalyticsLogHeader';
import useWhatsappAnalyticsLogs from '../../../hooks/useWhatsappAnalyticsLogs';
import { useSelector } from 'react-redux';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from 'libraryV2/ui/dropdown-menu';
import CirclePlusIcon from './../../../../../assets/images/CirclePlusIcon.svg';
import CloseIcon from './../../../../../assets/images/CloseIcon.svg';
import useTranslation from 'components/customHooks/useTranslation';
import {
  AnalyticsLogsSourceData,
  AnalyticsLogsStatusData,
  WhatsappTemplateAnalyticsLogsApiDataLimit,
} from 'pages/reporting/utility/content';
import useDebounce from 'components/customHooks/useDebounce';
import dayjs from 'dayjs';
import { LinearLoaderComponent } from 'utilities/utils';
import Pagination from 'pages/reporting/component/CommonComponent/Pagination';
import EmptyReportTemplateTableIcon from '../../../../../assets/images/EmptyReportTemplateTableIcon.svg';
import EmptySearchIcon from '../../../../../assets/images/EmptyReportTemplateTableIcon.svg';
import { SideSheet } from 'library';

const headerTitle = [
  'Timestamp',
  'Customer name',
  'Phone number',
  'Status',
  'Sources',
];

const formatDateRange = (startDate: string, endDate: string) => {
  const formattedStartDate = dayjs(startDate).format('Do MMM');
  const formattedEndDate = dayjs(endDate).format('Do MMM, YYYY');
  return `${formattedStartDate} - ${formattedEndDate}`;
};

const AnalyticsLogs = () => {
  const { t } = useTranslation();
  const [searchKeyword, setSearchKeyword] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const [selectedTypes, setSelectedTypes] = useState<string[]>([]);
  const [templateAnalyticsTableOffset, setTemplateAnalyticsTableOffset] =
    useState<number>(0);
  const {
    dateRange,
    templateAnalyticsLogsTotalData,
    templateAnalyticsLogs,
    selectedLogsData,
  } = useSelector((state: any) => state.reporting);
  const { fetchTemplateAnalyticsLogs: isFetchTemplateAnalyticsLogsLoading } =
    useSelector((state: any) => state.loading.effects.reporting);
  const { updateSelectedLogData, fetchWhatsappTemplateAnalyticsLogsData } =
    useWhatsappAnalyticsLogs();

  const searchDebounce = useDebounce(searchKeyword, 700);

  const fetchAnalyticsLogs = useCallback(() => {
    fetchWhatsappTemplateAnalyticsLogsData({
      searchValue: searchDebounce, // Use debounced value
      statusFilter: selectedStatus,
      sourceFilter: selectedTypes,
      offset: templateAnalyticsTableOffset,
    });
    // You no longer need to suppress eslint warnings
    //eslint-disable-next-line
  }, [
    searchDebounce,
    selectedStatus,
    selectedTypes,
    templateAnalyticsTableOffset,
  ]);

  useEffect(() => {
    fetchAnalyticsLogs();
  }, [fetchAnalyticsLogs]);

  // Function to handle checkbox changes
  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    filterType: 'status' | 'type'
  ) => {
    const { id, checked } = event.target;

    if (filterType === 'status') {
      setSelectedStatus((prev) =>
        checked ? [...prev, id] : prev.filter((item) => item !== id)
      );
    } else if (filterType === 'type') {
      setSelectedTypes((prev) =>
        checked ? [...prev, id] : prev.filter((item) => item !== id)
      );
    }
    setTemplateAnalyticsTableOffset(0);
  };

  // Function to clear all filters
  const clearFilters = (filterType: 'status' | 'type') => {
    if (filterType === 'status') {
      setSelectedStatus([]);
    } else {
      setSelectedTypes([]);
    }
    setTemplateAnalyticsTableOffset(0);
  };

  const getTextColor = (value: string) => {
    if (value === 'delivered') {
      return 'text-green-800';
    } else if (value === 'failed') {
      return 'text-red-800';
    } else {
      return 'text-gray-800';
    }
  };
  const getBgColor = (value: string) => {
    if (value === 'approved') {
      return 'bg-green-500';
    } else if (value === 'rejected') {
      return 'bg-red-500';
    } else {
      return 'bg-gray-500';
    }
  };

  const removeSelectedFilterItem = (
    type: 'status' | 'types',
    value: string
  ) => {
    if (type === 'status' && selectedStatus.includes(value)) {
      const updatedData = selectedStatus.filter(
        (item: string) => item !== value
      );
      setSelectedStatus(updatedData ?? []);
    } else {
      const updatedData = selectedTypes.filter(
        (item: string) => item !== value
      );
      setSelectedTypes(updatedData ?? []);
    }
    setTemplateAnalyticsTableOffset(0);
  };

  const renderStatusView = (
    deliveredStatus: boolean,
    failedStatus: boolean
  ) => {
    if (deliveredStatus === true) {
      return (
        <div className='flex items-center border border-[#DFDFE2] py-0.5 px-2 rounded-md'>
          <div className={`w-1.5 h-1.5 rounded-full mr-1 bg-green-500`}></div>
          <div className='text-green-500'>Delivered</div>
        </div>
      );
    } else if (failedStatus === true) {
      return (
        <div className='flex items-center border border-[#DFDFE2] py-0.5 px-2 rounded-md'>
          <div className={`w-1.5 h-1.5 rounded-full mr-1 bg-red-500`}></div>
          <div className='text-red-500'>Failed</div>
        </div>
      );
    } else {
      return (
        <div className='flex items-center border border-[#DFDFE2] py-0.5 px-2 rounded-md'>
          <div className={`w-1.5 h-1.5 rounded-full mr-1 bg-gray-500`}></div>
          <div className='text-black'>Pending</div>
        </div>
      );
    }
  };

  const renderShideSeetView = () => {
    return (
      <div className='p-6 flex flex-col gap-3'>
        {Object.keys(selectedLogsData).map((item: any) => (
          <div className='p-3 border rounded-md ' key={item}>
            <p className='text-gray-500 font-medium text-sm mb-1.5'>{item}</p>
            <p className='text-[#18181B] text-sm font-medium overflow-auto break-all whitespace-pre-wrap'>
              {selectedLogsData[item]}
            </p>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div>
      <WhatsappAnalyticsLogsHeader />
      <div className='p-6'>
        <div className='mb-4'>
          <h3 className='text-[#111827] text-base font-medium leading-6 mb-1'>
            Templates Activity Logs
          </h3>
          <p className='text-[#6B7280] text-xs mb-0'>
            Showing activity logs from{' '}
            {formatDateRange(dateRange[0].startDate, dateRange[0].endDate)}
          </p>
        </div>
        <div className='mb-4 flex justify-between items-center'>
          <div className='flex items-center'>
            <div className='relative rounded-md shadow-sm h-full mr-2'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                <MagnifyingGlassIcon
                  className='w-5 h-5 text-gray-400'
                  aria-hidden='true'
                />
              </div>
              <input
                type='search'
                name='search'
                id='search'
                className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary focus:border-primary sm:text-sm'
                placeholder={t('Search')}
                value={searchKeyword}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSearchKeyword(e.target.value);
                  setTemplateAnalyticsTableOffset(0);
                }}
              />
            </div>
            <div className='mr-2'>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className='flex items-center px-3 py-2.5 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                    <div>
                      <img src={CirclePlusIcon} alt='' />
                    </div>
                    <p className='text-sm font-medium ml-1'>Status</p>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  side='bottom'
                  align='start'
                  className='shadow-lg bg-white z-30 rounded-md p-0'
                >
                  <div className=''>
                    <div className='py-2 px-1'>
                      <div className='flex flex-col gap-3 p-1.5'>
                        {AnalyticsLogsStatusData.map(({ id, label }) => (
                          <div className='flex items-center' key={id}>
                            <input
                              type='checkbox'
                              readOnly
                              id={id}
                              checked={selectedStatus.includes(id)}
                              onChange={(event) =>
                                handleCheckboxChange(event, 'status')
                              }
                              className={`${
                                selectedStatus.includes(id)
                                  ? 'text-green-500 focus:ring-green-700 focus:border-primary'
                                  : ''
                              } flex items-center pl-3 rounded w-4 h-4 border border-[#18181B] outline-none`}
                            />
                            <label htmlFor={id} className='ml-2'>
                              <div
                                className={`border border-[#DFDFE2] ${getTextColor(
                                  id
                                )}  py-0.5 px-2 rounded-md text-sm font-medium flex items-center`}
                              >
                                <span
                                  className={`w-1.5 h-1.5 rounded-full ${getBgColor(
                                    id
                                  )}  mr-1`}
                                ></span>{' '}
                                {label}
                              </div>
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {selectedStatus.length > 0 && (
                      <div
                        className='border-t p-2 flex justify-center cursor-pointer'
                        onClick={() => clearFilters('status')}
                      >
                        <p className=''>Clear Filters</p>
                      </div>
                    )}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className='mr-2'>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <div className='flex items-center px-3 py-2.5 rounded-md border border-dotted border-[#DFDFE2] cursor-pointer'>
                    <div>
                      <img src={CirclePlusIcon} alt='' />
                    </div>
                    <p className='text-sm font-medium ml-1'>Sources</p>
                  </div>
                </DropdownMenuTrigger>
                <DropdownMenuContent
                  side='bottom'
                  align='start'
                  className='shadow-lg bg-white z-30 rounded-md p-0'
                >
                  <div className=''>
                    <div className='py-2 px-1'>
                      <div className='flex flex-col gap-3 p-1.5'>
                        {AnalyticsLogsSourceData.map(({ id, label }) => (
                          <div className='flex items-center' key={id}>
                            <input
                              type='checkbox'
                              id={id}
                              checked={selectedTypes.includes(id)}
                              onChange={(event) =>
                                handleCheckboxChange(event, 'type')
                              }
                              className='w-4 h-4 border-gray-300 rounded focus:ring-primary text-primary outline-none'
                            />
                            <label
                              htmlFor={id}
                              className='ml-2 text-sm text-[#18181B] capitalize'
                            >
                              {label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                    {selectedTypes.length > 0 && (
                      <div
                        className='border-t p-2 flex justify-center cursor-pointer'
                        onClick={() => clearFilters('type')}
                      >
                        <p className=''>Clear Filters</p>
                      </div>
                    )}
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            {(selectedStatus.length > 0 || selectedTypes.length > 0) && (
              <div
                className='flex items-center px-3 py-2.5 rounded-md bg-[#F4F4F5] cursor-pointer'
                onClick={() => {
                  setSelectedStatus([]);
                  setSelectedTypes([]);
                }}
              >
                <div>
                  <img src={CloseIcon} alt='' />
                </div>
                <p className='text-sm font-medium ml-1'>Clear Filter</p>
              </div>
            )}
          </div>
        </div>
        {(selectedStatus.length > 0 || selectedTypes.length > 0) && (
          <div className='mb-4 flex items-center'>
            {selectedStatus.length > 0 && (
              <div className='flex items-center'>
                <div className='text-xs text-[#71717A] mr-1'>Status:</div>
                <div className='flex items-center'>
                  <div className='bg-[#F4F4F5] py-1 px-2 rounded-md flex items-center justify-between'>
                    <span className='mr-2 capitalize text-xs font-medium'>
                      {selectedStatus[0]}
                    </span>
                    <div
                      className='cursor-pointer'
                      onClick={() =>
                        removeSelectedFilterItem('status', selectedStatus[0])
                      }
                    >
                      <img src={CloseIcon} alt='' />
                    </div>
                  </div>
                  {selectedStatus.length > 1 && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <div className='ml-1 bg-[#F4F4F5] py-1 px-2 rounded-md text-xs font-medium'>
                          +{selectedStatus.length - 1}
                        </div>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        side='bottom'
                        align='start'
                        className='shadow-lg bg-white z-30 rounded-md p-2'
                      >
                        <div className='flex flex-col gap-2'>
                          {selectedStatus
                            .slice(1)
                            .map((item: string, index: number) => (
                              <div key={index}>
                                <div className='bg-[#F4F4F5] py-1 px-2 rounded-md inline-flex'>
                                  <span className='mr-2 capitalize text-xs font-medium mr-1'>
                                    {item}
                                  </span>
                                  <div
                                    className='cursor-pointer mt-0.5'
                                    onClick={() =>
                                      removeSelectedFilterItem('status', item)
                                    }
                                  >
                                    <img src={CloseIcon} alt='' />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              </div>
            )}
            {selectedTypes.length > 0 && (
              <div className='flex items-center ml-3'>
                <div className='text-xs text-[#71717A] mr-1'>Type:</div>
                <div className='flex items-center'>
                  <div className='bg-[#F4F4F5] py-1 px-2 rounded-md flex items-center justify-between'>
                    <span className='mr-2 capitalize text-xs font-medium'>
                      {selectedTypes[0]}
                    </span>
                    <div
                      className='cursor-pointer'
                      onClick={() =>
                        removeSelectedFilterItem('types', selectedTypes[0])
                      }
                    >
                      <img src={CloseIcon} alt='' />
                    </div>
                  </div>
                  {selectedTypes.length > 1 && (
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <div className='ml-1 bg-[#F4F4F5] py-1 px-2 rounded-md text-xs font-medium'>
                          +{selectedTypes.length - 1}
                        </div>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        side='bottom'
                        align='start'
                        className='shadow-lg bg-white z-30 rounded-md p-2'
                      >
                        <div className='flex flex-col gap-2'>
                          {selectedTypes
                            .slice(1)
                            .map((item: string, index: number) => (
                              <div key={index}>
                                <div className='bg-[#F4F4F5] py-1 px-2 rounded-md inline-flex'>
                                  <span className='mr-2 capitalize text-xs font-medium mr-1'>
                                    {item}
                                  </span>
                                  <div
                                    className='cursor-pointer mt-0.5'
                                    onClick={() =>
                                      removeSelectedFilterItem('types', item)
                                    }
                                  >
                                    <img src={CloseIcon} alt='' />
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        <>
          {isFetchTemplateAnalyticsLogsLoading && <LinearLoaderComponent />}
          {!isFetchTemplateAnalyticsLogsLoading && (
            <div className='flex flex-col overflow-x-auto overflow-y-hidden border rounded-md'>
              <div className='inline-block min-w-full align-middle'>
                {templateAnalyticsLogs.length > 0 && (
                  <>
                    <table className='w-full min-h-full bg-white rounded-lg'>
                      <thead className='border-b'>
                        <tr>
                          {headerTitle.map((head) => (
                            <th
                              scope='col'
                              className='px-5 py-3 text-sm font-medium tracking-wider ltr:text-left rtl:text-right text-gray-500 capitalize'
                            >
                              {t(head)}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-200'>
                        {templateAnalyticsLogs.map(
                          (template: any, index: number) => (
                            <tr key={index} className='px-6 py-4 '>
                              <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right'>
                                {dayjs(template?.created_at * 1000).format(
                                  'MMM D, YYYY'
                                )}
                              </td>
                              <td className=' px-6 py-4 text-sm text-gray-900 whitespace-nowrap rtl:text-right'>
                                {String(template?.customer_full_name).length ===
                                0
                                  ? '--'
                                  : template?.customer_full_name ?? '--'}
                              </td>
                              <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template.customer_phone ?? '--'}
                              </td>
                              <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right'>
                                <div className='inline-block'>
                                  {renderStatusView(
                                    template?.is_delivered,
                                    template?.is_failed
                                  )}
                                </div>
                              </td>
                              <td className='px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap rtl:text-right'>
                                {template?.source ?? '--'}
                              </td>
                              <td
                                className='px-6 py-4 text-sm font-medium text-blue-500 whitespace-nowrap rtl:text-right capitalize cursor-pointer'
                                onClick={() => updateSelectedLogData(template)}
                              >
                                View Details
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </table>
                    {!isFetchTemplateAnalyticsLogsLoading &&
                      templateAnalyticsLogs?.length !== 0 && (
                        <Pagination
                          offset={templateAnalyticsTableOffset}
                          tableDataLength={templateAnalyticsLogs?.length}
                          total={templateAnalyticsLogsTotalData}
                          limit={WhatsappTemplateAnalyticsLogsApiDataLimit}
                          handleNextButton={() =>
                            setTemplateAnalyticsTableOffset(
                              templateAnalyticsTableOffset +
                                WhatsappTemplateAnalyticsLogsApiDataLimit
                            )
                          }
                          handlePreviousButton={() =>
                            setTemplateAnalyticsTableOffset(
                              templateAnalyticsTableOffset -
                                WhatsappTemplateAnalyticsLogsApiDataLimit
                            )
                          }
                        />
                      )}
                  </>
                )}
                {!isFetchTemplateAnalyticsLogsLoading &&
                  templateAnalyticsLogs.length === 0 &&
                  searchKeyword.length === 0 && (
                    <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white'>
                      <img
                        src={EmptyReportTemplateTableIcon}
                        alt={'empty table'}
                        className={'w-32 mt-8'}
                      />
                      <div className='mt-6 mb-8 text-center'>
                        <p className='text-[#18181B] text-lg font-semibold mb-2'>
                          {t(t('No Reports Found'))}
                        </p>
                        <p className='text-[#71717A] font-medium text-sm w-[290px] mx-auto'>
                          {t(
                            'Start sending templates to see your activity and performance metrics here.'
                          )}
                        </p>
                      </div>
                    </div>
                  )}
                {!isFetchTemplateAnalyticsLogsLoading &&
                  searchKeyword.length !== 0 &&
                  (templateAnalyticsLogs.length === 0 ||
                    templateAnalyticsLogs === null) && (
                    <div className='flex flex-col items-center justify-center min-w-full min-h-full p-8 bg-white shadow'>
                      <img
                        src={EmptySearchIcon}
                        alt={'empty table'}
                        className={'w-32 mt-8'}
                      />
                      <div className='mt-6 mb-8 text-center'>
                        <p className='text-[#18181B] text-lg font-semibold mb-2'>
                          {t(t('No Results Found'))}
                        </p>
                        <p className='text-[#71717A] font-medium text-sm mx-auto'>
                          {t('Please search with different keyword. ')}
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
        </>
      </div>
      {selectedLogsData !== null && (
        <SideSheet
          open={selectedLogsData !== null}
          handleClose={() => updateSelectedLogData(null)}
          handleConfirm={() => updateSelectedLogData(null)}
          title='View Details'
          hasFooter={false}
        >
          {renderShideSeetView()}
        </SideSheet>
      )}
    </div>
  );
};

export default AnalyticsLogs;
