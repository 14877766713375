import React from 'react';
import EditMenu from './EditMenu';
import ChannelSetupStatus from '../../common/pages/ChannelSetupStatus';

import { Link, SideSheet } from '../../../../../library';
import {
  channelInfo,
  classNames,
  getAllMarketPlaceData,
} from '../../../../../utilities/utils';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'libraryV2/ui/tooltip';
import { channelListProps } from '../../../interface';
import useTranslation from 'components/customHooks/useTranslation';
import { WhatsappGreenTickIcon } from 'pages/integration/assets/icons/socialIcons';

interface Props {
  elementData: channelListProps;
  handleScriptCopy: () => void;
  handleChannelEdit: () => void;
  handleChannelRemove: () => void;
  handleChannelReconnect: () => void;
  handleChannelDisconnect: () => void;
  handleIsPublishedOnChange: (value: boolean, id: string) => Promise<boolean>;
  handleChannelClone: () => void;
  handleManageTemplate: () => void;
  handleTicketSettings: (payload: string | null) => void;
  handleLivechatMigration: (channelId: string) => void;
  handleAccountInfo: () => void;
  ecommerceType?: string;
}

const ChannelCard: React.FC<Props> = ({
  elementData,
  handleIsPublishedOnChange,
  handleChannelDisconnect,
  handleChannelRemove,
  handleChannelEdit,
  handleScriptCopy,
  handleChannelReconnect,
  handleChannelClone,
  handleManageTemplate,
  handleTicketSettings,
  handleAccountInfo,
  ecommerceType,
  handleLivechatMigration,
}) => {
  const [openSideSheet, setOpenSideSheet] = React.useState(false);
  const channelData = channelInfo(elementData.platform_type);
  const { t } = useTranslation();

  const getChannelTitle = () => {
    if (elementData.title.length === 0) return '---';
    else
      return elementData?.title?.length > 18
        ? t(elementData?.title).substring(0, 18) + '...'
        : t(elementData?.title);
  };

  const getConnectedPageName = () => {
    return !!elementData.name && elementData?.name?.length > 18
      ? elementData?.name.substring(0, 18) + '...'
      : elementData?.name;
  };

  function showAlertForInvalidToken(elementData: channelListProps) {
    return !!elementData?.is_connected && !!elementData?.is_token_invalid;
  }

  return (
    <div className='p-5 border border-gray-200 rounded w-full'>
      <div className='flex justify-between align-top'>
        <div className='flex gap-2.5 align-top'>
          <img src={channelData.image} className='w-9 h-9' alt={'icon_box'} />
          <div className='mt-[-17px]'>
            <div className='flex font-medium text-base align-center mt-3 mb-1 capitalize'>
              {getChannelTitle()}{' '}
              {elementData?.type === 'whatsapp_bsp' &&
                Object.keys(elementData?.platform_meta).length > 0 && (
                  <div className='ml-1 flex items-center'>
                    {elementData?.platform_meta?.green_tick !== null && (
                      <div className='mr-1'>
                        <TooltipProvider>
                          <Tooltip>
                            <TooltipTrigger>
                              <WhatsappGreenTickIcon />
                            </TooltipTrigger>
                            <TooltipContent>
                              <div>This account is verified from Meta.</div>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      </div>
                    )}
                  </div>
                )}
              {showAlertForInvalidToken(elementData) && (
                <>
                  <span className='relative self-center group'>
                    <div className='invisible group-hover:visible '>
                      <span className='absolute bottom-[23px] w-[193px] text-xs text-white bg-gray-600 px-2 py-1 left-[-35px] rounded-[3px]'>
                        {t(
                          'Please reconnect your channel to generate a new token'
                        )}
                      </span>
                      <span className='absolute bottom-[17px] w-[0px] h-[0px] border-l-[7px] border-r-[7px] border-t-[10px] border-l-transparent border-r-transparent border-t-gray-600'></span>
                    </div>

                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='15'
                      height='14'
                      viewBox='0 0 17 16'
                      fill='none'
                    >
                      <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M5.92356 1.09882C6.68817 -0.26048 8.64526 -0.260481 9.40986 1.09882L14.9902 11.0194C15.7401 12.3526 14.7767 13.9999 13.247 13.9999H2.08641C0.556755 13.9999 -0.406673 12.3526 0.343257 11.0194L5.92356 1.09882ZM8.66663 11C8.66663 11.5523 8.21891 12 7.66663 12C7.11434 12 6.66663 11.5523 6.66663 11C6.66663 10.4477 7.11434 10 7.66663 10C8.21891 10 8.66663 10.4477 8.66663 11ZM7.66663 3C7.11434 3 6.66663 3.44772 6.66663 4V7C6.66663 7.55228 7.11434 8 7.66663 8C8.21891 8 8.66663 7.55228 8.66663 7V4C8.66663 3.44772 8.21891 3 7.66663 3Z'
                        fill='#F87171'
                      ></path>
                    </svg>
                  </span>
                </>
              )}
              &nbsp;&nbsp;&nbsp;
              <span
                className={classNames(
                  'px-2 py-0  text-xs text-gray-600 rounded-2xl border border-gray-400 cursor-pointer flex items-center',
                  elementData.platform_type === 'webchat' ? 'inline' : 'hidden'
                )}
                onClick={() => {
                  handleLivechatMigration(elementData.id);
                }}
              >
                {t('Migrate')}
              </span>
              {elementData.is_ecommerce_plugin_channel && (
                <span>
                  <img
                    src={
                      ecommerceType &&
                      getAllMarketPlaceData.find(
                        (platform) => platform.platform_type === ecommerceType
                      )?.icon
                    }
                    alt={'icon_box'}
                    className='ltr:ml-2 rtl:mr-2 w-5 h-5'
                  />
                </span>
              )}
            </div>
            <div className='flex gap-[3px]'>
              <p className='mt-1 text-sm text-gray-500 leading-none'>
                {elementData.is_connected ? t('Connected') : t('Disconnected')}
                {elementData.is_connected &&
                  !!elementData.name &&
                  elementData.name.length !== 0 && (
                    <>
                      <span>{` ${t('with')} `}</span>
                      {!!elementData.url && elementData.url.length ? (
                        <Link url={elementData.url} openInNewTab={true}>
                          {getConnectedPageName()}
                        </Link>
                      ) : (
                        <span className='text-gray-900 text-right'>
                          {getConnectedPageName()}
                        </span>
                      )}
                    </>
                  )}
              </p>
            </div>
          </div>
        </div>
        <EditMenu
          providerType={'Channel'}
          handleAccountInfo={handleAccountInfo}
          platformType={elementData.platform_type}
          isConnected={elementData.is_connected}
          handleEdit={handleChannelEdit}
          handleRemove={handleChannelRemove}
          handleDisconnect={handleChannelDisconnect}
          handleScriptCopy={handleScriptCopy}
          handleChannelReconnect={handleChannelReconnect}
          handleClone={handleChannelClone}
          handleManageTemplate={handleManageTemplate}
          handleTicketSettings={() => handleTicketSettings(elementData.id)}
          isEcommerce={elementData.is_ecommerce_plugin_channel}
        />
      </div>
      {/* <span className='border-b' /> */}
      {/* <div className='flex items-center justify-between mx-1 mt-4'>
        <span className='text-sm font-normal text-gray-500'>
          Publish Channel
        </span>
        <Switch
          checked={elementData.is_connected && elementData?.is_published}
          onChange={async () => {
            //this if condition is for whatsapp_messenger only
            if (
              elementData?.platform_type === 'whatsapp_messenger' &&
              !elementData.is_connected
            ) {
              setOpenSideSheet(true);
              return;
            }
            await handleIsPublishedOnChange(
              !elementData?.is_published,
              elementData.id
            );
          }}
        />
      </div> */}

      {/* this side sheet is for whatsapp_messenger only */}
      <SideSheet
        open={openSideSheet}
        hasMinimizeButton={false}
        handleClose={() => setOpenSideSheet(false)}
        closeOnExternalClick={false}
        disableConfirm={false}
        title=''
        confirmText={t('Exit')}
        cancelText={t('Back')}
        handleCancel={() =>
          console.log('will add when cancel button is implemented')
        }
        handleConfirm={() => setOpenSideSheet(false)}
        hasLeftActionElement={false}
        hideCancel={true}
      >
        <ChannelSetupStatus
          title={t('Your Whatsapp verification is pending.')}
          subTitle={t(
            'We will connect your channel as soon as all the work is done!'
          )}
        />
      </SideSheet>
    </div>
  );
};
export default ChannelCard;
