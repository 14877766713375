import { Button } from 'libraryV2/ui/button';
import { Form } from 'libraryV2/ui/form';
import { Loader2 } from 'lucide-react';
import React from 'react';
import { useForm } from 'react-hook-form';

import { useState, Fragment, SingleFormField, format } from './../../export';
import {
  formatDatalabFormEntryUpdate,
  formatDatalabNewEntryRequestPayload,
  getDefaultFieldsValue,
  mapFormValues,
} from 'pages/datalab/utils';
import useDatalab from 'pages/datalab/hooks/useDatalab';
import { toast } from 'libraryV2/ui/use-toast';

import { capitalize } from 'lodash';

interface EditViewEntriesProps {
  datalabEntries?: any;
  datalabShape: any;
  onClose: () => void;
  formAction: 'EDIT' | 'VIEW' | 'CREATE';
}

type DatalabFormProps = EditViewEntriesProps;

const DatalabForm: React.FC<DatalabFormProps> = ({
  formAction,
  datalabShape,
  datalabEntries,
  onClose,
}) => {
  const { submitDatalabNewEntries, labId, updateDatalabEntry } = useDatalab();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const isFormViewMode = formAction === 'VIEW';
  const isFormEditMode = formAction === 'EDIT';
  const isFormCreateMode = formAction === 'CREATE';

  const defaultFormValues = getDefaultFieldsValue(datalabShape?.lab_fields);

  const finalFormValues = isFormCreateMode
    ? defaultFormValues
    : mapFormValues(datalabEntries, defaultFormValues);

  const datalabHookForm = useForm({
    mode: 'onChange',
    defaultValues: finalFormValues,
  });

  const onSubmit = async (values: Record<string, any>) => {
    setIsSubmitLoading(true);
    if (isFormCreateMode) {
      const requestPayload = formatDatalabNewEntryRequestPayload(values);
      submitDatalabNewEntries({ formId: labId, payload: requestPayload })
        .then((res) => {
          onClose();
          toast({
            description: 'Successfully added new entry.',
          });
        })
        .catch((er) => {
          toast({
            description: 'Failed to add new entry!',
          });
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    } else if (isFormEditMode) {
      const payload = formatDatalabFormEntryUpdate(
        structuredClone(datalabEntries),
        values
      );
      //@ts-ignore
      payload['labId'] = labId;
      updateDatalabEntry({
        formId: labId,
        fieldId: datalabEntries.id,
        payload: payload,
      })
        .then(() => {
          onClose();
          toast({
            // @ts-expect-error Just bad typing
            title: <p className='text-green-400'>Saved Changes</p>,
            description: 'All changes has been saved successfully.',
          });
        })
        .catch((er) => {
          toast({
            // @ts-expect-error Just bad typing
            title: <p className='text-green-400'>Failed to update!</p>,
            description: new Error(er).message,
          });
        })
        .finally(() => {
          setIsSubmitLoading(false);
        });
    }
  };

  const renderFormFields = () => {
    const fields = datalabShape?.lab_fields;
    if (!fields || !Array.isArray(fields) || fields.length === 0) {
      return null;
    }
    return fields.map((field) => {
      return (
        <Fragment key={field.id}>
          <SingleFormField
            formActionType={formAction}
            data={field}
            valuePath=''
          />
        </Fragment>
      );
    });
  };

  const renderDataLabForm = () => (
    <Form {...datalabHookForm}>
      <form
        onSubmit={datalabHookForm.handleSubmit(onSubmit)}
        className='space-y-3'
      >
        {renderFormFields()}
        <FormSubmitButton
          formActionType={formAction}
          isLoading={isSubmitLoading}
          name={datalabShape.button.name}
          font_color={datalabShape.button?.font_color}
          background_color={datalabShape.button?.background_color}
        />
      </form>
    </Form>
  );
  const renderDatalabViewForm = () => {
    return (
      <div className='flex flex-col gap-4'>
        <div className='flex flex-col gap-3'>
          <h5 className='font-medium leading-5 text-normal text-zinc-900'>
            General Details
          </h5>
          <div
            id='container'
            className='bg-gray-100 border border-dashed rounded-md'
          >
            {[
              'id',
              'source',
              'created_at',
              'created_by',
              'last_updated_at',
              'last_updated_by',
            ].map((v, idx) => {
              const currentValue = datalabEntries[v];
              const timedValue =
                v.endsWith('_at') && parseInt(currentValue)
                  ? format(
                      new Date(parseInt(currentValue) * 1000),
                      'MMM d, yyyy h:mm a'
                    )
                  : null;
              const infoValule =
                typeof currentValue !== 'string'
                  ? currentValue?.toString()
                  : currentValue;
              return (
                <div key={v + idx} className='grid grid-cols-2 p-2'>
                  <div className='flex justify-between pr-3'>
                    <span className='text-zinc-400'>
                      {capitalize(v.replaceAll('_', ' '))}
                    </span>
                    <span className='w-fit'>:</span>
                  </div>
                  <span className='text-left text-zinc-900'>
                    {timedValue ? timedValue : infoValule}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <div className='flex flex-col gap-3'>
          <h3 className='font-medium leading-5 text-normal text-zinc-900'>
            Form Entries
          </h3>
          <div className=''>{renderDataLabForm()}</div>
        </div>
      </div>
    );
  };
  return isFormViewMode ? renderDatalabViewForm() : renderDataLabForm();
};

export default DatalabForm;

interface FormSubmitButtonProps {
  name: string;
  formActionType: 'EDIT' | 'VIEW' | 'CREATE';
  font_color?: string;
  background_color?: string;
  onClick?: () => void;
  isLoading: boolean;
  disabled?: boolean;
}

const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  name,
  font_color,
  formActionType,
  isLoading,
  background_color,
}) => {
  const shouldDisable = formActionType === 'VIEW';
  if (formActionType === 'VIEW') {
    return null;
  }
  return (
    <Button
      disabled={shouldDisable}
      type='submit'
      className={`w-full flex gap-2 disabled:cursor-not-allowed bg-primary text-white`}
    >
      {isLoading ? <Loader2 className='size-5 animate-spin' /> : null}
      {name}
    </Button>
  );
};
