import { useDispatch, useSelector } from 'react-redux';
import authTranslationData from '../../locales/authTranslation.json';
import broadcastTranslationData from '../../locales/broadcastTranslation.json';
import dashboardTranslationData from '../../locales/dashboardTranslation.json';
import inboxTranslationData from '../../locales/inboxTranslation.json';
import integrationTranslationData from '../../locales/integrationTranslation.json';
import onboardingTranslationData from '../../locales/onboardingTranslation.json';
import reportsTranslationData from '../../locales/reportsTranslation.json';
import settingsTranslationData from '../../locales/settingsTranslation.json';
import teamTranslationData from '../../locales/teamAgentTranslation.json';

import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';
import { getLanguageKey } from 'utilities/utils';

const useTranslation = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch<any>();
  const authData = useSelector((state: any) => state.auth);
  const dashboardData = useSelector((state: any) => state.dashboard);

  const localStorageLanguage = localStorage.getItem('language') || 'english';

  const [dashboardLanguage, setDashboardLanguage] =
    useState(localStorageLanguage);

  const isRtlLanguage = ['arabic'].includes(dashboardLanguage);

  const getUrlParamValue = () => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get('lang') || null;
  };

  useEffect(() => {
    if (authData?.dashboard_language !== localStorageLanguage) {
      localStorage.setItem(
        'language',
        authData?.dashboard_language || 'english'
      );
    }
    setDashboardLanguage(authData?.dashboard_language || 'english');

    //eslint-disable-next-line
  }, [authData?.dashboard_language]);

  useEffect(() => {
    const paramsValue = getUrlParamValue();
    if (!!paramsValue) {
      updateLanguage(getLanguageKey(paramsValue.trim().toLowerCase()));
    } else {
      const localStorageLanguageValue =
        localStorage.getItem('language') || 'english';
      updateLanguage(localStorageLanguageValue);
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    // Create a script element
    const scriptTag = document.createElement('script');
    if (dashboardLanguage === 'arabic') {
      // Set the inner HTML to add the 'dir' attribute to the 'body' tag
      scriptTag.innerHTML = "document.body.setAttribute('dir', 'rtl');";

      // Append the script element to the head
      document.head.appendChild(scriptTag);
    } else {
      scriptTag.innerHTML = "document.body.setAttribute('dir', 'ltr');";
      document.head.appendChild(scriptTag);
    }
  }, [dashboardLanguage]);

  const getNavString = () => {
    switch (dashboardData?.navActiveId) {
      case 0:
        return 'dashboard';
      case 1:
        return 'inbox';
      case 2:
        return 'integration';
      case 3:
        return 'automation';
      case 4:
        return 'customer';
      case 5:
        return 'reporting';
      case 9:
        return 'broadcast';
      case -1:
        return 'settings';
      default:
        return null;
    }
  };

  /**
   * Extracts a navigation string from the current URL pathname.
   *
   * This function splits the pathname into segments and returns a specific segment
   * based on predefined rules. If the first segment matches any of the predefined
   * segments in the `useFirstSegmentFor` array, it returns the first segment.
   * Otherwise, it returns the last segment.
   *
   * @returns {string} The navigation string derived from the URL pathname.
   *
   * @example
   * // Assuming pathname is "/integrations/integrated":
   * const navString = getNavStringFromUrl();
   * console.log(navString); // Outputs: "integrations"
   *
   * @example
   * // Assuming pathname is "/projects/375/reporting":
   * const navString = getNavStringFromUrl();
   * console.log(navString); // Outputs: "reporting"
   */
  const getNavStringFromUrl = () => {
    const pathSegment = pathname.split('/').filter((v) => v.length);
    const firstSegment = pathSegment[0];
    const lastSegment = pathSegment[pathSegment.length - 1];

    const useFirstSegmentFor = [
      'dashboard',
      'integrations',
      'settings',
      'onboarding',
      // add other if needed
    ];

    if (useFirstSegmentFor.includes(firstSegment)) {
      return firstSegment;
    } else {
      return lastSegment;
    }
  };

  const translatedFeatures = [
    'inbox',
    'settings',
    'dashboard',
    'integrations',
    'onboarding',
    'reporting',
    'broadcast',
  ];
  const authJsonTranslationData = { ...authTranslationData };
  const getTranslationData = () => {
    return {
      ...onboardingTranslationData,
      ...dashboardTranslationData,
      ...inboxTranslationData,
      ...integrationTranslationData,
      ...teamTranslationData,
      ...settingsTranslationData,
      ...broadcastTranslationData,
      ...reportsTranslationData,
    };
  };

  const tn = (num: any) => {
    if (!num) {
      return num;
    }
    const testValue = Number(num);
    // eslint-disable-next-line eqeqeq
    if (Number.isNaN(testValue) || num != testValue) {
      return num;
    }
    const localFormat = dashboardLanguage === 'arabic' ? 'ar-SA' : 'en-US';
    return testValue.toLocaleString(localFormat);
  };

  const getSingleTranslatedWord = (key: string) => {
    const translationData = getTranslationData();
    const currentTab = getNavStringFromUrl() ?? getNavString();

    if (
      !!currentTab &&
      dashboardLanguage &&
      translatedFeatures.includes(currentTab)
    ) {
      let translatedContent =
        translationData[currentTab][dashboardLanguage][
          key.trim().toLowerCase()
        ];

      return !!translatedContent ? translatedContent : key;
    }
    return key;
  };

  const getDoubleBracketsValues = (str: string, arr: string[] = []) => {
    let replacedString: string = '';
    if (typeof str === 'string')
      replacedString = str?.replace(/{{(.*?)}}/g, (_, value) => {
        arr.push(value);
        return '{{$}}';
      });
    return { replacedString, values: [...arr] };
  };

  const replaceDoubleBracketsWithValues = (str: string, arr: string[] = []) => {
    return str?.replace(/\[([^\]]+)\]/g, (_, g1) =>
      getSingleTranslatedWord(arr[g1] || g1)
    );
  };

  const t = (key: string) => {
    if (dashboardLanguage === 'english' && typeof key === 'string') {
      const result = key.replace(/{{(.*?)}}/g, (_, value) => value);
      return result;
    }
    const translationData = getTranslationData();
    const currentTab = getNavStringFromUrl() ?? getNavString();
    const generatedKeyData = getDoubleBracketsValues(key);
    let translatedContent = '';
    if (
      !!currentTab &&
      dashboardLanguage &&
      translatedFeatures.includes(currentTab)
    ) {
      translatedContent =
        translationData[currentTab][dashboardLanguage][
          generatedKeyData.replacedString.trim().toLowerCase()
        ];
    }

    if (!translatedContent || !currentTab) {
      const defaultTranslation =
        translationData['teamsupport'][dashboardLanguage][
          generatedKeyData.replacedString.trim().toLowerCase()
        ];

      return !!defaultTranslation
        ? replaceDoubleBracketsWithValues(
            defaultTranslation,
            generatedKeyData.values
          )
        : key;
    } else {
      return !!translatedContent
        ? replaceDoubleBracketsWithValues(
            translatedContent,
            generatedKeyData.values
          )
        : key;
    }
  };

  const updateLanguage = async (newLanguage: string) => {
    await dispatch.auth.updateStateData({
      key: 'dashboard_language',
      value: newLanguage,
    });
  };

  const authT = (key: string) => {
    if (!key) return;
    const lowercaseKey = key.trim().toLowerCase();
    if (!dashboardLanguage || !authJsonTranslationData.auth) {
      return key;
    }
    const translationData = authJsonTranslationData.auth;
    const translatedContent =
      translationData[dashboardLanguage]?.[lowercaseKey];
    return translatedContent || key;
  };

  return {
    t,
    dashboardLanguage,
    setDashboardLanguage,
    authT,
    updateLanguage,
    isRtlLanguage,
    tn,
  };
};

export default useTranslation;
