import {
  Button,
  Calendar,
  CalendarDays as CalendarIcon,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  IFormFieldComponent,
  Popover,
  PopoverContent,
  PopoverTrigger,
  cn,
  format,
  useFormContext,
} from '../../export';

export function DateField({
  data,
  valuePath,
  formActionType,
}: IFormFieldComponent) {
  const shouldDisableInput = formActionType === 'VIEW';
  const currentFieldValuePath = valuePath.length
    ? valuePath + '.' + data.slug
    : data.slug;

  const form = useFormContext();

  const isFormViewMode = formActionType === 'VIEW';

  const renderDateFieldView = () => {
    return (
      <div className='border border-zinc-200 space-y-2 rounded-md p-3'>
        <p className='text-xs text-zinc-500'>
          {data?.label_agent || data.name}
        </p>
        <p className='text-zinc-900 text-sm'>
          {form.getValues(currentFieldValuePath) || (
            <span className='text-zinc-500'> (Empty) </span>
          )}
        </p>
      </div>
    );
  };

  const renderDateField = () => (
    <FormField
      control={form.control}
      name={currentFieldValuePath}
      disabled={data?.is_readonly}
      rules={{
        required: {
          value: !!data.is_required,
          message: `${data.name} is required.`,
        },
      }}
      render={({ field }) => (
        <FormItem className='flex flex-col'>
          <FormLabel>
            {data?.label_agent || data.name}
            {data.is_required ? (
              <span className='text-destructive'>*</span>
            ) : null}
          </FormLabel>
          <FormControl>
            <Popover>
              <PopoverTrigger asChild disabled={shouldDisableInput}>
                <Button
                  type='button'
                  variant={'outline'}
                  className={cn(
                    'data-[state="open"]:border-ring justify-between text-left font-normal',
                    !field.value && 'text-muted-foreground'
                  )}
                >
                  {field.value ? (
                    format(field.value, 'dd/MM/yyyy')
                  ) : (
                    <span className='text-muted-foreground'>
                      {data?.placeholder ?? 'Pick a date'}
                    </span>
                  )}
                  <CalendarIcon className='h-4 w-4' />
                </Button>
              </PopoverTrigger>
              <PopoverContent
                className='relative-0 bottom-0  w-[320px] bg-white'
                onOpenAutoFocus={(e) => e.preventDefault()}
                align='start'
              >
                <Calendar
                  mode='single'
                  selected={field.value}
                  onSelect={(ev) => {
                    field.onChange(ev);
                  }}
                  // captionLayout='dropdown-buttons'
                  fromYear={new Date().getFullYear() - 200}
                  toYear={new Date().getFullYear() + 100}
                  initialFocus
                />
              </PopoverContent>
            </Popover>
          </FormControl>
          <FormDescription
            className={
              data.help_text ? 'visible text-zinc-500 text-sm' : 'hidden'
            }
          >
            {data.help_text}
          </FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  );

  return isFormViewMode ? renderDateFieldView() : renderDateField();
}

export default DateField;
