import React, { useState } from 'react';
import Button from '../../../../../library/button';
import defaultAvatar from '../../../assets/images/default-avatar.png';
import { channelInfo, classNames } from '../../../../../utilities/utils';
import {
  AgentFunnelIcon,
  ChannelFunnelIcon,
  GroupFunnelIcon,
  TagFunnelIcon,
} from 'pages/inbox/assets/iconComponent/FilterTypeIcons';
import useTranslation from 'components/customHooks/useTranslation';
import { TooltipProviderCustomised } from 'libraryV2/ui/tooltip';
import {
  ListType,
  TicketFilterChannelOptionInterface,
  TicketFilterOptionInterface,
} from 'pages/inbox/inboxInterface';
// type TFilterFieldType = 'channels' | 'groups' | 'agents' | 'tags';
interface Props {
  // ToDo: need to define type for each props\
  options: TicketFilterChannelOptionInterface[];
  filedType: string;
  selectedOptions: any;
  handleCancelOnClick: (value: string) => void;
  handleOptionSelection: (filedType: string, value: any) => void;
}

const TicketFilterCheckBox: React.FC<Props> = ({
  options,
  filedType,
  selectedOptions, // ToDo: need to rename it
  handleCancelOnClick,
  handleOptionSelection,
}) => {
  const { t } = useTranslation();
  const [selectedOptionId, setSelectedOptions] =
    useState<ListType[]>(selectedOptions);

  const handleSelectOption = (value: any) => {
    let selectedOptionIds: ListType[] = [...selectedOptionId];
    if (selectedOptionId.includes(value)) {
      selectedOptionIds = selectedOptionId.filter(
        (id: ListType) => id !== value && id !== 'all'
      );
    } else {
      selectedOptionIds.push(value);
    }
    setSelectedOptions(selectedOptionIds);
  };

  const getCheckedOptions = (value: any) => {
    // if (!!selectedOptionId && selectedOptionId[0] === 'all') return false;
    if (filedType === 'ticketStatus') return selectedOptionId === value;
    return selectedOptionId.includes(value);
  };

  const renderOptionImage = (item: TicketFilterOptionInterface) => {
    switch (filedType) {
      case 'channels': {
        return (
          <div className='flex-shrink-0'>
            <img
              src={channelInfo(item?.platFormType)?.image}
              alt='icon_box'
              className='h-4 w-4'
            />
          </div>
        );
      }
      case 'groups':
      case 'agents': {
        return (
          <div className='flex-shrink-0'>
            <img
              src={!!item?.image ? item?.image : defaultAvatar}
              alt='icon_box'
              className='h-5 w-5 rounded-full border border-gray-100'
            />
          </div>
        );
      }

      default:
        return null;
    }
  };

  const getFieldTypeIcons = () => {
    switch (filedType) {
      case 'channels':
        return (
          <div
            className='absolute float-left top-1/3 left-3'
            data-tip='tooltip'
            data-for='ticket_filter_tooltip-01'
            onClick={() =>
              window.open('/integrations/available-integrations', '_blank')
            }
          >
            <TooltipProviderCustomised content={getTootltipMsg()} side='right'>
              <ChannelFunnelIcon />
            </TooltipProviderCustomised>
          </div>
        );
      case 'groups':
        return (
          <div
            className='absolute float-left top-1/3 left-3'
            data-tip='tooltip'
            data-for='ticket_filter_tooltip-01'
            onClick={() => window.open('/settings/team-group', '_blank')}
          >
            <TooltipProviderCustomised content={getTootltipMsg()} side='right'>
              <GroupFunnelIcon />
            </TooltipProviderCustomised>
          </div>
        );
      case 'agents':
        return (
          <div
            className='absolute float-left top-1/3 left-3'
            data-tip='tooltip'
            data-for='ticket_filter_tooltip-01'
            onClick={() => window.open('/settings/team-user', '_blank')}
          >
            <TooltipProviderCustomised content={getTootltipMsg()} side='right'>
              <AgentFunnelIcon />
            </TooltipProviderCustomised>
          </div>
        );
      case 'tags':
        return (
          <div
            className='absolute float-left top-1/3 left-3'
            data-tip='tooltip'
            data-for='ticket_filter_tooltip-01'
            onClick={() => window.open('/settings/tags', '_blank')}
          >
            <TooltipProviderCustomised content={getTootltipMsg()} side='right'>
              <TagFunnelIcon />
            </TooltipProviderCustomised>
          </div>
        );
      default:
        return <div className='absolute float-left top-1/3 left-3'></div>;
    }
  };

  const getTootltipMsg = () => {
    switch (filedType) {
      case 'channels':
        return t('Go to Integrations');
      case 'groups':
        return t('Manage Groups');
      case 'agents':
        return t('Add team members');
      case 'tags':
        return t('Manage Tags');
      default:
        return;
    }
  };

  const getEmptyMs = () => {
    switch (filedType) {
      case 'groups':
        return 'No groups found. Create a group to get started.';

      case 'tags':
        return 'No tags found. Add a tag to get started.';
      default:
        return 'No Data Found';
    }
  };

  const isDataChanged = () =>
    JSON.stringify(selectedOptionId) !== JSON.stringify(selectedOptions);

  const sortSelectedOption = () => {
    let newArrSelected = [];
    let newArrUnselected = [];
    for (let i = 1; i < options.length; i++) {
      if (getCheckedOptions(options[i].value)) {
        newArrSelected.push(options[i]);
      } else newArrUnselected.push(options[i]);
    }
    newArrSelected.sort(function (a, b) {
      let nameA = a.label.toLowerCase();
      let nameB = b.label.toLowerCase();
      let returnValue = 0;
      nameA < nameB ? (returnValue = -1) : (returnValue = 1);
      return returnValue;
    });
    let optionsSorted = [options[0]].concat(newArrSelected, newArrUnselected);

    return optionsSorted;
  };

  const getTruncatedLabel = (item: TicketFilterOptionInterface) => {
    const label: string = item?.label ?? '---';

    let maxTextSize = 15;
    return (
      <TooltipProviderCustomised
        content={label?.length > maxTextSize ? label : ''}
        side='right'
        asChild={true}
      >
        <label
          htmlFor={`${item?.label}`}
          className='flex-grow flex line-clamp-1 font-normal text-gray-900'
        >
          {item?.label}
        </label>
      </TooltipProviderCustomised>
    );
  };

  const renderFooterButtons = () => {
    return (
      <div className='sticky bottom-0 flex rtl:flex-row ltr:flex-row-reverse w-full h-auto p-1.5 bg-white border-t'>
        {getFieldTypeIcons()}
        <div className='flex gap-1'>
          <Button
            size='xs'
            className='text-[#374151] hover:none'
            onClick={() => {
              handleCancelOnClick('');
              setSelectedOptions([]);
            }}
          >
            {t('Cancel')}
          </Button>
          <Button
            isDisabled={!isDataChanged()}
            size='xs'
            className={classNames(
              'ml-3',
              isDataChanged()
                ? 'text-white bg-primary'
                : 'bg-gray-300 text-gray-900'
            )}
            onClick={() => handleOptionSelection(filedType, selectedOptionId)}
          >
            {t('Select')}
          </Button>{' '}
        </div>
      </div>
    );
  };

  const renderEmptyMessage = () => {
    return (
      <div className='flex justify-start p-2 '>
        <span className='text-sm font-normal text-justify text-gray-500'>
          {t(getEmptyMs())}
        </span>
      </div>
    );
  };

  const renderCheckboxOptions = (
    item: TicketFilterOptionInterface,
    index: number
  ) => {
    return (
      <div key={index}>
        {index === 0 && ( // renders "unselect all" option
          <span
            className='text-sm text-[#0078CF] w-full p-3 cursor-pointer'
            onClick={() => setSelectedOptions([])}
          >
            {item?.label}
          </span>
        )}
        {index !== 0 && (
          <div
            className={`relative flex items-center p-2 gap-3`}
            onClick={() => handleSelectOption(item?.value)}
          >
            <div className='flex items-center h-5'>
              <input
                id={`${item?.label}_${index}`}
                name={item?.label}
                checked={getCheckedOptions(item?.value)}
                type='checkbox'
                autoComplete={'nope'}
                className='w-4 h-4 border-gray-300 rounded text-primary focus:ring-primary'
              />
            </div>

            {!index ? null : renderOptionImage(item)}

            {getTruncatedLabel(item)}

            <div className='flex gap-2 text-sm'>
              {filedType === 'groups' && index !== 0 && item.isSmart && (
                <div className='flex items-center'>
                  <span className='px-1 py-0.5 text-xs text-gray-800 bg-green-100 rounded'>
                    {t('SMART')}
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      {Array.isArray(options) && options.length !== 0 ? (
        <>
          {sortSelectedOption().map(
            (item: TicketFilterChannelOptionInterface, index: number) =>
              renderCheckboxOptions(item, index)
          )}
        </>
      ) : (
        renderEmptyMessage()
      )}
      {renderFooterButtons()}
    </>
  );
};
export default TicketFilterCheckBox;
