import { FC, useCallback, useState } from 'react';
import {
  Button,
  PlusIcon,
  useDatalab,
  useFilter,
  toast,
  DeleteAlert,
} from '../../../export';
import { TFilterData } from '../../../interface';
import FilterField from './FilterField';

interface Props {
  onSideSheetClose: () => void;
}

const Filter: FC<Props> = ({ onSideSheetClose }) => {
  const {
    pageChange,
    storedFilterData,
    updateFilterData,
    selectedDatalabInfo,
    fetchDatalabEntries,
    updateIsFilterState,
  } = useDatalab();
  const { validateFilterFields } = useFilter();
  const [filterData, setFilterData] = useState<TFilterData[]>(storedFilterData);
  const [openDiscardDialog, setOpenDiscardDialog] = useState<boolean>(false);

  const handleFilterDataOnChange = useCallback(
    (index: number, updatedData: any) => {
      setFilterData((prevFilterData) => {
        const newFilterData = [...prevFilterData];
        newFilterData[index] = updatedData;
        return newFilterData;
      });
    },
    []
  );

  const handleAddNewFilter = useCallback(() => {
    setFilterData((prevFilterData) => [
      ...prevFilterData,
      { slug: '', operator: '', value: '' },
    ]);
  }, []);

  const removeFilterFromList = useCallback((index: number) => {
    let localFilterData = [...filterData];
    let updatedFilterData = localFilterData.filter((_, idx) => idx !== index);

    setFilterData(updatedFilterData);
    updateFilterData(updateFilterData);
    // eslint-disable-next-line
  }, []);

  const handleApplyFilter = async () => {
    let isValidated = validateFilterFields(filterData);
    if (!isValidated) {
      toast({
        //@ts-ignore
        title: <p className='text-red-500'>Could not apply filter</p>,
        description: `Please fill all the fields.`,
      });
      return;
    }
    updateFilterData(filterData);

    let response = await fetchDatalabEntries(filterData, {
      ...pageChange,
      offset: 0,
    });
    if (response) {
      updateIsFilterState(true);
      onSideSheetClose();
    }
  };

  const handleCancelFilter = () => {
    let hasFilterData = filterData.length !== 0;
    if (hasFilterData) setOpenDiscardDialog(true);
  };

  return (
    <div className='h-full overflow-y-scroll scrollbar-hide'>
      {filterData.length !== 0 ? (
        <>
          {filterData.map((data, index) => (
            <FilterField
              key={index}
              data={data}
              formFieldList={selectedDatalabInfo?.lab_fields}
              removeFilterFromList={() => removeFilterFromList(index)}
              handleFilterDataOnChange={(updatedData: any) =>
                handleFilterDataOnChange(index, updatedData)
              }
            />
          ))}
          <AddNewFilterButton onClick={handleAddNewFilter} />
          <div className='fixed flex justify-end gap-2 bottom-6 right-6'>
            <Button variant='outline' onClick={() => handleCancelFilter()}>
              Cancel
            </Button>
            <Button className='text-white' onClick={() => handleApplyFilter()}>
              Apply Filter
            </Button>
          </div>
        </>
      ) : (
        <AddNewFilterView onClick={handleAddNewFilter} />
      )}
      {openDiscardDialog && (
        <DeleteAlert
          open={openDiscardDialog}
          onOpenChange={setOpenDiscardDialog}
          title={'Discard filter'}
          description={`All entered data will be discarded`}
          cancelTitle={`Cancel`}
          confirmTitle={`Discard`}
          onCancel={() => setOpenDiscardDialog(false)}
          onConfirm={() => {
            updateFilterData([]);
            setOpenDiscardDialog(false);
            onSideSheetClose();
          }}
        />
      )}
    </div>
  );
};

const AddNewFilterButton: FC<{ onClick: () => void }> = ({ onClick }) => (
  <Button className='flex gap-2 mt-4' variant='outline' onClick={onClick}>
    <PlusIcon className='w-4 h-4' stroke='2' />
    <span>Add New Filter</span>
  </Button>
);

const AddNewFilterView: FC<{ onClick: () => void }> = ({ onClick }) => (
  <div className='flex flex-col items-center justify-center h-full'>
    <span>No filter added.</span>
    <AddNewFilterButton onClick={onClick} />
  </div>
);

export default Filter;
