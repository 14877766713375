import React from 'react';
import BroadcastModal from './BroadcastModal';
import Spinner from './Spinner';
import { XMarkIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { connect } from 'react-redux';
import { PlatformInterface, SelectedTeamInterface } from 'index';
import EmptyList from '../assets/emptyList.svg';
import {
  UGetInfoSvgTooptipIcon,
  UGetCssClassForAudience,
  UBroadcastStatusCheck,
} from 'utilities/content';
import moment from 'moment';
import {
  AudienceReport,
  BroadcastCreationProperty,
  CSVProperty,
} from '../interface';
import BroadcastPreview from './broadcastPreview';

interface Props {
  isOpen: boolean;
  setOpen: (payload: boolean) => void;
  broadcastDetailsLoading: boolean;
  fetchBroadcastDetails: (payload: {
    projectId: number;
    data: { broadcast_id: string };
  }) => void;
  selectedProject: SelectedTeamInterface;
  broadcastId: string;
  broadcastCreationData: BroadcastCreationProperty;
  platformList: PlatformInterface[] | null;
  setIsDetails: (payload: boolean) => void;
  isDetails: boolean;
  updateBroadcastCreationData: (
    payload: BroadcastCreationProperty[] | null
  ) => void;
  updateMappedData: (payload: CSVProperty | null) => void;
  downloadCampaignReport: (payload: {
    projectId: number;
    file_name: string;
    data: {
      broadcast_id: string;
    };
  }) => { success: boolean; data: any };
}

const BroadcastDetails: React.FC<Props> = ({
  isOpen,
  broadcastDetailsLoading,
  setOpen,
  fetchBroadcastDetails,
  selectedProject,
  broadcastId,
  broadcastCreationData,
  platformList,
  setIsDetails,
  isDetails,
  updateBroadcastCreationData,
  updateMappedData,
  downloadCampaignReport,
}) => {
  const [audienceData, setAudienceData] = React.useState<
    AudienceReport[] | null
  >(
    broadcastCreationData && broadcastCreationData.audience_report.length > 0
      ? broadcastCreationData.audience_report
      : null
  );

  React.useEffect(() => {
    if (broadcastCreationData && broadcastCreationData.audience_report.length) {
      setAudienceData(broadcastCreationData.audience_report);
    }
  }, [broadcastCreationData]);

  const renderInfoSvgTooptipIcon = (title: string) => {
    switch (title) {
      case 'Queued':
        return UGetInfoSvgTooptipIcon(
          'Total number of broadcasts being processed',
          'text-[#6B7280]'
        );
      case 'Delivered':
        return UGetInfoSvgTooptipIcon(
          'Total number of recipients the broadcast was delivered to',
          'text-[#6B7280]'
        );
      case 'Seen':
        return UGetInfoSvgTooptipIcon(
          'Total number of recipients who has seen the broadcast',
          'text-[#6B7280]'
        );
      case 'Replied':
        return UGetInfoSvgTooptipIcon(
          'Total number of recipients who has replied to the broadcast',
          'text-[#6B7280]'
        );
      case 'Failed':
        return UGetInfoSvgTooptipIcon(
          'Total number of contacts the broadcast failed to reach due to technical limitations',
          'text-[#6B7280]'
        );
      default:
        return UGetInfoSvgTooptipIcon(
          'Total number of contacts the broadcast was scheduled for',
          'text-[#6B7280]'
        );
    }
  };

  const broadcastStatisticsData = (
    title: string,
    percentage: number | null,
    total: number
  ) => {
    return (
      <div className='p-4 shadow rounded-md border bg-white h-[96px] w-[48.5%] flex flex-col justify-center'>
        <div className='flex gap-1.5 items-center'>
          <p className='mt-2 mb-2 text-sm text-gray-500 capitalize'>{title}</p>
          <>
            {renderInfoSvgTooptipIcon(title)}
            {title !== 'Scheduled' && (
              <p
                className={`text-xs leading-4 rounded-[15px] ${UGetCssClassForAudience(
                  title
                )} px-2 py-1`}
              >
                {percentage && percentage.toFixed(0)}%
              </p>
            )}
          </>
        </div>
        <p className='text-[30px] text-gray-800 mb-2'>{total}</p>
      </div>
    );
  };

  const searchBroadcastContacts = () => {
    return (
      <div className='relative mt-1'>
        <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
          <MagnifyingGlassIcon
            className='w-5 h-5 text-gray-400'
            aria-hidden='true'
          />
        </div>
        <input
          type='text'
          name='search'
          id='search'
          className='block w-1/2 pl-10 border-gray-300 rounded-md focus:border-green-500 focus:ring-green-500 sm:text-sm'
          placeholder='Search contacts'
          onChange={(e) => {
            if (e.target.value === '')
              return setAudienceData(broadcastCreationData.audience_report);
            let searchData =
              audienceData &&
              audienceData.filter((item) =>
                item?.name
                  .toLowerCase()
                  .includes(e.target.value.trim().toLowerCase())
              );
            setAudienceData(searchData);
          }}
        />
      </div>
    );
  };

  const persentageCalculation = (total: number, givendata: number) => {
    if (givendata === 0 || total === 0) return 0;
    return (givendata / total) * 100;
  };

  const downloadReport = async () => {
    let payload = {
      projectId: selectedProject.id,
      file_name: broadcastCreationData?.title,
      data: {
        broadcast_id: broadcastId,
      },
    };
    await downloadCampaignReport(payload);
  };

  React.useEffect(() => {
    if (isDetails) {
      let payload = {
        projectId: selectedProject.id,
        data: {
          broadcast_id: broadcastId,
        },
      };
      fetchBroadcastDetails(payload);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const renderBroadcastModalHeader = () => {
    return (
      !broadcastDetailsLoading && (
        <div className='flex items-center space-between'>
          <div className='w-[90%]'>
            <div className='flex w-full align-center'>
              <span className='text-lg text-gray-700'>
                {broadcastCreationData && broadcastCreationData?.title}
              </span>
              &nbsp;
              <span className='ml-2 text-xs leading-4 text-gray-700'>
                {broadcastCreationData &&
                  UBroadcastStatusCheck(broadcastCreationData?.status)}
              </span>
              <div className='ml-2 text-gray-500'>
                sent from{' '}
                <span className='py-1 px-1 text-[#1F2837]'>
                  {broadcastCreationData &&
                    broadcastCreationData?.channel_details?.phone}
                </span>{' '}
                on{' '}
                <span className='py-1 px-1 text-[#1F2837]'>
                  {broadcastCreationData &&
                    moment(
                      parseInt(broadcastCreationData?.scheduled_time)
                    ).format('ddd, DD MMM YYYY, h:mm A')}
                </span>
              </div>
            </div>
          </div>
          <div className='flex justify-end w-[10%]'>
            <XMarkIcon
              className='w-6 h-6 text-gray-500 cursor-pointer'
              onClick={() => {
                setOpen(false);
                setIsDetails(false);
                updateBroadcastCreationData(null);
                updateMappedData(null);
              }}
            />
          </div>
        </div>
      )
    );
  };

  const getQueuedCount = (broadcastCreationData: BroadcastCreationProperty) => {
    return (
      broadcastCreationData?.total_count -
      broadcastCreationData?.sent_count -
      broadcastCreationData?.failed_count
    );
  };

  const renderBroadcastStatistics = () => {
    return (
      <>
        <p className='mb-3 text-sm font-medium leading-5 text-gray-900'>
          Broadcast Statistics
        </p>
        <div className='flex flex-wrap gap-3 mb-8'>
          {broadcastCreationData && (
            <>
              {broadcastStatisticsData(
                'Scheduled',
                null,
                broadcastCreationData?.total_count
              )}
              {broadcastStatisticsData(
                'Delivered',
                persentageCalculation(
                  broadcastCreationData?.total_count,
                  broadcastCreationData?.sent_count
                ),
                broadcastCreationData?.sent_count
              )}
              {broadcastStatisticsData(
                'Queued',
                persentageCalculation(
                  broadcastCreationData?.total_count,
                  getQueuedCount(broadcastCreationData)
                ),
                getQueuedCount(broadcastCreationData)
              )}
              {broadcastStatisticsData(
                'Seen',
                persentageCalculation(
                  broadcastCreationData?.sent_count,
                  broadcastCreationData?.seen_count
                ),
                broadcastCreationData?.seen_count
              )}
              {broadcastStatisticsData(
                'Replied',
                persentageCalculation(
                  broadcastCreationData?.sent_count,
                  broadcastCreationData?.replied_count
                ),
                broadcastCreationData?.replied_count
              )}
              {broadcastStatisticsData(
                'Failed',
                persentageCalculation(
                  broadcastCreationData?.total_count,
                  broadcastCreationData?.failed_count
                ),
                broadcastCreationData?.failed_count
              )}
            </>
          )}
        </div>
      </>
    );
  };

  const renderBroadcastPreview = () => {
    return (
      broadcastCreationData && (
        <BroadcastPreview
          title={broadcastCreationData?.title}
          templateId={broadcastCreationData?.template_id}
          height='h-[344px] overflow-auto'
          width='w-[236px]'
          templateList={
            (platformList &&
              platformList.find(
                (item) =>
                  item.secondary_id ===
                  broadcastCreationData?.channel_details?.phone
              )?.whatsapp_message_templates) ||
            null
          }
        />
      )
    );
  };

  const renderAudienceDataTable = () => {
    return (
      <table className='min-w-full divide-y divide-gray-200'>
        <thead className='sticky top-0 z-10 bg-gray-50'>
          <tr>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
            >
              Name
            </th>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase'
            >
              Phone
            </th>
            <th
              scope='col'
              className='sticky top-0 z-10 px-6 py-3 pr-10 text-xs font-medium tracking-wider text-right text-gray-500 uppercase'
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody className='bg-white divide-y divide-gray-200 w-full mt-[50px]'>
          {audienceData &&
            audienceData.length > 0 &&
            audienceData.map((audience) => {
              return (
                <tr>
                  <td className='px-6 py-3 text-sm font-medium text-gray-900 whitespace-nowrap capitalize w-[47%]'>
                    {audience?.name}
                  </td>
                  <td className='px-6 py-3 text-sm text-gray-400 whitespace-nowrap w-[47%]'>
                    <div className='overflow-hidden truncate '>
                      {audience?.phone}
                    </div>
                  </td>
                  <td className='px-2 py-2 text-xs leading-3 font-medium text-gray-500 whitespace-nowrap w-[6%] text-right'>
                    <div className='overflow-hidden text-center truncate '>
                      <div
                        className={`py-1 px-3 h-[20px] inline-block ${UGetCssClassForAudience(
                          audience?.status
                        )} rounded-[15px] text-center capitalize`}
                      >
                        {audience?.status || '- -'}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          {(!audienceData || audienceData.length === 0) && (
            <tr>
              <td colSpan={7}>
                <div className='w-full flex flex-col items-center justify-center min-h-[300px]'>
                  <img src={EmptyList} className='w-40 mb-2' alt='' />
                  <p className='text-sm text-gray-400'>
                    Audience data is empty
                  </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    );
  };

  return (
    <BroadcastModal
      isOpen={isOpen}
      width={'w-[1082px]'}
      height={'h-[750px]'}
      disableFullScreen={true}
      setOpen={setOpen}
    >
      <>
        <div className='rounded-md'>
          <div className='border border-b rounded-md mp-2'>
            <div className='p-5'>{renderBroadcastModalHeader()}</div>
          </div>

          <div className='flex justify-center p-5'>
            {!broadcastDetailsLoading && (
              <div className='w-[100%]'>
                <div className='flex'>
                  <div className='w-[75%]'>{renderBroadcastStatistics()}</div>

                  <div className='flex gap-2 justify-center w-[25%]'>
                    {renderBroadcastPreview()}
                  </div>
                </div>
                <div className='flex items-center justify-center w-full mb-5'>
                  <div className='w-[50%]'>{searchBroadcastContacts()}</div>
                  <div className='flex w-[50%] justify-end'>
                    <button
                      className='bg-[#04B25F] py-2 px-3 rounded-md text-white'
                      onClick={() => downloadReport()}
                    >
                      Download as Report
                    </button>
                  </div>
                </div>
                <div className='border border-gray-200 rounded-md mb-5 max-h-[200px] overflow-auto'>
                  {renderAudienceDataTable()}
                </div>
              </div>
            )}
            {broadcastDetailsLoading && (
              <div className='w-[100%] min-h-[500px] flex items-center justify-center'>
                <div className='flex items-center justify-center'>
                  <Spinner
                    color='border-r-transparent border-t-green-500 border-b-green-500 border-l-green-500'
                    size='w-16 h-16'
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    </BroadcastModal>
  );
};

const mapState = (state: any) => ({
  broadcastDetailsLoading:
    state.loading.effects.broadcast.fetchBroadcastDetails,
  broadcastCreationData: state.broadcast.broadcastCreationData,
  platformList: state.dashboard.platformList,
});
const mapDispatch = (dispatch: any) => ({
  fetchBroadcastDetails: (payload: {
    projectId: number;
    data: { broadcast_id: string };
  }) => dispatch.broadcast.fetchBroadcastDetails(payload),
  updateBroadcastCreationData: (payload: BroadcastCreationProperty[] | null) =>
    dispatch.broadcast.updateBroadcastCreationData(payload),
  updateMappedData: (payload: CSVProperty | null) =>
    dispatch.broadcast.updateMappedData(payload),
  downloadCampaignReport: (payload: {
    projectId: number;
    file_name: string;
    data: {
      broadcast_id: string;
    };
  }) => dispatch.broadcast.downloadCampaignReport(payload),
});

export default connect(mapState, mapDispatch)(BroadcastDetails);
