import React from 'react';
import FacebookLogin from 'react-facebook-login';
import facebookLogo from '../../../assets/icons/facebookLogo.svg';
import config from '../../../../../utilities/config';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { getMetaPermissionScope } from 'utilities/utils';
import useTranslation from 'components/customHooks/useTranslation';

interface Props {
  processFacebookResponse: (response: any) => any;
  loading: boolean;
  type: string;
}

const FacebookConnection: React.FC<Props> = ({
  processFacebookResponse,
  loading = false,
  type,
}) => {
  const appId = config.misc.facebookAppId();
  const { t } = useTranslation();
  return (
    <>
      <div className='p-6'>
        {loading && (
          <div className='flex flex-col items-center justify-center '>
            <ArrowPathIcon className='w-20 h-20 text-primary hover:text-primary-hover animate-reverse-spin' />
            <span>{t('fetching page list...')}</span>
          </div>
        )}
        {!loading && (
          <>
            <img
              src={facebookLogo}
              className='m-auto text-center'
              alt={'facebook logo'}
            />
            <p className='mt-3 text-xl font-medium text-center'>
              Connect With Facebook!
            </p>
            <p className='mt-3 text-sm font-normal text-center text-gray-00'>
              {t(
                'Connect your facebook account, you can edit the details later. Please provide us the required permission to get started!'
              )}
            </p>
            <br />
            <div className='flex justify-center'>
              <FacebookLogin
                appId={!!appId ? appId : ''}
                isDisabled={false}
                autoLoad={false}
                fields='email'
                scope={getMetaPermissionScope(type)}
                callback={processFacebookResponse}
                cssClass='facebook-button-connect'
                textButton='Connect With Facebook'
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default FacebookConnection;
