import { getDefaultFieldsValue, isEmtyObject } from 'pages/datalab/utils';
import {
  Button,
  IFormField,
  SingleFormField,
  Trash,
  useFieldArray,
} from '../../export';
import SearchSelectField from './searchableField';

interface Props {
  groupData: IFormField;
  valuePath: string;
  formActionType: 'EDIT' | 'VIEW' | 'CREATE';
}

export const GroupsContainer: React.FC<Props> = ({
  groupData,
  formActionType,
  valuePath,
}) => {
  const groupStructure = groupData.children ?? [];
  const groupDefaultValue = getDefaultFieldsValue(groupStructure);
  const isFormActionView = formActionType === 'VIEW';

  const currentFieldPath = valuePath
    ? valuePath + '.' + groupData.slug
    : groupData.slug;

  const fieldArray = useFieldArray({
    name: currentFieldPath,
  });

  const groupContainerData = groupData;

  const handleAddNewGroup = () => {
    if (
      !groupContainerData.children ||
      !Array.isArray(groupContainerData.children)
    ) {
      return;
    }
    fieldArray.append(groupDefaultValue);
  };

  const deleteGroup = (ev: React.MouseEvent<HTMLButtonElement>) => {
    const deleteIdx = ev.currentTarget.tabIndex;
    fieldArray.remove(deleteIdx);
  };

  const renderSingleGroup = (fieldLists: IFormField[], groupIndex: number) => {
    return fieldLists?.map((v) => {
      const fieldValuePath = `${currentFieldPath}.${groupIndex}`;
      if (!isEmtyObject(v?.api_call) && formActionType !== 'VIEW') {
        return (
          <SearchSelectField
            fieldData={v}
            valuePath={fieldValuePath}
            datalabFields={fieldLists}
            shouldDisableField={false}
          />
        );
      } else
        return (
          <SingleFormField
            formActionType={formActionType}
            key={v.id}
            data={v}
            valuePath={fieldValuePath}
          />
        );
    });
  };

  const renderGroups = () => {
    if (!fieldArray.fields.length) {
      return null;
    }
    return fieldArray.fields?.map((singleGroup: any, groupIndex: number) => {
      const currentGroupId = fieldArray.fields[groupIndex]?.id;

      const isManuallyCreatedGroup = groupIndex !== 0;

      return (
        <div
          key={groupContainerData.id + groupIndex}
          className='w-full relative border flex flex-col p-4 rounded-md gap-4 bg-white'
        >
          <div className=''>
            <h3 className='text-sm font-medium text-text-primary'>
              {groupContainerData?.label_agent || groupContainerData.name}
            </h3>
            {isManuallyCreatedGroup && !isFormActionView ? (
              <Button
                variant={'ghost'}
                type='button'
                disabled={isFormActionView}
                className='absolute text-xs right-0 top-2 flex gap-1 hover:bg-transparent items-center hover:text-red-500'
                id={currentGroupId}
                tabIndex={groupIndex}
                onClick={deleteGroup}
              >
                <Trash className='h-4 w-4' />
                <span className='mt-1'>Delete</span>
              </Button>
            ) : null}
          </div>
          <hr className='border-0 border-b' />
          {renderSingleGroup(groupStructure!, groupIndex)}
        </div>
      );
    });
  };

  return (
    <div className='bg-zinc-50 p-4 rounded-md grid gap-4'>
      {renderGroups()}
      {isFormActionView ? null : (
        <Button
          type='button'
          disabled={isFormActionView}
          onClick={handleAddNewGroup}
          className='py-1.5 border bg-[#04B25F] text-white'
        >
          + Add Another ({groupContainerData.name})
        </Button>
      )}
    </div>
  );
};

export default GroupsContainer;
