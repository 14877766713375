import React from 'react';
import Step1 from './Step1';
import Step2 from './Step2';
import Modal from '../../common/Modal';
import Stepper from '../../common/Stepper';

import { navigate } from '@reach/router';
import { getModalCustomFooter } from '../../../utils/functions';
import { LivechatDefaultData } from 'pages/onboardingV2/utils/content';

interface Props {
  open: boolean;
  teamName: string;
  handleClose: () => void;
  handleChannelCreate: (
    channelType: string,
    channelData: any
  ) => Promise<channelCreateAPiResponseType>;
}

const initialSettingData = {
  url: '',
  title: '',
  avatar: '',
  welcome_message: '',
  is_published: true,
  primary_color: '#04B25F',
  whitelisted_domains: [],
  connected_nlp_integration_id: '',
  live_chat_widget_position: 'right',
  live_chat_should_show_mobile_prompt: false,
  live_chat_should_show_desktop_prompt: true,
  live_chat_should_show_myalice_branding: true,
};

const formSteps = [
  { id: 1, name: 'General Information' },
  { id: 2, name: 'Embed Livechat' },
];

const LivechatModal: React.FC<Props> = ({
  open,
  teamName,
  handleClose,
  handleChannelCreate,
}) => {
  const [step, setStep] = React.useState(1);
  const [success, setSuccess] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const [platfromData, setPlatfromData] = React.useState<any>({
    id: '',
    primary_id: '',
    access_token: '',
  });

  const [webChatSettingData, setWebChatSettingData] =
    React.useState(initialSettingData);

  const clearState = () => {
    setWebChatSettingData(initialSettingData);
    setStep(1);
    setLoading(false);
    setSuccess(true);
    setErrorMessage('');
  };

  const handleConfirm = async () => {
    switch (step) {
      case 1:
        setLoading(true);
        let payloadData = { ...LivechatDefaultData };
        payloadData.title = webChatSettingData.title;
        payloadData.livechat_data.welcome_prompt_online_text =
          webChatSettingData.welcome_message;
        let res = await handleChannelCreate('livechat_messenger', payloadData);
        if (res.status === 200) {
          setStep(2);
          setSuccess(true);
          setLoading(false);
          setPlatfromData(res.data.dataSource);
        } else {
          setSuccess(false);
          setLoading(false);
          setErrorMessage(
            res.status === 500
              ? 'Something went wrong while creating the channel. Try again later.'
              : res.data.error
          );
        }
        break;

      case 2:
        handleClose();
        navigate(`/dashboard?channel_type=livechat`);
    }
  };

  const handleCancel = (step: number) => {
    switch (step) {
      case 2:
        setStep(1);
        break;
    }
  };

  const checkSteps = (step: number) => {
    if (loading) return true;
    const mandatoryKeys = ['title', 'welcome_message'];
    let flag = false;
    mandatoryKeys.forEach((items) => {
      if (!(webChatSettingData && webChatSettingData[items])) {
        flag = true;
      }
    });
    return flag;
  };

  const checkConfirmButtons = () => {
    switch (step) {
      case 1:
        return checkSteps(step);
      default:
        return false;
    }
  };

  return (
    <Modal
      open={open}
      teamName={teamName}
      title='Livechat'
      platfromType='webchat'
      closeOnExternalClick={false}
      handleClose={() => {
        if (step === 2) {
          navigate(`/dashboard?channel_type=livechat`);
        }
        clearState();
        handleClose();
      }}
      handleCancel={() => handleCancel(step)}
      disableConfirm={checkConfirmButtons()}
      handleConfirm={handleConfirm}
      hideCancel={true}
      confirmText={step === 1 ? 'Complete Integration' : 'Finish'}
      customFooterContent={
        step === 2
          ? getModalCustomFooter(
              'The chat-widget is not visible?',
              'https://myalice.ai/contact/',
              'Contact Support'
            )
          : getModalCustomFooter(
              'Confused about what to do?',
              'https://docs.myalice.ai/connect-social-channels/connect-web-app/connect-live-chat',
              'Read Documentation'
            )
      }
    >
      <div className='p-6'>
        <Stepper steps={formSteps} currentStep={step} />
        <div className='mt-10'>
          {
            {
              1: (
                <Step1
                  success={success}
                  errorMessage={errorMessage}
                  webChatSettingData={webChatSettingData}
                  handleChange={(
                    key: string,
                    value: string | string[] | boolean
                  ) => {
                    setWebChatSettingData({
                      ...webChatSettingData,
                      [key]: value,
                    });
                  }}
                />
              ),
              2: <Step2 platfromData={platfromData} />,
            }[step]
          }
        </div>
      </div>
    </Modal>
  );
};
export default LivechatModal;
