export interface INodeDecoratorItem {
  label: string;
  background: string;
  icon: JSX.Element; // Assuming JSX.Element is imported from React or another appropriate library
  renderIcon?: (className: string) => JSX.Element;
}

export interface INodeDecoratorItemsData {
  'trigger-node': INodeDecoratorItem;
  'condition-node': INodeDecoratorItem;
  'action-node': INodeDecoratorItem;
}

export enum NodeType {
  TRIGGER = 'trigger-node',
  CONDITION = 'condition-node',
  ACTION = 'action-node',
  CONDITION_ACTION_BUTTON = 'condition_action-button',
}

export enum NodeTypeAlias {
  TRIGGER = 'trigger',
  CONDITION = 'component',
  ACTION = 'component',
}

export interface INode {
  id: string;
  type: NodeType;
  node_type: NodeTypeAlias;
  deletable?: boolean;
  position: { x: number; y: number };
  data: any; // any because Node data is nested object and it is dynamic. We will update this with proper definition later if possible
}

export interface IConditionalDropdown {
  id: number | string;
  label: string;
}

export type TriggerTagTypeCode = 'shopify' | 'woocommerce' | 'salla' | 'zid';

export interface TriggerTagTypes {
  title: string;
  code: TriggerTagTypeCode;
}

export interface TriggerDetailsTypes {
  trigger_name: string;
  trigger_code: string;
  trigger_description: string;
}

export interface IEdge {
  id: string;
  target: string;
  source: string;
  style: {
    strokeWidth: number;
    stroke: string;
  };
  sourceHandle: string;
  targetHandle: string;
  markerEnd: {
    type: string;
    width: number;
    height: number;
    color: string;
  };
}
export interface ComparatorTagTypes {
  name: string;
  code: string;
  description: string;
}

export interface ComparatorTagDetailsTypes {
  id: string;
  title: string;
}

export interface DelayTimingDataTypes {
  value: string;
  label: string;
}

interface ILocatedAt {
  next: ILocatedAtNext | ILocatedAt;
  entity: string;
  value_type: string;
  entity_type: string;
}

interface ILocatedAtNext {
  mark?: true;
  entity: string;
  value_type: string;
  entity_type: string;
  next?: ILocatedAtNext | ILocatedAt;
}

export interface IOrderItem {
  name: string;
  located_at: ILocatedAt;
}

interface IContactLocatedAt {
  next: IContactLocatedAtNext | IContactLocatedAt;
  entity: string;
  value_type: string;
  entity_type: string;
}

interface IContactLocatedAtNext {
  mark?: true;
  entity: string;
  value_type: string;
  entity_type: string;
  next?: IContactLocatedAtNext | IContactLocatedAt;
}

export interface IContactItem {
  name: string;
  located_at: IContactLocatedAt;
}

export interface ITriggerList {
  trigger_name: string;
  trigger_code: string;
  trigger_icon: null;
  trigger_description: null;
}

interface IDefaultFlow {
  version: string;
  blueprint: IBlueprint;
}

interface IBlueprint {
  entry_key: string;
  save_data: ISaveData;
}

interface ISaveData {
  unknown: null;
  meta_data: IMetaData;
  definition: ISaveDefinition;
  on_failure?: null;
  on_success?: null;
  component_code: string;
  component_name: string;
  component_type: string;
}

interface IMetaData {
  id: string;
  type: string;
  width: number;
  height: number;
  dragging: boolean;
  position: IPosition;
  selected: boolean;
  positionAbsolute: IPosition;
}

interface IPosition {
  x: number;
  y: number;
}

interface ISaveDefinition {
  save_to: ISaveTo;
  input_schema?: [];
  executor_type: string;
}

interface ISaveTo {
  next?: ISaveToNext | ISaveTo;
  entity: string;
  value_type: string;
  entity_type: string;
}

interface ISaveToNext {
  mark: true;
  entity: string;
  value_type: string;
  entity_type: string;
}

interface IComparatorTag {
  name: string;
  code: string;
  description?: null;
}

export interface IExecutorTag {
  name: string;
  code: string;
  description?: null;
}

interface IComparison {
  mark: boolean;
  entity: string;
  value_type: string;
  entity_type: string;
}

interface ICompareWith {
  mark: boolean;
  value: string | number | string[];
  entity: string;
  value_type: string;
  entity_type: string;
}

interface IInputSchema {
  mark: boolean;
  entity: string;
  value_type: string;
  entity_type: string;
  value_choices?: string[];
  alias_as?: { label: string; value: string }[];
  next?: IComparison;
}

interface IComponent {
  definition: {
    compare_to: IComparison;
    compare_type: string;
    compare_with: ICompareWith;
    input_schema: IInputSchema[];
  };
}

export interface IOrder {
  name: string;
  label: string;
  representation_type: string;
  description: string;
  component: IComponent;
  data?: IData;
  tags: string[];
  api_request_data: any;
  api_response_data: any;
}

export interface IProduct {
  product_id: string;
  product_name: string;
  product_link: string;
  product_images: string[];
}

interface IRepresentation {
  product_id: string;
  product_name: string;
  product_link: string;
  product_images: string[];
}

interface IComparison {
  mark: boolean;
  entity: string;
  value_type: string;
  entity_type: string;
  value?: string | number | string[];
}

interface ICompareWith {
  next?: ICompareWith;
  mark: boolean;
  value: string | number | string[];
  entity: string;
  value_type: string;
  entity_type: string;
  representation?: IRepresentation[];
}

interface IInputSchema {
  mark: boolean;
  entity: string;
  value_type: string;
  entity_type: string;
  value_choices?: string[];
  alias_as?: { label: string; value: string }[];
  next?: IComparison;
}

export interface IAction {
  url: string;
  type: string;
  method: string;
  values: {
    next: {
      next: {
        each: {
          mark: boolean;
          entity: string;
          value_type: string;
          entity_type: string;
        }[];
        return_extra: {
          each: string[];
          entity: string;
          value_type: string;
          entity_type: string;
        }[];
        return_data_format: { each: string[] };
      };
      entity: string;
      value_type: string;
      entity_type: string;
    };
    entity: string;
    value_type: string;
    query_params: {};
    return_val_type: string;
  };
}
interface IComponent {
  compare_to: IComparison;
  compare_type: string;
  compare_with: ICompareWith;
  input_schema: IInputSchema[];
  value?: IAction;
}

export interface IData {
  col_1_label_name: string;
  col_1_label_options_datatype: string;
  col_1_label_options: {
    label: string;
    compare_type: string;
    select_type: string;
  }[];
  col_2_label_name?: string;
  col_2_label_options_datatype?: string;
  col_2_label_options?: {
    component_name: string;
    component_code: string;
    label: string;
    definition?: IComponent;
    component_type?: string;
    component_icon?: string | null;
  }[];
}

export interface ICartItem {
  name: string;
  label: string;
  representation_type: string;
  description: string;
  data: IData;
  tags: string[];
}

export interface IDelayOperator {
  mark: boolean;
  entity: string;
  entity_type: string;
  value_type: string;
}

export interface IDelayDefinition {
  input_schema: IDelayOperator[];
  execution_type: string;
  timeout_in_sec: number | null;
}

export interface IDelayOptions {
  component_name: string;
  component_code: string;
  definition: IDelayDefinition;
}

export interface IDelayData {
  col_1_label_name: string;
  col_1_label_options_datatype: string;
  col_1_label_options: IDelayOptions[];
}

export interface IExecutorItem {
  name: string;
  label: string;
  description: string;
  tags: string[];
  representation_type: string;
  data: IDelayData; // Modify this based on other executor types
}

interface IExecutor {
  whatsapp: IWhatsappExecutor[];
  ecommerce: never[]; // You can replace 'never[]' with the actual type for ecommerce if needed
}

interface IWhatsappExecutor {
  name: string;
  label: string;
  representation_type: string;
  description: string;
  component: IExecutorComponent;
  data: Record<string, unknown>; // Replace 'Record<string, unknown>' with the actual type for 'data' if needed
  tags: string[];
}

interface IExecutorComponent {
  component_name: string;
  component_code: string;
  definition: {
    stored_data: {
      whatsapp_template_id: null | number;
      whatsapp_variables_map: Record<string, unknown>;
      whatsapp_template_details: Record<string, unknown>;
      whatsapp_variables_fallback_map: Record<string, unknown>;
    };
    request_type: string;
    executor_type: string;
  };
}

export interface IConditionOperator {
  mark: boolean;
  entity: string;
  entity_type: string;
  value_type: string;
}

export interface IConditionDefinition {
  compare_to: null | IConditionOperator;
  compare_with: null | IConditionOperator;
  input_schema: IConditionOperator[];
  condition_type: 'and' | 'or';
}

export interface IConditionOptions {
  unknown: null;
  definition: IConditionDefinition;
  on_failure: null;
  on_success: null;
  component_name: string;
  component_type: 'conditional';
}

export interface IConditionData {
  col_1_label_name: string;
  col_1_label_options_datatype: string;
  col_1_label_options: IConditionOptions[];
}

export interface IConditionItem {
  name: string;
  label: string;
  description: string;
  tags: string[];
  data: IConditionData;
}

export interface ITrigger {
  trigger_name: string;
  trigger_code: string;
  trigger_icon: null | string;
  trigger_description: null | string;
}

export interface ITriggerDependencyDict {
  [key: string]: {
    trigger_info: {
      data_map: {
        order: IOrderItem[];
        contact: IContactItem[];
        general: any[];
      };
      default_flow: IDefaultFlow;
    };
    comparator_tag_list: IComparatorTag[];
    executor_tag_list: IExecutorTag[];
    conditional_tag_list: any[];
    comparator: {
      order: IOrder[];
      whatsapp: any[];
      cart: ICartItem[];
    };
    executor: IExecutor;
    conditional: any;
  };
}

export interface ICommonComponents {
  comparator: any[];
  executor: IExecutorItem[];
  conditional: IConditionItem[];
}

export interface IAutomationTriggerData {
  trigger_list: ITrigger[];
  trigger_dependency_dict: ITriggerDependencyDict;
  common_components: ICommonComponents;
}

export interface ITemporaryComparatorOption {
  id: string;
  name: string;
  representationType: string;
}

export interface IProductDataTypes {
  product_name: string;
  product_id: number | string;
  product_link: string;
}

export interface IProductSaveDataTypes {
  label: string;
  value: string | number;
  product_id: string | number;
  product_link: string;
  product_name: string;
}

export interface ISaveSelectedConditionCol1Types {
  label: string;
  compare_type: string;
  select_Type: string;
}
export interface ISaveSelectedConditionCol2Types {
  label: string;
  select_Type: string;
}
export interface ISaveSelectedConditionDataTypes {
  name: string;
  col_1: ISaveSelectedConditionCol1Types;
  col_2: ISaveSelectedConditionCol2Types[];
}

export interface IAssignVariableTypes {
  item: string;
  variableCategory: string;
  variableValue: string;
  fallbackValue: string;
}

export interface IWhatsappTemplateHeader {
  type: string;
  value: string;
}

export interface IWhatsappTemplateButton {
  id: number;
  type: string;
  title: string;
  value: number | string;
  verbose: string;
}

export interface IWhatsappTemplate {
  id: string;
  name: string;
  status: string;
  body: string;
  header: IWhatsappTemplateHeader;
  footer: string;
  buttons: IWhatsappTemplateButton[];
  attributes: {
    attribute: string;
  }[];
  label: string;
}

export interface IUserActionFlowTableActionTypes {
  link: string;
  label: string;
}

export interface toggleUserActionFlowTypes {
  flowId: number;
  name: string;
  trigger_code: string;
  is_active: boolean;
  trigger_match_user_unique_ref: string;
}

export interface IUserFlowTableDataTypes {
  id: number;
  name: string;
  created_at: string | number;
  is_active: boolean;
  ecommerce_tag_code: string;
  ecommerce_tag_name: string;
  trigger_name: string;
  trigger_code: string;
}

export interface IUserFlowLogTableDataTypes {
  history_id: number;
  Unique_identifier_value: string;
  triggered_time: number;
  customer_name: string;
  whatsapp_number: string;
  status: string;
}

export interface IUtilityFunctionOptions {
  isLogView: boolean;
}

export interface ISingleComparatorTagDetails {
  name: string;
  label: string;
  representation_type: string;
  description: string;
  data: IData;
  tags?: string[];
}

export interface ISingleAutomationNodes {
  id: string;
  deletable?: boolean;
  type: string;
  node_type: string;
  width?: number;
  height?: number;
  position: {
    x: number;
    y: number;
  };
  data: IActionDataTypes | IConditionAutomationNodes;
  selected?: boolean;
}

export interface ITriggerAutomationNodes {
  trigger_name: string;
  trigger_code: string;
  trigger_description: string;
  trigger_icon: string;
}

export interface IConditionAutomationNodes {
  name: string;
  representation_type: string;
  selectedComparator: string;
  col_1: {
    label: string;
    compare_type: string;
    select_Type: string;
  };
  col_2: {
    label: string;
    select_Type?: string;
    component_type?: string;
    definition?: IComponent | IComponentDefinition[];
  }[];
}

export interface IComponentDefinition {
  component_name: string;
  component_code: string;
  component_type: string;
  component_icon: string | null;
  definition: IComponent;
}

export interface IActionDataTypes {
  general: {
    delay: {
      valueOfDelay: string | null;
      timeOfDelay: string;
      data?: IComponentDefinition | IActionGeneralDefinitionTypes;
    };
  };
  whatsapp: {
    selectedTemplate: {
      variableCount: number;
    };
    phoneVariable: {
      selectedTriggerInfo: string;
      selectedTriggerInfoValue: string;
      phoneVariableInfoObj: {
        name: string;
        located_at: Ilocated_at;
      };
      selectedChannelId: number;
      data: IActionWhatsappDataTypes;
    };
  };
}

export interface Ilocated_at {
  next: Ilocated_at;
  entity: string;
  value_type: string;
  entity_type: string;
}
export interface IActionWhatsappDataTypes {
  name: string;
  label: string;
  representation_type: string;
  description: string;
  tags: string[];
  components: IActionWhatsappComponent;
}

export interface IActionWhatsappComponent {
  component_name: string;
  component_code: string;
  component_type: string;
  component_icon: string | null;
  definition: {
    request_type: string;
    executor_type: string;
    stored_data: {
      whatsapp_platform_id: null | number | string;
      whatsapp_template_id: null | number | string;
      whatsappVariablesMap: Record<string, any>;
      whatsappTemplateDetails: Record<string, any>;
      whatsappCustomerPhoneNumber: Record<string, any>;
    };
  };
}

export interface IActionGeneralDefinitionTypes {
  component_name: string;
  component_code: string;
  component_type: string;
  component_icon: string;
  definition: {
    input_schema: IInputSchema[];
    executor_type: string;
    timeout_in_sec: null | string;
  };
}

export interface IChildrenUiScreen {
  type:
  | 'TextInput'
  | 'Dropdown'
  | 'RadioButtonsGroup'
  | 'Footer'
  | 'Tag_textInput'
  | 'variable_dropdown_from_trigger';
  name: string;
  label: string;
  description: '';
  'input-type'?: string; // Only for TextInput
  data_source?: IDropdownGroup[] | IRadioGroup[];
  required: boolean;
  'on-click-action'?: {
    name?: string;
    store_on?: string;
    payload?: IDiscountPayload;
  };
}

export interface IDropdownGroup {
  id: string;
  title: string;
  description: string;
}
export interface IRadioGroup {
  id: string;
  title: string;
  description: string;
}

export interface IDiscountPayload {
  amount?: string;
  discount_on?: string;
  discount_type?: string;
  discount_apply_count?: string;
}
export interface IAddTagsPayload {
  select_on?: string;
  plain_tag: string[];
  variable_tag: any;
}
export interface IRemoveTagsPayload {
  select_on: string;
  remove_tag: string[];
  add_variable: any;
}

export interface IAutomationLogResult {
  user_input_data: any;
  webhook_data: any;
  starts_at: string;
  ends_at: string;
  current_component_state: 'success' | 'failed' | 'pending' | 'unknown';
  component_execution_status: string;
  generated_data: any;
  result: any[];
}

export type TconditionRepresentationTypes =
  | 'two_column_comparator_single_choice'
  | 'two_column_comparator_multi_choice_api'
  | 'two_column_comparator_single_choice_api'
  | 'two_column_comparator_input_field'
  | 'two_column_comparator_string_input_field'
  | 'two_column_comparator_multi_choice';

export interface ISingleCondtionLogData {
  name: string;
  representation_type: string;
  selectedComparator: string;
  component_name: string;
  component_code?: string;
  component_type?: string;
  col_1: Col1;
  col_2: Col2[];
  component_computation_info: IAutomationLogResult;
}

export interface Col1 {
  label: string;
  select_Type: string;
  compare_type: string;
}

export interface Col2 {
  label: string;
  definition: any[];
}
export interface IAddTagsOnCustomerLogData {
  routingModel: any[];
  screens: any[];
  component: IComponentDefinition | IActionGeneralDefinitionTypes;
  currentRoute: string;
  currentScreen: any;
  extranalData: any;
}

export interface ISingleActionLogData {
  component_computation_info: IAutomationLogResult;
  general: {
    data?: IComponentDefinition | IActionGeneralDefinitionTypes;
    delay?: {
      valueOfDelay: string | null;
      timeOfDelay: string;
    };
  };
  eCommerce: {
    addTagsOnCustomer?: IAddTagsOnCustomerLogData;
    addTagsOnOrder?: any;
  };
  whatsapp: {
    selectedTemplate: any;
    phoneVariable: any;
    selectedChannelId: any;
  };
}
