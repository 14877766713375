import BlockList from './blockList';
import { DragDropIcon } from 'pages/datalab/export';

const FormEditor = () => (
  <div className='w-full col-span-2 row-span-2 mt-6 overflow-x-hidden overflow-y-scroll h-[93vh] pb-10'>
    <BlockList />
    <div className='flex items-center justify-center mt-4 border-2 border-gray-300 border-dashed rounded h-[52px] w-[580px]'>
      <DragDropIcon />
      <span className='ml-2 text-sm text-zinc-500'>
        Drag items from left bar
      </span>
    </div>
  </div>
);

export default FormEditor;
